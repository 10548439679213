import axios from "axios";
import { errorCall } from "../ErrorHandling/action";

import {
  VIEW_APPLICANT_FAILED,
  VIEW_APPLICANT_REQUEST,
  VIEW_APPLICANT_SUCCESS,
} from "./actionType";

export const viewApplicantRequest = () => ({
  type: VIEW_APPLICANT_REQUEST,
  payload: [],
  isLoading: true,
});

export const viewApplicantSuccess = (data) => ({
  type: VIEW_APPLICANT_SUCCESS,
  payload: data,
  isLoading: false,
});
export const viewApplicantFailed = (err) => ({
  type: VIEW_APPLICANT_FAILED,
  payload: [],
  isLoading: false,
  isError: true,
});

export const postViewApplicant =
  (id, sort, status, experience, value, page) => async (dispatch) => {
    const token = localStorage.getItem("token");
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await dispatch(viewApplicantRequest());
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/view-jobappilcation-details/${id}`,
        { sort, status, experience, search: value, page },
        header
      );

      await dispatch(viewApplicantSuccess(res.data));
    } catch (err) {
      console.log(err.message);
      if (err?.message == "Network Error" || err?.response?.status == 500) {
        dispatch(errorCall(err));
      }
    }
  };
