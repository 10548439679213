import axios from "axios";
import {
    DESIGNATION_REQUEST,
    DESIGNATION_SUCCESS,
    DESIGNATION_FAILURE,
    EXPERIENCE_REQUEST,
    EXPERIENCE_SUCCESS,
    EXPERIENCE_FAILURE,
    SKILLS_REQUEST,
    SKILLS_SUCCESS,
    SKILLS_FAILURE,
    LOCATION_REQUEST,
    LOCATION_SUCCESS,
    LOCATION_FAILURE,
    CITY_REQUEST,
    CITY_SUCCESS,
    CITY_FAILURE,
    CANDIDATE_REQUEST,
    CANDIDATE_SUCCESS,
    CANDIDATE_FAILURE,
    CANDIDATE_SUCCESS_ALLRESPONSE,
    ADD_CANDIDATE_REQUEST,
    ADD_CANDIDATE_SUCCESS,
    ADD_CANDIDATE_FAILURE,
    STATUS_UPDATE_REQUEST,
    STATUS_UPDATE_SUCCESS,
    STATUS_UPDATE_FAILURE,
    SAVED_DATA,
    EMAIL_REQUEST,
    EMAIL_SUCCESS,
    EMAIL_FAILURE,
    // start resume extract
    RESUMEEXTRACT_REQUEST,
    RESUMEEXTRACT_SUCCESS_ADD,
    RESUMEEXTRACT_SUCCESS_EDIT,
    RESUMEEXTRACT_FAILURE,
    // end resume extract
} from "./actionType";
import { toast } from "react-toastify";
import { errorCall } from "../ErrorHandling/action";
import { updateCurrentLocation, updateDesignation, updateDesignationExperience, updateFirstName, updateLastName, updatePrimaryEmail, updatePrimaryPhone, updateQualification, updateSecondaryEmail, updateSecondaryPhone, updateSkill, updateSocials, } from "../Candidate/createCandidate/action";


// START RESUMEEXTRACT ACTIONS
export const extractresume_req = () => ({
    type: RESUMEEXTRACT_REQUEST,
});

export const extractresume_succ_add = (data) => ({
    type: RESUMEEXTRACT_SUCCESS_ADD,
    payload: data,
});
export const extractresume_succ_edit = (data) => ({
    type: RESUMEEXTRACT_SUCCESS_EDIT,
    payload: data,
});
export const extractresume_failure = (err) => ({
    type: RESUMEEXTRACT_FAILURE,
    payload: err,
});


export const extractresume = (data, navigate, type) => (dispatch, getState) => {
    const formData = new FormData();
    formData.append("resume", data)
    // dispatch(skills(navigate))
    dispatch(extractresume_req());
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'multipart/form-data'
        },
    };
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/candidate/getCVdata`, formData, config)
        .then((res) => {
            type != "edit" ? dispatch(extractresume_succ_add(res)) : dispatch(extractresume_succ_edit(res))
            return res

        })
        .catch((error) => {
            console.log("Failed to upload Resume", error);
            dispatch(extractresume_failure());
            toast("Failed To Extact Data Please Try Again")
            // if (error?.message === "Network Error" || error?.response?.status === 500) {
            //     dispatch(errorCall(error))
            //     return;
            // }else{
            //     toast.error("Failed To Extact Data Please Try Again")
            // }

        })
}
// END RESUMEEXTRACT ACTIONS


export const fillUpdata = (response) => (dispatch, getState) => {
    try {
        //  dispatch(designation_req());
        const { skillsData, designationData, experienceData } = getState().Candidate_Reducer
        const source = getState().getSourceSet
        const fullPhone = response?.data?.resume?.contact?.phone_primary
        const secondNumber = response?.data?.resume?.contact?.phone_secondary
        const link = response?.data?.resume?.socials?.link
        const name = source?.data?.data?.find(item => {
            return response?.data?.resume?.socials?.name.toLowerCase().includes(item.name.toLowerCase())
        })?.name || ""
        const social = [{ link: link, name: name }]
        const userDesignation = response?.data?.resume?.designation.trim(" ")
        const matchedDesignation = designationData?.find((item) => {
            const regexPattern = new RegExp(item.name.trim(" "), 'i');
            return regexPattern.test(userDesignation)
        });
        const userExperience = response?.data?.resume?.designation.trim(" ")
        const matchedExperience = experienceData?.find((item) => {
            const regexPattern = new RegExp(item.name.trim(" "), 'i');
            return regexPattern.test(userExperience)
        });
        const skills = []
        skillsData.filter((item) => {
            return response?.data?.resume?.skills?.find((element) => {
                const lowercaseSkillName = element.toLowerCase();
                return item.name && item.name.toLowerCase().includes(lowercaseSkillName) ?
                    skills.push({ currently_working: true, exp_month: "", exp_year: "", skill_id: [item] }) : null
            });
        });
        // general data section
        dispatch(updateFirstName(response?.data?.resume?.first_name));
        dispatch(updateLastName(response?.data?.resume?.last_name));
        //  dispatch(updateLeadSource(response?.data?.resume.lead_source));
        dispatch(updateDesignationExperience(matchedExperience?.id ? matchedExperience?.id : ""));
        dispatch(updateDesignation(matchedDesignation?.designation_id ? matchedDesignation?.designation_id : ""));
        dispatch(updatePrimaryEmail(response?.data?.resume?.contact?.email_primary));
        dispatch(updateSecondaryEmail(response?.data?.resume?.contact.email_secondary));
        dispatch(updatePrimaryPhone(fullPhone));
        dispatch(updateSecondaryPhone(secondNumber));
        dispatch(updateCurrentLocation(response?.data?.resume?.contact?.city));
        dispatch(updateSocials(social));
        //  //education section
        dispatch(updateQualification(response?.data?.resume?.qualifications));
        //  skills section
        dispatch(updateSkill(skills.length == 0 ? [{ currently_working: true, exp_month: "", exp_year: "", skill_id: [] }] : skills))
        // dispatch(updateLanguage());
    } catch (err) {
        console.log(err);
    }
};

export const designation_req = () => ({
    type: DESIGNATION_REQUEST,
});

export const designation_succ = (data) => ({
    type: DESIGNATION_SUCCESS,
    payload: data,
});
export const designation_failure = (err) => ({
    type: DESIGNATION_FAILURE,
    payload: err,
});

export const designationList = (navigate) => (dispatch) => {
    dispatch(designation_req());
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/auth/designationList`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
        .then((response) => {
            dispatch(designation_succ(response?.data?.data));
        })
        .catch((error) => {
            if (error?.message === "Network Error" || error?.response?.status === 500) {
                dispatch(errorCall(error))
                return;
            }
            dispatch(designation_failure(error));
            if (error?.response?.data?.error == "Unauthenticated.") {
                localStorage.removeItem("token");
                navigate("/login");
            }
            dispatch(designation_failure(error));
        });
};

export const location_req = () => ({
    type: LOCATION_REQUEST,
});

export const location_succ = (data) => ({
    type: LOCATION_SUCCESS,
    payload: data,
});
export const location_failure = (err) => ({
    type: LOCATION_FAILURE,
    payload: err,
});

export const locationList = (navigate) => (dispatch) => {
    dispatch(location_req());
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/auth/companyJobPost/location`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
        .then((response) => {
            dispatch(location_succ(response?.data?.locations));
        })
        .catch((error) => {
            if (error?.message === "Network Error" || error?.response?.status === 500) {
                dispatch(errorCall(error))
                return;
            }
            dispatch(location_failure(error));
            if (error?.response?.data?.error == "Unauthenticated.") {
                localStorage.removeItem("token");
                navigate("/login");
            }
            dispatch(location_failure(error));
        });
};

export const experience_req = (data) => ({
    type: EXPERIENCE_REQUEST,
});
export const experience_succ = (data) => ({
    type: EXPERIENCE_SUCCESS,
    payload: data,
});
export const experience_failure = (err) => ({
    type: EXPERIENCE_FAILURE,
    payload: err,
});

export const experienceLevel = (navigate) => (dispatch) => {
    dispatch(experience_req());
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/auth/experienceLevel`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
        .then((response) => {
            dispatch(experience_succ(response?.data?.data));
        })
        .catch((error) => {
            if (error?.message === "Network Error" || error?.response?.status === 500) {
                dispatch(errorCall(error))
                return;
            }
            if (error?.response?.data?.message == "Unauthenticated.") {
                localStorage.removeItem("token");
                navigate("/login");
            }
            dispatch(experience_failure(error));
        });
};

export const skills_req = (data) => ({
    type: SKILLS_REQUEST,
});
export const skills_succ = (data) => ({
    type: SKILLS_SUCCESS,
    payload: data,
});
export const skills_failure = (err) => ({
    type: SKILLS_FAILURE,
    payload: err,
});

export const skills = (navigate) => (dispatch) => {
    dispatch(skills_req());
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/auth/skill`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
        .then((response) => {
            dispatch(skills_succ(response?.data?.data));
        })
        .catch((error) => {
            if (error.message == "Network Error" || error.response.status == 500) {
                dispatch(errorCall(error))
                dispatch(skills_failure(error));
                return;
            }
            else if (error?.response?.data?.error == "Unauthenticated.") {
                localStorage.removeItem("token");
                navigate("/login");
            } else {
                dispatch(skills_failure(error));
            }
        });
};

export const city_req = (data) => ({
    type: CITY_REQUEST,
});
export const city_succ = (data) => ({
    type: CITY_SUCCESS,
    payload: data,
});
export const city_failure = (err) => ({
    type: CITY_FAILURE,
    payload: err,
});

export const city = (navigate) => (dispatch) => {
    dispatch(city_req());
    axios
        .post(
            `${process.env.REACT_APP_BASE_URL}/api/auth/candidate/city`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        )
        .then((response) => {
            dispatch(city_succ(response?.data?.data));
        })
        .catch((error) => {
            if (error.message == "Network Error" || error.response.status == 500) {
                dispatch(errorCall(error))
                dispatch(city_failure(error));
                return;
            }
            else if (error?.response?.data?.error == "Unauthenticated.") {
                localStorage.removeItem("token");
                navigate("/login");
            } else {
                dispatch(city_failure(error))
            }
        });
};

export const candidate_req = () => ({
    type: CANDIDATE_REQUEST,
});

export const candidate_succ = (data) => ({
    type: CANDIDATE_SUCCESS,
    payload: data,
});
export const candidate_failure = (err) => ({
    type: CANDIDATE_FAILURE,
    payload: err,
});
export const candidate_succ_allData = (data) => ({
    type: CANDIDATE_SUCCESS_ALLRESPONSE,
    payload: data,
});

export const candidate = (searchData, designation, skill, experience, cities, rating, status, sort, page, rangeValues, navigate) => (dispatch, getState) => {
    dispatch(candidate_req());
    const minimum = rangeValues?.length > 0 ? rangeValues[0] : 0;
    const maximum = rangeValues?.length > 0 ? rangeValues[1] : 20;
    // let exp = JSON.stringify(experience) ? experience : [];
    const sortValue = sort ? `${JSON.stringify(sort)}}` : "%22%22%7D"; //extra bracket '}' is added as required for backend.
    const setSkill = skill ? skill : []
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/candidate?page=${page}&search=${searchData}&selected=%7B%22designations%22:${JSON.stringify(designation)},%22designation_exp%22:${JSON.stringify(experience)},%22exp_designation_max%22:${maximum},%22exp_designation_min%22:${minimum},%22cities%22:${JSON.stringify(cities)},%22ratings%22:${JSON.stringify(rating)},%22statuses%22:${JSON.stringify(status)},%22skills%22:${JSON.stringify(setSkill)},%22sort%22:${sortValue}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }
    )
        .then((response) => {

            dispatch(candidate_succ(response?.data?.data));
            dispatch(candidate_succ_allData(response?.data));
            return response?.data?.data

        })
        .catch((error) => {
            if (error.message == "Network Error" || error.response.status == 500) {
                dispatch(errorCall(error))
                dispatch(candidate_failure(error));
                return;
            }
            else if (error?.response?.data?.error == "Unauthenticated.") {
                localStorage.removeItem("token");
                navigate("/login");
            } else { dispatch(candidate_failure(error)); }
            return error
        });
};



// export const singleCandidate_req = (data) => ({
//     type: SINGLE_CANDIDATE_REQUEST,
// });
// export const singleCandidate_succ = (data) => ({
//     type: SINGLE_CANDIDATE_SUCCESS,
//     payload: data,
// });
// export const singleCandidate_failure = (err) => ({
//     type: SINGLE_CANDIDATE_FAILURE,
//     payload: err,
// });

// export const singleCandidate = (id, navigate) => (dispatch) => {
//     dispatch(singleCandidate_req());
//     axios
//         .get(`${process.env.REACT_APP_BASE_URL}/api/auth/candidate/${id}`, {
//             headers: {
//                 Authorization: `Bearer ${localStorage.getItem("token")}`,
//             },
//         })
//         .then((response) => {
//             dispatch(singleCandidate_succ(response?.data?.data));
//         })
//         .catch((error) => {
//             if (error.message == "Network Error" || error.response.status == 500) {
//                 dispatch(errorCall(error))
//                 dispatch(singleCandidate_failure(error))
//                 return;
//             }
//             else if (error?.response?.data?.error == "Unauthenticated.") {
//                 localStorage.removeItem("token")
//                 navigate("/login");
//             } else {
//                 dispatch(singleCandidate_failure(error));
//             }
//         });
// };



export const statusUpdate_req = (data) => ({
    type: STATUS_UPDATE_REQUEST
});
export const statusUpdate_succ = (data) => ({
    type: STATUS_UPDATE_SUCCESS,
    payload: data,
});
export const statusUpdate_failure = (err) => ({
    type: STATUS_UPDATE_FAILURE,
    payload: err,
});

export const statusUpdate = (status, id, navigate) => (dispatch) => {
    dispatch(statusUpdate_req());
    return axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/auth/candidate/status/${id}`, { status }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
        .then((response) => {
            dispatch(statusUpdate_succ(response?.data));
            return response?.data;
        })
        .catch((error) => {
            if (error.message == "Network Error" || error.response.status == 500) {
                dispatch(errorCall(error))
                dispatch(statusUpdate_failure(error));
                return;
            }
            else if (error?.response?.data?.error == "Unauthenticated.") {
                localStorage.removeItem("token");
                navigate("/login");
            } else {
                dispatch(statusUpdate_failure(error))
            }
            return error
        });
};


export const add_candidate_req = (data) => ({
    type: ADD_CANDIDATE_REQUEST,
});
export const add_candidate_succ = (data) => ({
    type: ADD_CANDIDATE_SUCCESS,
    payload: data,
});
export const add_candidate_failure = (err) => ({
    type: ADD_CANDIDATE_FAILURE,
    payload: err,
});

export const send_email_req = (data) => ({
    type: EMAIL_REQUEST,
});
export const send_email_succ = (data) => ({
    type: EMAIL_SUCCESS,
    payload: data,
});
export const send_email_failure = (err) => ({
    type: EMAIL_FAILURE,
    payload: err,
});


export const sendEmail = (emailData) => (dispatch) => {
    dispatch(send_email_req());
    axios
        .post(
            `${process.env.REACT_APP_BASE_URL}/api/auth/scheduleInterview`,
            {
                title: emailData.title,
                interviewDate: emailData.interviewDate,
                candidate_email: emailData.candidateEmail,
                link: emailData.link,
                test: emailData.testNote,
                venue: emailData.venue,
                interviwerList: emailData.interviewerEmail,
                candidate_id: emailData.tempCandidateId,
                interviewMode: emailData.interviewMode,
                interviewTime: emailData.interviewTime,
                testType: emailData.interviewType,
                technicalTestDate: "",
                technicalTestTime: "",
                technicalTitle: ""
            },
            {
                headers: {
                    Authorization: "Bearer" + " " + emailData.token,
                },
            }
        )
        .then((response) => {
            dispatch(send_email_succ("Email sent successfully"))
            emailData.navigate('/candidates/all-candidates')
        })
        .catch((error) => {
            dispatch(send_email_failure(error, "Failed to load"))
            if (error.message == "Network Error" || error.response.status == 500) {
                dispatch(errorCall(error))
                return;
            }
            else if (error?.response?.data?.errors?.link) {
                if (error?.response?.data?.errors?.link[0] == "The link format is invalid.") {
                    toast.warn("The Link Format Is Invalid");
                }
            }
            if (error?.response?.data?.error == "Unauthenticated.") {
                localStorage.removeItem("token");
                emailData.navigate("/login");
            }

        });
};


export const saved_data = (data) => ({
    type: SAVED_DATA,
    payload: data
});

