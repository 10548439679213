import React, { useEffect, useState } from "react";
import "./css/TestScreen.css";
import { Link, useNavigate, useLocation, NavLink } from "react-router-dom";
import { testSearch } from "../../Redux/TestReducer/action";
import { useDispatch, useSelector } from "react-redux";
import Paginate from "./Pagination/Pagination";
import SettingsNavbar from "../../Components/SettingsNavbar";
import { errorCall } from "../../Redux/ErrorHandling/action";
import axios from "axios";
import Swal from "sweetalert2";
import { MdOutlineGridOn } from "react-icons/md";
import { CiViewList } from "react-icons/ci";

const TestScreen = ({ newSidedata, onSidedataChange }) => {
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const [viewType, setViewType] = useState("Table");
  const [testType, setTestType] = useState("custom test");
  const [data, setData] = useState([]);
  const [isDraft, setDraft] = useState("false");
  const testResult = useSelector((state) => state.test?.result);
  const loaders = useSelector((state) => state.test);
  const { isLoadingCustom } = loaders;
  const dispatch = useDispatch();
  const tokenData = localStorage.getItem("token");
  const usersPermission = localStorage?.getItem("userPermission");
  const permissionList = usersPermission?.split(",");

  useEffect(() => {
    if (!tokenData) {
      navigate("/login");
    }
  }, [tokenData]);

  const menuClick = () => {
    onSidedataChange(!newSidedata);
  };

  const [searchData, setSearchData] = useState("");
  const [page, setPage] = useState(1);
  const [dataLimit, setDataLimit] = useState(0);

  const searchedData = useSelector((state) => state?.test?.testSearch);

  const handleLimit = (e) => {
    setPage(1)
    setDataLimit(e.target.value)
    dispatch(
      testSearch(navigate, tokenData, e.target.value, searchData, 1, testType)
    )
  }

  const handleSearch = (e) => {
    setSearchData(e.target.value);
    e.preventDefault();
    setPage(1);
    dispatch(
      testSearch(navigate, tokenData, dataLimit, e.target.value, page, testType)
    );
  };
  const handleType = (e) => {
    setTestType(e.target.value);
    e.preventDefault();
    setPage(1);
    dispatch(
      testSearch(
        navigate,
        tokenData,
        dataLimit,
        searchData,
        page,
        e.target.value
      )
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (searchData != 0) {
        handleSearch(event);
      }
    }
  };

  const searchValue = (e) => {
    const newValue = e.target.value;
    setSearchData(newValue);
    setPage(1);
    if (e.target.value === "") {
      setSearchData(e.target.value);
      dispatch(
        testSearch(
          navigate,
          tokenData,
          dataLimit,
          e.target.value,
          page,
          testType
        )
      );
    }
  };

  const pageChange = (e) => {
    let nextPage = page;
    if (e === "previous") {
      nextPage = page - 1;
    } else if (e === "next") {
      nextPage = page + 1;
    } else {
      nextPage = e;
    }

    setPage(nextPage);

    const draftTestsKey = isDraft ? "draftTrue" : "draftFalse";
    const testsData = searchedData?.data?.[draftTestsKey]?.tests || [];

    dispatch(testSearch(navigate, tokenData, dataLimit, searchData, nextPage, testType));
    setData(testsData);
  };

  useEffect(() => {
    dispatch(
      testSearch(navigate, tokenData, dataLimit, searchData, page, testType)
    );
  }, []);


  useEffect(() => {
    isDraft == "false" ? setData(searchedData?.data?.draftFalse?.tests) : setData(searchedData?.data?.draftTrue?.tests)
  }, [searchedData?.data?.draftFalse?.tests]);

  useEffect(() => {
    if (isDraft == "true") {
      setData(searchedData?.data?.draftTrue?.tests)
    } else {
      setData(searchedData?.data?.draftFalse?.tests)
    }
  }, [isDraft]);



  const handleTestDelete = async (e) => {
    const ids = e.target.getAttribute("name");
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/teststatus/${ids}`,
        { isDraft: undefined, isArchive: false, status: true },
        {
          headers: {
            Authorization: `Bearer ${tokenData}`,
          }
        }
      );
      // dispatch(testSearch(navigate, tokenData, dataLimit, searchData, page, testType));
      const updatedData = data.filter((test) => test._id !== ids);
    setData(updatedData);
    } catch (error) {
      if (error.message === "Network Error" || error.response.status === 500) {
        dispatch(errorCall(error))
        return;
      }
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  }



  const handleDeleteTest = async (e) => {
    const result = await Swal.fire({
      title: 'Do you want to delete the test?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3ab2e4',
      cancelButtonColor: 'grey',
      cancelButtonText: "No",
      confirmButtonText: 'Yes',

      customClass: {
        confirmButton: 'Swal_confirm',
        cancelButton: 'Swal_cancel',
        icon: 'Swal_icon',
        title: 'Swal_title'
      },
    });

    if (result.isConfirmed) {
      handleTestDelete(e)
    }
  }


  return (
    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<-------------------------------- New view---------------------------------------->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    <div>
      <SettingsNavbar
        newSidedata={newSidedata}
        newonSidedataChange={onSidedataChange}
        navdata={{
          header: "All Tests",
          buttonText: "Test Button",
          buttonPath: "/test/test-add",
        }}
      />
      <div className="container-fluid">
        <div className="row g-3">
          {permissionList?.includes("65bb92c853e762c89b9af16a") && (
            <div className="col-md-12">
              <div className="companyAdmin_action_area mt-4">
                <div className="add_companyAdmins">
                  <Link
                    to={"/test/test-add"}
                    className="add_companyAdmin_link d-flex align-items-cente txt__sm w-25"
                  >
                    <span className="icon d-flex align-items-center">
                      <svg
                        width="14px"
                        height="16px"
                        viewBox="0 0 14 16"
                        version="1.1"
                        className="me-2"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          id="Page-1"
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g id="add-skill" fill="#000000" fillRule="nonzero">
                            <path
                              d="M7,7 C7.27614237,7 7.5,7.22385763 7.5,7.5 L7.5,9 L9,9 C9.27614237,9 9.5,9.22385763 9.5,9.5 C9.5,9.77614237 9.27614237,10 9,10 L7.5,10 L7.5,11.5 C7.5,11.7761424 7.27614237,12 7,12 C6.72385763,12 6.5,11.7761424 6.5,11.5 L6.5,10 L5,10 C4.72385763,10 4.5,9.77614237 4.5,9.5 C4.5,9.22385763 4.72385763,9 5,9 L6.5,9 L6.5,7.5 C6.5,7.22385763 6.72385763,7 7,7 Z"
                              id="Path"
                            />{" "}
                            <path
                              d="M3,1.5 L2,1.5 C0.8954305,1.5 0,2.3954305 0,3.5 L0,14 C0,15.1045695 0.8954305,16 2,16 L12,16 C13.1045695,16 14,15.1045695 14,14 L14,3.5 C14,2.3954305 13.1045695,1.5 12,1.5 L11,1.5 L11,2.5 L12,2.5 C12.5522847,2.5 13,2.94771525 13,3.5 L13,14 C13,14.5522847 12.5522847,15 12,15 L2,15 C1.44771525,15 1,14.5522847 1,14 L1,3.5 C1,2.94771525 1.44771525,2.5 2,2.5 L3,2.5 L3,1.5 Z"
                              id="Path"
                            />{" "}
                            <path
                              d="M8.5,1 C8.77614237,1 9,1.22385763 9,1.5 L9,2.5 C9,2.77614237 8.77614237,3 8.5,3 L5.5,3 C5.22385763,3 5,2.77614237 5,2.5 L5,1.5 C5,1.22385763 5.22385763,1 5.5,1 L8.5,1 Z M5.5,0 C4.67157288,0 4,0.671572875 4,1.5 L4,2.5 C4,3.32842712 4.67157288,4 5.5,4 L8.5,4 C9.32842712,4 10,3.32842712 10,2.5 L10,1.5 C10,0.671572875 9.32842712,0 8.5,0 L5.5,0 Z"
                              id="Shape"
                            />
                          </g>
                        </g>
                      </svg>
                      Add New Test
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          )}
          <div className="col-12">
            <div className="bg-white p-3 rounded">
              <div className="row g-2">
                <div className="col-md-3">
                  <div className="position-relative list_search_fild">
                    <input
                      type="search"
                      onChange={(e) => handleSearch(e)}
                      name="query"
                      placeholder="Search"
                      aria-label=""
                      className="form-control h-100"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <select
                    className=" form-control form-control-custom"
                    onChange={(e) => {
                      handleType(e);
                    }}
                    value={testType}
                  >
                    <option value="custom test">MCQ Test</option>
                    <option value="logical test">Logical Test</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <select
                    className="  form-control form-control-custom"
                    defaultValue={isDraft}
                    onChange={(e) => {
                      setDraft(e.target.value);
                    }}
                  >
                    <option value="" hidden>
                      Test Type
                    </option>
                    <option value="false">Live</option>
                    <option value="true">Draft</option>
                  </select>
                </div>
                <div className="col-md-2 d-flex align-items-center justify-content-between">
                  {/* <button className="button_remove" onClick={()=>resetFilter()}>Clear Filter</button> */}
                  <select
                    className=" form-control form-control-custom"
                    onChange={(e) => {
                      handleLimit(e);
                    }}
                    style={{ width: "61px" }}
                  >
                    <option>10</option>
                    <option>20</option>
                    <option>50</option>
                    <option>100</option>
                  </select>
                </div>
                <div className="col-md-1 d-flex align-item-center justify-content-center">
                  <button
                    className="border-0 p-1 bg-transparent"
                    onClick={() => {
                      viewType === "Grid"
                        ? setViewType("Table")
                        : setViewType("Grid");
                    }}
                  >
                    {viewType === "Grid" ? (
                      <MdOutlineGridOn className="text-primary fs-5" />
                    ) : (
                      <CiViewList className="text-primary fs-5" />
                    )}
                  </button>
                  {
                    searchedData?.data?.statusTrue?.tests?.length > 0 &&
                    <button style={{ border: '0px', backgroundColor: 'transparent', fontSize: '25px' }} title="Trash bin">
                      <NavLink to="/test/trash-bin" state={{ from: location }} id="creat_new_test_btn" className="text-danger"><i className="fa-solid fa-trash-can-arrow-up"></i></NavLink>
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="table_box p-3">
              {viewType == "Grid" && (
                <div className="custom_test_cards h-auto border-0 pb-4 ps-2 pe-2 bg-white">
                  <h4 className="customs_text fw-bold mb-2">Custom Tests</h4>
                  <React.Fragment>
                    <div className="">
                      <div className="row g-3">
                        {!isLoadingCustom && testResult ? (
                          data?.length === 0 ? (
                            <div colSpan="6" style={{ textAlign: " center" }}>
                              <b style={{ color: "grey" }}>No Data</b>
                            </div>
                          ) : (
                            data?.map((element) => (
                              <div
                                key={element._id}
                                className="col-md-6 col-lg-4"
                                onClick={() => {
                                  permissionList?.includes(
                                    "65bb973f53e762c89b9af17b"
                                  ) &&
                                    navigate(
                                      `/test/inner-test/${element._id}`,
                                      {
                                        state: { from: location },
                                      }
                                    );
                                }}
                              >
                                <div className="card h-100">
                                  <div className="card-body">
                                    <h5 className="card-title mb-0">
                                      {element.title}
                                    </h5>
                                    <p className="card-text mb-0">
                                      {element.details}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))
                          )
                        ) : (
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{ textAlign: "center", height: "100px" }}
                          >
                            <div className="loader"></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              )}
              {viewType == "Table" && (
                <div className="table-responsive scroll_table">
                  <table className="table  table-striped">
                    <thead>
                      <tr>
                        <th className="txt__md  fw-bold">Test Name</th>
                        <th className="text-center">Questions Count</th>
                        <th className="text-center">Last Updated</th>
                        <th className="text-center ">Categories Count</th>
                        <th className="text-center">Test Type</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length > 0 ? (
                        data?.map((element, index) => (
                          <tr key={index}>
                            <td
                              onClick={() =>
                                permissionList?.includes(
                                  "65bb973f53e762c89b9af17b"
                                ) &&
                                navigate(`/test/inner-test/${element._id}`, {
                                  state: { from: location },
                                })
                              }
                            >
                              <button className="border-0 d-flex align-items-center bg-transparent">
                                <b className="">
                                  <p className="text-primary">
                                    {element.title}
                                  </p>
                                </b>
                              </button>
                            </td>
                            <td>
                              <div className="text-center flex-wrap">
                                <div key={"index"}>
                                  <span style={{ fontSize: "12px" }}>
                                    {element.questionsCount}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="text-center ">
                              <div className="align-items-center">
                                {element.updatedAt.slice(0, 10)}
                              </div>
                            </td>
                            <td className="text-center text-danger">
                              {element.qesCategoriesCount}
                            </td>
                            <td className="text-center">
                              {element.testType == "custom test"
                                ? "MCQ Test"
                                : "Logical Test"}
                            </td>
                            <td>
                              <div className="d-flex justify-content-center ">
                                <button
                                  className="custom_table_btn edit_btn"
                                  title="Edit"
                                  onClick={() =>
                                    navigate(`/test/test-edit/${element._id}`, {
                                      state: { from: location },
                                    })
                                  }
                                >
                                  <img src="/logos/edit_logo.svg" alt="logo" />
                                </button>
                                <button
                                  className="custom_table_btn del_btn"
                                  title="Delete"
                                  name={element._id}
                                  value={"status"}
                                  onClick={handleDeleteTest}
                                >
                                  <img
                                    src="/logos/delete_logo.svg"
                                    alt="logo"
                                    name={element._id}
                                    value={"status"}
                                  />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6}>
                            <p className=" text-center">No Data</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {data?.length > 0 && (
        <>
          <div className="parentPage mt-4">
            <Paginate
              currentPage={page}
              totalPages={
                searchedData?.data
                  ? isDraft == "false"
                    ? searchedData?.data?.draftFalse?.totalPages
                    : isDraft == "true"
                      ? searchedData?.data?.draftTrue?.totalPages
                      : null
                  : 1
              }
              onPageChange={(e) => pageChange(e)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TestScreen;
