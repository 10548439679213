import "./CreateCandidate.css";
import React, { useState, useEffect, useRef } from "react";
import { sourceList } from "../../Redux/settings/source_management/action";
import { useDispatch, useSelector } from "react-redux";
import {
  designationList,
  experienceLevel,
  extractresume,
  extractresume_succ_add,
  fillUpdata
} from "../../Redux/CandidateReducer/action";
import { typeList } from "../../Redux/JobReducer/action";
import { useNavigate } from "react-router-dom";
import "../../Pages/candidates/CreateCandidate.css";
import EducationalData from "../../Components/candidate/addcandidate/EducationalData";
import GeneralData from "../../Components/candidate/addcandidate/GeneralData";
import HardwareData from "../../Components/candidate/addcandidate/HardwareData";
import ExperienceData from "../../Components/candidate/addcandidate/ExperienceData";
import SkillsData from "../../Components/candidate/addcandidate/SkillsData";
import { AiOutlineFilePdf } from "react-icons/ai";
import { LuUpload } from "react-icons/lu";
import Modal from "react-bootstrap/Modal";
import { FaCheck } from "react-icons/fa";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { ImCross } from "react-icons/im";
import { IoSaveOutline } from "react-icons/io5";
import { Document, Page, pdfjs } from "react-pdf";

import {
  add_succ,
  dataFillById,
  updateDesignation,
  updateDesignationExperience,
  updateFirstName,
  updateLastName,
  updateLeadSource,
  updateProfileImage,
  updateResume,
  updateStep,
  validData,
} from "../../Redux/Candidate/createCandidate/action";
import { BlurLoader } from "../../Components/BlurLoader/BlurLoader";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const CreateCandidate = ({ Sidedata, onSidedataChange }) => {
  const inputRef = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null),
  useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const candidateResponse = useSelector((state) => state.addResponseReducer.data);
  const canidiResLoad = useSelector((state) => state.addResponseReducer);
  const leadSourse = useSelector((state) => state?.getSourceSet?.data?.data);
  const lead = useSelector((state) => state?.getSourceSet);
  const candidateData = useSelector((state) => state?.createCandidate);
  const step = useSelector((state) => state?.changeStep);
  const designationData = useSelector(
    (state) => state.Candidate_Reducer.designationData
  );
  const designation = useSelector(
    (state) => state.Candidate_Reducer.designationData
  );
  const experienceData = useSelector(
    (state) => state.Candidate_Reducer.experienceData
  );
  const experience = useSelector(
    (state) => state.Candidate_Reducer
  );
  const [imageName, setImageName] = useState("");
  const extractData = useSelector((state) => state.Candidate_Reducer);
  const { isLoadingResumeExtract, resumeExtractData } = extractData;
  const [previewPDF, setPreviewPDF] = useState(false);
  const [selecetedPdf, setSelecetedPdf] = useState("");
  const [maxPage, setMaxPage] = useState(1);
  const id = localStorage.getItem("id");
  const [selectedCVFile, setSelectedCVFile] = useState(null);
  const [errorMessage,setErrorMessage]=useState({})
  const [isChecked,setIsChecked]=useState(false)
  const [show, setShow] = useState(false);
  const errorGenerate=(field,message)=>{
    !field&&!message?setErrorMessage():
    setErrorMessage((prev) => {
      return {
        ...prev,
        [field]: message,
      };
    })
  }
  const handleFirstName = (e) => {
    delete errorMessage?.first_name
    dispatch(updateFirstName(e.target.value.replace(/[~!@#$%^&*()_\-+={}\[\]|\\/?:;"'<>.,0-9]/g,'').replace(/\s+/g,' ').trimStart()));
    dispatch(validData())
  };
  
  const handleLastName = (e) => {
    delete errorMessage?.last_name
    dispatch(updateLastName(e.target.value.replace(/[~!@#$%^&*()_\-+={}\[\]|\\/?:;"'<>.,0-9]/g,'').replace(/\s+/g,' ').trimStart()));
    dispatch(validData())
  };
  
  const handleLeadSource = (e) => {
    delete errorMessage?.lead_source
    dispatch(updateLeadSource(e.target.value));
    dispatch(validData())
  };
  
  const handleDesignationEXP = (e) => {
    delete errorMessage?.designation_experience
    dispatch(updateDesignationExperience(e.target.value));
    dispatch(validData())
  };
  const handelDesignation = (e) => {
    delete errorMessage?.designation
    dispatch(updateDesignation(e.target.value));
    dispatch(validData())
  };

  const handleTabData = (tabdata) => {
    dispatch(updateStep(tabdata))
    dispatch(validData())
  }

  const handleCVFileChange = async (event) => {
    delete errorMessage?.resume
    const file = event.target.files[0];
    if (file) {
      if (file.type === "application/pdf") {
        if (file.size > 5242880) {
          toast.warn("Please select file smaller than 5MB");
        } else {
          setSelecetedPdf(file);
          setSelectedCVFile(file);
          dispatch(updateResume(file));
          
        }
      } else {
        toast.warn("Please upload a PDF file!");
      }
    }
  };


  const renaming = (url) => {
    const filenameWithExtension = url?.split('/')?.pop() ||"";
    const parts = filenameWithExtension?.split('.')|| ["",""];
    const filename = decodeURI(parts[0]);
    const filetype = parts[1];
    return url ? filename + "." + filetype :""
  }



  function truncateFilename(filename, wordCount) {
    if (typeof filename !== "string" || wordCount <= 0) {
      return filename;
    }
    let extension = filename.split(".").pop();
    let truncated =
      filename.length > wordCount
        ? filename.substring(0, wordCount) + "..."
        : filename.replace(extension, "");
    return truncated + "." + extension;
  }

  const handleProfileImage = (e) => {
    const file = e ? e?.target?.files[0] : "";
    if (file) {
      if (
        file.type == "image/jpeg" ||
        file.type == "image/gif" ||
        file.type == "image/png" ||
        file.type == "image/svg"
      ) {
        if (file.size < 5000000) {
          let truncatedFilename = truncateFilename(file.name, 8);
          setImageName(truncatedFilename);
          const reader = new FileReader();
          reader.onload = function (event) {
            dispatch(updateProfileImage(event.target.result));
          };
          reader.readAsDataURL(file);
        } else {
          toast.warn("Please Upload An Image Less Then 5Mb");
        }
      } else {
        toast.warn("Please Upload An Image File");
      }
    }
  };

  const removePdf = (e) => {
    setSelectedCVFile("")
    setSelecetedPdf(''); 
    dispatch(updateResume(''))
  }

  const removeFile = (e) => {
    setImageName();
    dispatch(
      updateProfileImage("")
    );
  };

  useEffect(() => {
    dispatch(sourceList(navigate));
    dispatch(designationList(navigate));
    dispatch(experienceLevel(navigate));
    dispatch(typeList(navigate, token));
    
  }, []);


  const menuClick = () => {
    onSidedataChange(!Sidedata);
  };


 
  const handleClose = () => {
    setShow(false);
  };


  const handleShow = () => {
    dispatch(validData())
    setShow(true);
    dispatch(extractresume_succ_add(""))
  };


  const saveResume = (event) => {
    dispatch(extractresume(candidateData?.resume, navigate));
  };


  const importData = (event) => {
     setIsChecked(event.target.checked)
    if (event.target.checked == true && selectedCVFile) {
      if (resumeExtractData.length == 0 && event.target.checked ) {
        toast.warn("Please Save The Resume ")
        return
      } else {
        errorGenerate()
        dispatch(fillUpdata(resumeExtractData, navigate));
      }
    } else {
    }
  };


  useEffect(() => {
    if (isLoadingResumeExtract == false) setShow(false);
  }, [isLoadingResumeExtract]);

  
  useEffect(() => {
    if (id) {
      dispatch(dataFillById(navigate, id)).then((response) => {
        setSelecetedPdf(response?.resume);
        setImageName(renaming(response?.profile_image))
      })
      dispatch(add_succ({ candidate: id }));
    }

  }, []);
  return (
    <>
      <section className={`addnewcandidate  ${canidiResLoad.isloading && id || lead.isLoading && id || designation.isloading && id || experience.isLoadingExp && id ?  'body_scroll_hide': ''} `}>
        <div className="title">
          {canidiResLoad.isloading && id|| lead.isLoading && id|| designation.isloading&& id|| experience.isLoadingExp && id  ?<BlurLoader />:null}
          <h2 className="title-box position-relative">
            <button className="fa_bars  d-lg-none" onClick={menuClick}>
              <i className="fa-solid fa-bars"></i>
            </button>
            Add New Candidate
          </h2>
        </div>
        <div className="row create_candidate">
          <div className="col-lg-12 col-md-12">
            <div className="row g-2 align-items-end justify-content-between mb-3">
              <div className="col-auto ">
                <p className="add_p">Applicant Details</p>
              </div>
              <div className="col-12 col-md-auto align-items-center justify-content-end me-2">
                <div className=" d-flex align-items-center justify-content-center justify-content-sm-end mb-3">
                  <button ref={inputRef[0]} className="btn btn-primary" onClick={handleShow}>
                  <span className="text-danger ms-1">*</span>
                    <AiOutlineFilePdf /> Upload CV
                  </button>
                </div>
                <div>
                  {selectedCVFile ? (
                    <div className="d-flex justify-content-center justify-content-sm-end flex-wrap" style={{ gap: "10px" }}>
                      <div>
                        <a href="#" style={{ color: '#3ab2e4' }} onClick={() => setPreviewPDF(true)}>{selectedCVFile && selectedCVFile.name}</a>
                        <ImCross
                          onClick={(e) => {
                            removePdf(e);
                          }}
                          className="text-secondary p-1"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="importData"
                          label="import data"
                          onClick={(e) => importData(e)}
                          className="ms-2 me-2"
                        />
                        <label
                          htmlFor="importData"
                          style={{ cursor: "pointer" }}
                        >
                          Fill Up The Fields With CV
                        </label>
                      </div>
                    </div>
                  ) : selecetedPdf!='' && selecetedPdf!=null?(
                    <div className="d-flex justify-content-center justify-content-sm-end flex-wrap" style={{ gap: "10px" }}>
                      <div>
                        <a href="#" style={{ color: '#3ab2e4' }} onClick={() => setPreviewPDF(true)}>{renaming(selecetedPdf)}</a>
                      </div>
                    </div>
                  ):null}
                  <p className="text-danger ms-1">{errorMessage?.resume}</p>
                </div>
              </div>
            </div>

            <div className="newcandidate-form-box ">
              <div className="d-md-flex align-items-center justify-content-around">
                <div>
                  <div className="applicatant-img">
                    {candidateData?.profile_image ? (
                      <img
                        src={candidateData?.profile_image}
                        alt="Preview"
                        className="img-fluid w-100 "
                        style={{ maxHeight: "170px" }}
                      />
                    ) : (
                      <img
                        src="/images/user-alt.png"
                        alt="Profile"
                        className="img-fluid w-100"
                      />
                    )}
                    <label
                      htmlFor="image-upload"
                      className=" text-white profile-img-upload"
                    >
                      <input
                        type="file"
                        id="image-upload"
                        name="profile_image"
                        accept="image/jpeg,image/gif,image/png"
                        aria-required="false"
                        aria-invalid="false"
                        onChange={(e) => handleProfileImage(e)}
                      />
                      <i className="fa-solid fa-pen"></i>
                    </label>
                  </div>
                  <p className="mt-0 d-md-none text-light text-center text-md-start">
                    {imageName ? (
                      imageName
                    ) : (
                      <span style={{ fontSize: "12px" }}>
                        JPG/PNG/JPEG  Are Allowed
                      </span>
                    )}
                    <b style={{color:'red'}}>
                      <IoMdClose
                        className={!imageName && "d-none "}
                        onClick={(e) => removeFile(e)}
                      />
                    </b>
                  </p>
                </div>
                <div className="aplicatant-details">
                  <div className="row">
                    <div className="col-md-4">
                      <label className="text-white">First Name</label>
                      <span className="text-danger ms-1">*</span>
                      <input
                        ref={inputRef[1]}
                        type="text"
                        name="first_name"
                        className="form-control"
                        aria-required="true"
                        aria-invalid="false"
                        placeholder="Enter your first name"
                        autoComplete="off"
                        value={candidateData?.first_name}
                        onChange={(e) => handleFirstName(e)}
                      />
                      <p className="text-danger ms-1">{errorMessage?.first_name}</p>
                    </div>
                    <div className="col-md-4">
                      <label className="text-white">Last Name</label>
                      <span className="text-danger ms-1">*</span>
                      <input
                        ref={inputRef[2]}
                        type="text"
                        name="last_name"
                        className="form-control"
                        aria-required="true"
                        placeholder="Enter your last name"
                        autoComplete="off"
                        value={candidateData?.last_name}
                        onChange={(e) => handleLastName(e)}
                        aria-invalid="false"
                      />
                      <p className="text-danger ms-1">{errorMessage?.last_name}</p>
                    </div>
                    <div className="col-md-4">
                      <label className="text-white">Lead Source </label>
                      <span className="text-danger ms-1">*</span>
                      <span className="option-container">
                        <i className="fa-solid fa-caret-down"></i>
                        <select
                          ref={inputRef[3]}
                          onChange={(e) => handleLeadSource(e)}
                          className="form-control"
                          value={candidateData?.lead_source}
                        >
                          <option value="" >Select Lead source</option>
                          {leadSourse
                            ? leadSourse.map((source, index) => (
                              <option hidden={source.status!=1} value={source.id} key={index} >
                                {source.name}
                              </option>
                            ))
                            : null}
                        </select>
                      </span>
                      <p className="text-danger ms-1">{errorMessage?.lead_source}</p>
                    </div>
                    <div className="col-md-6 col-xl-4 ">
                      <label
                        className="text-white"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Designation Experience
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <span className="option-container d-block">
                        <i className="fa-solid fa-caret-down"></i>
                        <select
                          ref={inputRef[4]}
                          onChange={(e) => {
                            handleDesignationEXP(e);
                          }}
                          className="form-control"
                          value={candidateData?.designation_experience}
                        >
                          <option value="">Select Experience</option>
                          {experienceData
                            ? experienceData?.map((el, i) => (
                              <option hidden={el.status!=1} key={i} value={el.id}>
                                {el.name}
                              </option>
                            ))
                            : null}
                        </select>
                      </span>
                      <p className="text-danger ms-1">{errorMessage?.designation_experience}</p>
                    </div>
                    <div className="col-md-6 col-xl-4  ">
                      <label className="text-white">Designation</label>
                      <span className="text-danger ms-1">*</span>
                      <span className="option-container">
                        <i className="fa-solid fa-caret-down"></i>
                        <select
                          ref={inputRef[5]}
                          name="designation"
                          className="form-control"
                          aria-required="true"
                          aria-invalid="false"
                          value={candidateData.designation}
                          onChange={(e) => {
                            handelDesignation(e);
                          }}
                        >
                          <option value="" placeholder="Select Designation">
                            Select Designation
                          </option>
                          {designationData?.length>0
                            ? designationData?.map((el, i) => (
                              <option
                                key={i}
                                hidden={el.status!=1}
                                value={el.designation_id}
                              >
                                {el.name}
                              </option>
                            ))
                            : null}
                        </select>
                      </span>
                      <p className="text-danger ms-1">{errorMessage?.designation}</p>
                    </div>
                  </div>
                </div>
              </div>
              <p className=" mb-0 d-none d-md-block text-light text-center text-sm-start">
                {imageName ? (
                  imageName
                ) : (
                  <span style={{ fontSize: "12px" }}>
                    JPG/PNG/JPEG Are
                    Allowed
                  </span>
                )}
                <b style={{color:'red'}}>
                  <IoMdClose
                    className={!imageName && "d-none"}
                    onClick={(e) => removeFile(e)}
                  />
                </b>
              </p>
            </div>
            <div className="middle-section">
              <ul id="myTab" role="tablist" className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    id="Profile-tab"
                    data-bs-toggle="tab"
                    href="#Profile"
                    role="tab"
                    aria-controls="Profile"
                    aria-selected="true"
                    className="nav-link active px-3"
                  >
                    Profile Details
                  </a>
                </li>
              </ul>
              <div id="myTabContent" className="tab-content">
                <div
                  id="Profile"
                  role="tabpanel"
                  aria-labelledby="Profile-tab"
                  className="tab-pane fade show active"
                >
                  <ul className="nav nxt-tab tab_content_tabChange">
                    <li className="nav-item">
                      <button onClick={() => handleTabData("General")}
                        className={
                          step?.step == "General" ? "nav-link active" : "nav-link"
                        }
                      >
                        General Details
                      </button>
                    </li>
                    <li className="nav-item" >
                      <button  disabled={!candidateResponse}  onClick={() => handleTabData("Education")}
                        className={
                          step?.step == "Education"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        Education Data
                      </button>
                    </li>
                    <li className="nav-item" >
                      <button disabled={!candidateResponse} onClick={() => handleTabData("Hardware")}
                        className={
                          step?.step == "Hardware" ? "nav-link active" : "nav-link"
                        }
                      >
                        Hardware Requirements
                      </button>
                    </li>
                    <li className="nav-item" >
                      <button disabled={!candidateResponse} onClick={() => handleTabData("Experience")}
                        className={
                          step?.step == "Experience"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        Work Experience
                      </button>
                    </li>
                    <li className="nav-item" >
                      <button  disabled={!candidateResponse} onClick={() => handleTabData("Skill")}
                        className={
                          step?.step == "Skill" ? "nav-link active" : "nav-link"
                        }
                      >
                        Skills
                      </button>
                    </li>
                  </ul>
                  {step?.step === "General" ? (
                    <GeneralData inputRef={inputRef} errorGenerate={errorGenerate} errorMessage={errorMessage}/>
                  ) : step?.step === "Education" ? (
                    <EducationalData inputRef={inputRef} />
                  ) : step?.step === "Hardware" ? (
                    <HardwareData inputRef={inputRef} />
                  ) : step?.step === "Skill" ? (
                    <SkillsData inputRef={inputRef} errorGenerate={errorGenerate} errorMessage={errorMessage} />
                  ) : step?.step === "Experience" ? (
                    <ExperienceData inputRef={inputRef} errorGenerate={errorGenerate} errorMessage={errorMessage}/>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      >
        <Modal.Header closeButton={!isLoadingResumeExtract}>
          <Modal.Title className="fw-bold fs-5">
            {/* Upload New Resume */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pdfModal pt-4 pb-5">
            <h4 style={{ fontSize: "22px" }}>Upload candidate`s CV/Resume</h4>
            {!isLoadingResumeExtract && (
              <>
                <div className="pdfUploadArea">
                  <input
                    type="file"
                    id="pdfFile"
                    className="d-none"
                    accept="application/pdf"
                    onChange={handleCVFileChange}
                  />
                  <label htmlFor="pdfFile" className="btn btn-primary">
                    <LuUpload /> Upload
                  </label>
                  <p className="mt-2">
                    {selectedCVFile ? (
                      <span className="text-success">
                        {selectedCVFile.name}
                        <FaCheck />
                      </span>
                    ) : (
                      "Supports only pdf."
                    )}
                  </p>
                </div>

                {selectedCVFile && !isLoadingResumeExtract && (
                  <div className="mt-4">
                    <button
                      className="btn btn-primary"
                      onClick={(e) => saveResume(e)}
                      disabled={isLoadingResumeExtract ? true : false}
                    >
                      <IoSaveOutline className="m-1" />
                      Save
                    </button>
                  </div>
                )}
              </>
            )}

            {isLoadingResumeExtract && (
              <>
                <div className="progressARea mt-3">
                  <div className="progress">
                    <div className="progress-value" />
                  </div>
                </div>
                <p className="text-primary mt-2 fs-6 text-uppercase">
                  Importing Data...
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </p>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="j_pdf_show_custom"
        show={previewPDF}
        onHide={() => setPreviewPDF(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <Modal.Header closeButton={previewPDF}>
          <Modal.Title className="fw-bold fs-5">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pdfModal d-flex align-items-center justify-content-center flex-column pt-4 pb-5">
            <table className=" w-100 d-flex justify-content-center">
              <tbody>
                <tr>
                  <td>
                    <Document file={selecetedPdf} onLoadSuccess={({ numPages }) => { setMaxPage(numPages) }}>
                      {[...Array(maxPage)].map((_, index) => (
                        <Page key={index + 1} pageNumber={index + 1} renderTextLayer={false} renderAnnotationLayer={false} />
                        // each  page is able to render a page of pdf, thats why we are loading all pages withh different page number using loop
                      ))}
                    </Document>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateCandidate;