import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginCall } from "../../Redux/AuthReducer/action";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
// Note: The CSS import had a typo in your code, assuming it's named 'login.css'
import "../Authentication/login.css";
import { toast } from "react-toastify";
import axios from "axios";
import { LuClipboardList } from "react-icons/lu";
import Spiner1 from "../Spiner1";
import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';
const Login = (props) => {
  const tokenData = localStorage.getItem("token");
  const navigate = useNavigate();
  const loginData = useSelector((state) => state.Login_Reducer);
  const [userName, setUserName] = useState("");
  const [passWord, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [contentLoader, setContentLoader] = useState(true);
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [disable, setDisable] = useState(false);
  const checkValidEmail = (email) => {
    const validation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return validation.test(String(email).toLowerCase());
  };
  

  const fetchFileContent = async (file) => {
    try {
      const response = await fetch(file);
      const content = await response.text();
      return(content);
    } catch (error) {
      throw error;
    }
  };

  const loginAdmin = (e) => {
    e.preventDefault();
    if (!userName) {
      setDisable(true);
      toast.warn("Please Provide Your Email Address");
      setTimeout(() => {
        setDisable(false)
      }, 2000);
    }
    else if (!checkValidEmail(userName)) {
      setDisable(true);
      toast.warn("Please Enter a Valid Email address");
      setTimeout(() => {
        setDisable(false)
      }, 2000);
    }
    else if (!passWord) {
      setDisable(true);
      toast.warn("Please Provide Your Password");
      setTimeout(() => {
        setDisable(false)
      }, 2000);
    } else {
      setDisable(false);
      let login_credential = {
                email: userName,
                password: passWord,
                remember: rememberMe
        }

      const publicKey=process.env.REACT_APP_PUBLIC_KEY;
     
      const encryptor = new JSEncrypt();
      encryptor.setPublicKey(publicKey);
      const windowTime=Date.now();
      var encrypted = encryptor.encrypt(JSON.stringify({...login_credential,windowTime:btoa(windowTime)}));
      dispatch(loginCall(encrypted, navigate))
        .then((res) => {
            toast.success('Login Successfully')
            localStorage.setItem("token", res.data.access_token);
            localStorage.setItem("userPermission", res.data.user.access)
            props?.setShow(false);
            if (rememberMe) {
              var credential_local = CryptoJS.AES.encrypt(JSON.stringify(login_credential), process.env.REACT_APP_BACKEND_SECRET).toString();
              localStorage.setItem("login_credential", credential_local);
            } else {
              localStorage.getItem('login_credential') && localStorage.removeItem("login_credential");
            }
            navigate("/");
        })
        .catch((error) => {
          console.error("Login error:", error);
          if(error?.token_error != undefined){
            toast.error(error?.token_error);
          }
          const passwordError = error?.response?.data?.password?.map(e => (e))
          if (passwordError === "The password must be at least 8 characters.") {
            toast.warn("The Password Must Be At Least 8 Characters");
          }
          else if (error?.response?.data?.error === "Invalid user id or password.") {
            toast.warn("Please provide a valid email address or password");
          }else if (error?.response?.data?.error == "Your request token is already expired") {
            toast.error("Your Request token is already expired");
          }else if (error?.response?.data?.error == "Your request token is not valid") {
            toast.error("Your request token is not valid");
          }
          console.error("Login error:", error?.response?.data?.error);
        });
    }
  };

  const handleImageLoaded = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleImageError = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    props.setPath(location)
  }, [props]);

  useEffect(() => {
    if(localStorage.getItem("login_credential")!=null){
      try{
        let bytes = CryptoJS.AES.decrypt(localStorage.getItem("login_credential"),process.env.REACT_APP_BACKEND_SECRET);
        let login_data=JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUserName(login_data.email);
        setPassword(login_data.password);
        setRememberMe(login_data.remember);
      }catch(error){
        localStorage.removeItem("login_credential");
        navigate('/login');
      }
      
      
    }
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      loginAdmin(e);
    }
  };


  const webToken = localStorage.getItem("token");

  useEffect(() => {
    if (webToken) {
      navigate('/')
    }
  }, [webToken]);

  return (
    <section className="login">
      {isLoading &&
        <div className="loading_box d-flex align-items-center">
          <LuClipboardList style={{ fontSize: '72px', color: 'white', fontWeight: "100" }} />
          <div>
            <div className="heading loading_animation"><p>I</p><p>n</p><p>t</p><p>e</p><p>r</p><p>v</p><p>i</p><p>e</p><p>w</p></div>
            <p className="large_text">Screening</p>
          </div>
        </div>
      }
      <div className={`login_box ${isLoading && "active"}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="lt_site">
                <img
                  src="/images/login-img.png"
                  onLoad={handleImageLoaded}
                  onError={handleImageError}
                  alt="image"
                />
                <p>Choose the right candidate in an organized way</p>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div className="rt-site mt-4">
                <img src="/images/login.png" alt="Login Logo" />
                <div className="form_input_box form_input_box_password">
                  <input
                    type="text"
                    name="email"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value.replace(' ', ''))}
                    onKeyDown={handleKeyPress}
                    placeholder="User Email"
                    className="form-control mb-3"
                    autoComplete="off"
                  />
                  <div className="form_input_box form_input_box_password">
                    <input
                      type={showPass ? "text" : "password"}
                      className="form_input_squar"
                      value={passWord}
                      onChange={(e) => setPassword(e.target.value.replace(' ', ''))}
                      onKeyDown={handleKeyPress}
                      name="password"
                      placeholder="Password"
                      autoComplete="off"
                    />
                    <button
                      type="button"
                      className="passToggler"
                      onClick={() => setShowPass(!showPass)}
                    >
                      {showPass ? (
                        <i className="fa-regular fa-eye"></i>
                      ) : (
                        <i className="fa-regular fa-eye-slash"></i>
                      )}
                    </button>
                  </div>
                  {disable === true ? (
                    <button type="submit" disabled className="logDisable">
                      Login
                    </button>
                  ) : (
                    <button type="submit" className="log" disabled={loginData.isLoading} onClick={loginAdmin}>
                      {loginData.isLoading === false ?
                        "Login" :
                        <Spiner1 />
                      }
                    </button>
                  )}
                  <input
                    className="form_input_box"
                    id="remember"
                    name="remember"
                    type="checkbox"
                    checked={rememberMe}
                    onClick={
                      () => {
                        setRememberMe(!rememberMe);
                      }
                    }
                  /> <laber className="form_input_box_label" for="remember">Remember Me</laber>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
