import axios from "axios";
import { CODE_EXAM_FAIL, CODE_EXAM_REQ, CODE_EXAM_SUCC } from "./actionType";
import { errorCall } from "../ErrorHandling/action";

export const Post_Exam_Req = (data) => ({
    type: CODE_EXAM_REQ,
    payload: data,
});
export const Post_Exam_Succ = (data) => ({
    type: CODE_EXAM_SUCC,
    payload: data,
});
export const Post_Exam_Fail = (error) => ({
    type: CODE_EXAM_FAIL,
    payload: error,
});

export const codeExam = (webToken, navigate, language, code) => {
    return async function test(dispatch, getState) {
        dispatch(Post_Exam_Req());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}api/execute`,
                {
                    language: language,
                    code: code,
                },
                {
                    headers: {
                        Authorization: `Bearer ${webToken}`,
                    },
                }
            );
            dispatch(Post_Exam_Succ(response?.data));
            return response?.data
        } catch (error) {
            if (error?.message === "Network Error" || error?.response?.status === 500) {
                dispatch(errorCall(error))
                return;
            }
            dispatch(Post_Exam_Fail(error));
            if (error?.response?.data?.message === "Unauthenticated") {
                localStorage.removeItem("token");
                navigate("/login");
            }
            return error
        }
    };
};
