import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Editor from "@monaco-editor/react";
import { codeExam } from "../../../Redux/CodeExam/action";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spiner1 from "../../../Components/Spiner1";
const CodeExamPage = () => {

    const languageArray = [
        'ada',          'algol',        'awk',       'bash',
        'bc',           'befunge',      'blockly',   'c',
        'c99',          'clisp',        'clojure',   'cobol',
        'coffeescript', 'cpp',          'cpp14',     'csharp',
        'd',            'dart',         'elixir',    'erlang',
        'factor',       'falcon',       'fantom',    'fasm',
        'forth',        'fortran',      'freebasic', 'fsharp',
        'gccasm',       'go',           'groovy',    'hack',
        'haskell',      'haxe',         'icon',      'intercal',
        'java',         'jlang',        'kotlin',    'lolcode',
        'lua',          'mozart',       'nasm',      'nemerle',
        'nim',          'nodejs',       'objc',      'ocaml',
        'octave',       'pascal',       'perl',      'php',
        'picolisp',     'pike',         'prolog',    'python2',
        'python3',      'r',            'racket',    'rhino',
        'ruby',         'rust',         'scala',     'scheme',
        'smalltalk',    'spidermonkey', 'sql',       'swift',
        'tcl',          'unlambda',     'vbn',       'verilog',
        'whitespace',   'yabasic'
      ];

    const [langCodeData, setLangCodeData] = useState(false);
    const [language, setLanguage] = useState(languageArray[0]);
    const [code, setCode] = useState("");
    const codeResult = useSelector((state) => state.codeExam.data);
    const loader = useSelector((state) => state.codeExam.isLoading);

    const webToken = localStorage.getItem("token");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const langCode = () => {
        setLangCodeData(!langCodeData);
    };
    function handleEditorChange(value, event) {
        setCode(value)
    }


    const chooseLanguage = (e) => {
        setLanguage(e)
        setLangCodeData(!langCodeData);
    }

    const createCodeExam = () => {
        dispatch(codeExam(webToken, navigate, language, code)).then(() => {
        });
    };


    const renderLanguageItems = (startIndex, endIndex) => {
        return languageArray.slice(startIndex, endIndex).sort((a, b) => a.localeCompare(b)).map((menuLanguage, index) => (
            <li key={index} className={`lang-item ${language === menuLanguage && 'active'}`} onClick={() => chooseLanguage(menuLanguage)}>
                <span>{menuLanguage.charAt(0).toUpperCase()+ menuLanguage.slice(1)}</span> <i className={`fa-solid fa-check ${language === menuLanguage ? 'd-block' : 'd-none'}`}value="java"></i>
            </li>
        ));
    }

    useEffect(() => {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function (event) {
          window.history.pushState(null, null, window.location.href);
        };
      }, [])
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="w-100  position-relative pt-3">
                            <img className="" src="/site_logo.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="container mb-5">
                <div className="row mb-3">
                    <div className="col-12 pb-3 pt-0">
                        <div className="code_test_time_box flex-wrap flex-sm-nowrap d-flex align-items-center justify-content-between">
                            <h3 className="mb-0">Python Test</h3>
                            <div className=" d-flex align-items-center flex-wrap flex-sm-nowrap">
                                <p className="mb-0 me-3">Remaining Time</p>
                                <p className="mb-0">
                                    <span>01</span>
                                    <strong>:</strong>
                                    <span>18</span>
                                    <strong>:</strong>
                                    <span>42</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-3">
                    <div className="col-md-6">
                        <div className="test_coding_headtext_body">
                            <div className="test_coding_headtext border-bottom mb-3">
                                {/* <span>Python</span> */}
                                <p className="mb-2 mt-3">Question No.1</p>
                            </div>
                            <h4>1. Question Title Here.</h4>
                            <p className="mb-4">
                                Among continents, European countries accounted for the highest
                                net worth of flower exports, with shipments amounting to $4
                                billion. The largest exporter in Europe was the Netherlands at
                                $3.5 billion, followed by Italy.
                            </p>
                            <p>
                                Latin America (excluding Mexico) accounted for $2 billion of
                                flower exports and represented the second-largest exporter
                                region. The largest exporters in Latin America were Colombia at
                                $1 billion and Ecuador at $800 million.
                            </p>
                            <div>
                                <div className="my-4">
                                    <h4>Complexity</h4>
                                    <p>
                                        Among continents, European countries accounted for the
                                        highest net worth of flower exports, with shipments
                                        amounting to $4 billion. The largest exporter in Europe was
                                        the Netherlands at $3.5 billion, followed by Italy.
                                    </p>
                                </div>
                                <div className="coding_test_scroll">
                                    <div className="mb-4">
                                        <h5>Example 1:</h5>
                                        <p>
                                            Input: nums = [2,7,11,15], target = 9 <br />
                                            Output: [0,1] <br />
                                            Explanation: Because nums[0] + nums[1] == 9, we return [0,
                                            1]. <br />
                                        </p>
                                    </div>

                                    <div className="my-4">
                                        <h5>Example 1:</h5>
                                        <p>
                                            Input: nums = [2,7,11,15], target = 9 <br />
                                            Output: [0,1] <br />
                                            Explanation: Because nums[0] + nums[1] == 9, we return [0,
                                            1]. <br />
                                        </p>
                                    </div>
                                    <div className="my-4">
                                        <h5>Example 1:</h5>
                                        <p>
                                            Input: nums = [2,7,11,15], target = 9 <br />
                                            Output: [0,1] <br />
                                            Explanation: Because nums[0] + nums[1] == 9, we return [0,
                                            1]. <br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-md-6 d-flex flex-column g-3"
                        style={{ gap: "15px" }}
                    >
                        <div className="coding_edit_box position-relative">
                            <div className=" position-relative">
                                <button className="codding_btn" onClick={langCode}>
                                    {language.charAt(0).toUpperCase()+ language.slice(1)} <i className="fa-solid fa-angle-down"></i>
                                </button>
                                {langCodeData ? (
                                    <div className="lang_option flex-wrap">
                                        {Array.from({ length: Math.ceil(languageArray.length / 15) }, (_, index) => (
                                            <ul key={index} className="list-unstyled mb-0">
                                                {renderLanguageItems(index * 15, (index + 1) * 15)}
                                            </ul>
                                        ))}
                                    </div>
                                ) : ("")}
                            </div>
                            <div className="codeEditor mt-3">
                                <Editor
                                    height="38vh"
                                    defaultLanguage={language}
                                    defaultValue="// some comment"
                                    // onChange={(e) => {
                                    //     setCode(e.target.value);
                                    // }}
                                    onChange={handleEditorChange}
                                />
                            </div>
                        </div>
                        <div className="coding_result_box">
                            <div className="result_box mt-0 border-0 flex-column d-flex justify-content-between">
                                <div>
                                    <ul className="list-unstyled d-flex align-items-center justify-content-between">
                                        <li className="d-flex align-items-center result_text me-1">
                                            <p className="border-end mb-0 pe-3">Testcase</p>
                                            <p className="ms-3 mb-0 active">Result</p>
                                        </li>
                                        <li className="d-flex align-items-center result_btn">
                                            <p className="border-end mb-0 pe-3">
                                                Time : <span>{codeResult?.cpuTime ? codeResult?.cpuTime : 0}</span>
                                            </p>
                                            <p className="ms-3 mb-0 active">
                                                Space : <span>{codeResult?.memory ? codeResult?.memory : 0}</span>
                                            </p>
                                        </li>
                                    </ul>
                                    <div className=" resalt_scroll">
                                        <div>
                                            {loader ? <h6 className="text-start">Compiling...</h6> :
                                                <div className={`${codeResult?.output?.includes("jdoodle") || codeResult?.output?.includes("error")?"text-danger":null}`}>
                                                    {codeResult?.output?.includes("jdoodle") ?
                                                        <p className="text-start"> {codeResult?.output?.replace(/jdoodle/g, "interview_screener")}</p>
                                                        : <p className="text-start"> {codeResult?.output}</p>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-end code_run_btn">
                                    <button onClick={() => createCodeExam()}>{loader ? <Spiner1 />
                                        : "Run"}</button>
                                    <button>Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default CodeExamPage;
