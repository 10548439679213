import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import ReactStars from "react-rating-stars-component";
import Swal from "sweetalert2";

const Note = ({ candidate }) => {
    const [currentProfileMenu, updateprofileMenu] = useState('All');
    const [noteList, setNoteList] = useState([])
    const [noteDetails, setNoteDetails] = useState({ notes: '', note_type: '', rating: 0 })
    const generalMessageRef = useRef(null);
    const feedbackRef = useRef(null);
    const callbackRef = useRef(null);
    const [starsKey, setStarsKey] = useState(Date.now());

    const [editNoteDetails, setEditNoteDetails] = useState(null)

    const profileMenu = (e) => {
        updateprofileMenu(e);
    }

    const getCandidateNote = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/get-candidate-notes/${candidate.id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }).then((response) => {
            setNoteList(response?.data?.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const clearInputFields = () => {
        setNoteDetails({
            notes: '',
            note_type: '',
            rating: 0,
        });
        setStarsKey(Date.now());
        if (generalMessageRef.current) generalMessageRef.current.checked = false;
        if (feedbackRef.current) feedbackRef.current.checked = false;
        if (callbackRef.current) callbackRef.current.checked = false;
    };

    useEffect(() => {
        getCandidateNote()
        clearInputFields();
    }, [candidate])


    const ratingChanged = (newRating) => {
        setNoteDetails({ ...noteDetails, rating: newRating })
    };

    const addCandidateNote = (event) => {
        event.preventDefault()
        if (noteDetails.notes === '' || noteDetails.note_type === '' || noteDetails.rating === '') {
            return toast.error('Provide all details to add a note')
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/candidate-note/${candidate.id}`, noteDetails, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }).then((response) => {
            if (response.data.status) {
                toast.success(response.data.message)
                getCandidateNote()
                clearInputFields()

            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const deleteNote = (notes_id) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/delete-candidates-notes`, {
            candidate_id: candidate.id,
            note_id: notes_id
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }).then((response) => {
            if (response.data.status) {
                toast.success(response.data.message);
                getCandidateNote();
            }
        }).catch((error) => {
            console.log(error);
            toast.error('Error deleting the note');
        });
    };

    const updateCandidateNote = () => {
        if (editNoteDetails.notes === '' || editNoteDetails.note_type === '' || editNoteDetails.rating === '') {
            return toast.error('Provide all details to add a note')
        }

        editNoteDetails.note_type = editNoteDetails.note_type || editNoteDetails.type

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/update-candidate-notes/${editNoteDetails._id}`, { ...editNoteDetails, candidate_id: candidate.id }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }).then((response) => {
            if (response.data.status) {
                toast.success(response.data.message)
                setEditNoteDetails(null)
                getCandidateNote()
                clearInputFields()

            }
        }).catch((error) => {
            console.log(error)
        })
    }


    const handleDeleteNote = async (notes_id) => {
        const result = await Swal.fire({
            title: 'Do you want to delete the note?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3ab2e4',
            cancelButtonColor: 'grey',
            cancelButtonText: "No",
            confirmButtonText: 'Yes',

            customClass: {
                confirmButton: 'Swal_confirm',
                cancelButton: 'Swal_cancel',
                icon: 'Swal_icon',
                title: 'Swal_title'
            },
        });

        if (result.isConfirmed) {
            deleteNote(notes_id)
        }
    }


    return (
        <>
            <div className="profile_items_box_content px-3">

                <div className="Profile_Details_nav w-100">
                    <ul className='list-unstyled d-flex flex-wrap align-items-center mb-0'>
                        <li>
                            <button className={currentProfileMenu === 'All' ? 'active' : ''} onClick={() => profileMenu('All')} >All</button>
                        </li>
                        <li>
                            <button className={currentProfileMenu === 'Message' ? 'active' : ''} onClick={() => profileMenu('Message')}>General Message</button>
                        </li>
                        <li>
                            <button className={currentProfileMenu === 'Callback' ? 'active' : ''} onClick={() => profileMenu('Callback')}>Callback</button>
                        </li>
                        <li>
                            <button className={currentProfileMenu === 'Feedback' ? 'active' : ''} onClick={() => profileMenu('Feedback')}>Feedback</button>
                        </li>
                        <li>
                            <button className={currentProfileMenu === 'Schedule' ? 'active' : ''} onClick={() => profileMenu('Schedule')}>Interview Schedule</button>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="Profile_Details_content bg-white pt-0 border-top pb-4 px-2">
                {currentProfileMenu === 'All' || currentProfileMenu === 'Message' || currentProfileMenu === 'Callback' || currentProfileMenu === 'Feedback' ? (
                    <div className="comment-box">
                        <Form onSubmit={addCandidateNote}>
                            <div className="mb-2">
                                <p className="mb-2 txt__sm fw-bold txt__para">
                                    Add a new note
                                </p>
                                <div onChange={(event) => setNoteDetails({ ...noteDetails, note_type: 'General Message' })} className="form-check form-check-inline"><input ref={generalMessageRef} type="radio" id="general_message" name="note_type" className="form-check-input" />
                                    <label for="general_message" className="form-check-label">General Message</label></div>
                                <div onChange={(event) => setNoteDetails({ ...noteDetails, note_type: 'Feedback' })} className="form-check form-check-inline"><input type="radio" ref={feedbackRef} id="feedback" name="note_type" className="form-check-input" />
                                    <label for="feedback" className="form-check-label">Feedback</label></div>
                                <div onChange={(event) => setNoteDetails({ ...noteDetails, note_type: 'Callback' })} className="form-check form-check-inline"><input type="radio" ref={callbackRef} id="callback" name="note_type" className="form-check-input" />
                                    <label for="callback" className="form-check-label">Callback</label></div>
                            </div>
                            <div className="comment-box-typing mt-3">
                                <div className="form-floating">
                                    <textarea className="form-control h-100" placeholder="Leave a comment here" id="floatingTextarea2" onChange={(event) => setNoteDetails({ ...noteDetails, notes: event.target.value })} value={noteDetails.notes.trimStart()}></textarea>
                                    <label for="floatingTextarea2">Message...</label>
                                </div>
                                <div className="comment-box-action-area  d-flex align-items-center justify-content-between py-2" style={{ backgroundColor: '#f0f0f0' }}>
                                    <ReactStars key={starsKey} count={5} onChange={ratingChanged} size={24} activeColor="#ffd700" classNames={'comment-box-stars-btn comment-box-btn border-0'} />

                                    <p className='m-0'></p>

                                    <button name="submit" type="submit" className=" border-0 comment-box-submit-btn comment-box-btn">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.832 7.06587C15.6676 6.7264 15.3935 6.45232 15.0541 6.28794L2.407 0.168092C1.57387 -0.235541 0.571319 0.112666 0.167686 0.945796C-0.0379446 1.37028 -0.0552305 1.86179 0.120117 2.29966L2.31929 7.79653L0.120117 13.2939C-0.222426 14.1563 0.198984 15.1331 1.06135 15.4756C1.25634 15.5531 1.46413 15.5933 1.67395 15.5943C1.92781 15.5943 2.17839 15.537 2.407 15.4266L15.054 9.30512C15.8872 8.90158 16.2355 7.89903 15.832 7.06587ZM1.92034 14.4192C1.64259 14.5537 1.30843 14.4375 1.17397 14.1598C1.10545 14.0183 1.09975 13.8545 1.15822 13.7085L3.29929 8.35524H14.4523L1.92034 14.4192ZM3.29932 7.23778L1.15826 1.88451C1.06914 1.67112 1.12447 1.42469 1.29625 1.26991C1.46253 1.10788 1.7136 1.06987 1.92034 1.17549L14.4523 7.23778H3.29932Z" fill="#708097"></path>
                                        </svg>
                                    </button>

                                </div>
                            </div>

                        </Form>

                    </div>
                ) : null}
                <div className='d-flex flex-wrap mt-3'>
                    {
                        noteList?.map((notes, index) => editNoteDetails?._id === notes._id ? (
                            <div className="card mb-3 col-12 me-3" key={notes?._id}>
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <p className='d-flex align-items-center justify-content-center gap-2'>Type:
                                        <div onChange={(event) => setEditNoteDetails({ ...editNoteDetails, note_type: 'General Message' })} className="form-check form-check-inline">
                                            <input type="radio" id="edit_general_message" defaultChecked={editNoteDetails?.type === 'General Message' ? true : false} name="note_type" className="form-check-input" />
                                            <label for="edit_general_message" className="form-check-label">General Message</label>
                                        </div>
                                        <div onChange={(event) => setEditNoteDetails({ ...editNoteDetails, note_type: 'Feedback' })} className="form-check form-check-inline">
                                            <input type="radio" id="edit_feedback" defaultChecked={editNoteDetails?.type === 'Feedback' ? true : false} name="note_type" className="form-check-input" />
                                            <label for="edit_feedback" className="form-check-label">Feedback</label>
                                        </div>

                                        <div onChange={(event) => setEditNoteDetails({ ...editNoteDetails, note_type: 'Callback' })} className="form-check form-check-inline">
                                            <input type="radio" id="edit_callback" defaultChecked={editNoteDetails?.type === 'Callback' ? true : false} name="note_type" className="form-check-input" />
                                            <label for="edit_callback" className="form-check-label">Callback</label>
                                        </div>

                                    </p>

                                    <p className='d-flex align-items-center justify-content-center gap-2'>Rating: <ReactStars count={5} value={notes?.rating} onChange={(newRating) => setEditNoteDetails({ ...editNoteDetails, rating: newRating })} size={24} activeColor="#ffd700" classNames={'border-0'} /></p>

                                    <div className="d-flex justify-content-center">
                                        <button onClick={() => updateCandidateNote()} className="custom_table_btn edit_btn" title="Edit">
                                            <img src="/logos/save.png" alt="logo" />
                                        </button>
                                        <button className="custom_table_btn del_btn" title="Delete" name={notes._id} value={"status"} onClick={() => setEditNoteDetails(null)}>
                                            <img src="/logos/cancel_logo.png" alt="logo" name={candidate._id} value={"status"} />
                                        </button>
                                    </div>

                                </div>
                                <div className="card-body">
                                    <p className="card-text">
                                        <textarea className="form-control h-100" placeholder="Leave a comment here" id="floatingTextarea2" onChange={(event) => setEditNoteDetails({ ...editNoteDetails, notes: event.target.value })} value={editNoteDetails.notes}></textarea>
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className="card mb-3 col-12 me-3" key={notes?._id}>
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <p style={{ minWidth: 200 }}>Type:<span className="fw-bold"> {notes?.type}</span></p>
                                    <p>Rating:<span className="fw-bold"> {notes?.rating}</span></p>
                                    <div className="d-flex justify-content-center ">
                                        <button onClick={() => setEditNoteDetails(notes)} className="custom_table_btn edit_btn" title="Edit">
                                            <img src="/logos/edit_logo.svg" alt="logo" />
                                        </button>
                                        <button className="custom_table_btn del_btn" title="Delete" name={notes._id} value={"status"} onClick={() => handleDeleteNote(notes._id)}>
                                            <img src="/logos/delete_logo.svg" alt="logo" name={candidate._id} value={"status"} />
                                        </button>
                                    </div>
                                    {/* <ReactStars count={notes?.rating} size={24} activeColor="#ffd700" classNames={'border-0'} /> */}
                                </div>
                                <div className="card-body">
                                    <p className="card-text">{notes?.notes}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>

                {currentProfileMenu === 'Schedule' ? (
                    <p className='text-center fw-bold mb-0 pt-4 '></p>
                ) : null}

            </div>
        </>
    )
}
export default Note;