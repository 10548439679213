import axios from "axios";
import {
  USER_FAILURE,
  USER_REQUEST,
  USER_SUCCESS,
  USER_ADD_SUCCESS,
  USER_ADD_FAILURE,
  USER_ADD_REQUEST,
  ACCESS_REQUEST,
  ACCESS_SUCCESS,
  ACCESS_FAILURE,
  SPEC_USER_REQUEST,
  SPEC_USER_SUCCESS,
  SPEC_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from "./actionType";
import { toast } from "react-toastify";
import { errorCall } from "../ErrorHandling/action";

export const user_req = () => ({
  type: USER_REQUEST,
});

export const user_succ = (data) => ({
  type: USER_SUCCESS,
  payload: data,
});
export const user_failure = (err) => ({
  type: USER_FAILURE,
  payload: err,
});

export const userCall = (navigate, token, search,status,type,page,perpage) => (dispatch) => {
  dispatch(user_req());
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/auth/user/search`,
    {
      search: search,
      status,
      type,
      page,
      perpage
    },
    {
      headers: {
        Authorization: "Bearer" + " " + token,
      },
    }
  )
    .then((response) => {
      dispatch(user_succ(response?.data));
      return response;
    })
    .catch((error) => {
      if (error?.message === "Network Error" || error?.response?.status === 500) {
        dispatch(errorCall(error))
        return;
      }
      if (error?.response?.data?.error == "Unauthenticated.") {
        localStorage.removeItem("token");
        navigate("/login");
      }
      dispatch(user_failure(error?.response?.data));
    });
};

export const user_add = (data) => ({
  type: USER_ADD_REQUEST,
});
export const user_Succ = (data) => ({
  type: USER_ADD_SUCCESS,
  payload: data
});
export const user_fail = (error) => ({
  type: USER_ADD_FAILURE,
  payload: error
});
export const userAdd =
  (
    navigate,
    token,
    access,
    email,
    name,
    password,
    password_confirmation,
    status,
    type
  ) =>
    (dispatch) => {
      dispatch(user_add());
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/auth/users`,
          {
            access,
            email,
            name,
            password,
            password_confirmation,
            status,
            type,
          },
          {
            headers: {
              Authorization: "Bearer" + " " + token,
            },
          }
        )
        .then((response) => {
          // if (response?.data?.data) {
          dispatch(user_Succ("User Added"));
          toast.success("User Added");
          navigate('/user/user-list')
          // }
          return response;
        })
        .catch((error) => {
          if (error?.message === "Network Error" || error?.response?.status === 500) {
            dispatch(errorCall(error))
            return;
          }
          if (error?.response?.data?.error == "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
          }
          dispatch(user_fail(error?.response?.data));
          if (error?.response?.data?.errors.email) {
            error?.response?.data?.errors.email.map((el) => { toast.warn(el) })
            // toast.warn(error?.response?.data?.errors);
          } else if (error?.response?.data?.errors?.password) {
            error?.response?.data?.errors.password.map((el) => { toast.warn(el) })
          } else if (error?.response?.data?.message) {
            return toast.warn(error?.response?.data?.message)
          }

        });
    };

export const access_req = () => ({
  type: ACCESS_REQUEST,
});

export const access_succ = (data) => ({
  type: ACCESS_SUCCESS,
  payload: data,
});
export const access_failure = (err) => ({
  type: ACCESS_FAILURE,
  payload: err,
});

export const accessList = (navigate, token) => (dispatch) => {
  dispatch(access_req());
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/auth/accessLevel`, {
      headers: {
        Authorization: "Bearer" + " " + token,
      },
    })
    .then((response) => {
      dispatch(access_succ(response?.data?.data));
      return;
    })
    .catch((error) => {
      if (error?.message === "Network Error" || error?.response?.status === 500) {
        dispatch(errorCall(error))
        return;
      }
      if (error?.response?.data?.error == "Unauthenticated.") {
        localStorage.removeItem("token");
        navigate("/login");
      }
      dispatch(user_failure(error?.response?.data));
    });
};


export const speUser_req = () => ({
  type: SPEC_USER_REQUEST,
});

export const speUser_succ = (data) => ({
  type: SPEC_USER_SUCCESS,
  payload: data,
});
export const speUser_failure = (err) => ({
  type: SPEC_USER_FAILURE,
  payload: err,
});

export const specUserData = (navigate, token, id) => (dispatch) => {
  dispatch(speUser_req());
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/auth/users/${id}`, {
      headers: {
        Authorization: "Bearer" + " " + token,
      },
    })
    .then((response) => {
      dispatch(speUser_succ(response?.data?.data));
      return;
    })
    .catch((error) => {
      if (error?.message === "Network Error" || error?.response?.status === 500 || error?.response?.status === 404) {
        dispatch(errorCall(error))
        return;
      }
      if (error?.response?.data?.error == "Unauthenticated.") {
        localStorage.removeItem("token");
        navigate("/login");
      }
      dispatch(user_failure(error?.response?.data));
    });
};
export const user_update_req = () => ({
  type: UPDATE_USER_REQUEST,
});

export const user_update_succ = (data) => ({
  type: UPDATE_USER_SUCCESS,
  payload: data,
});
export const user_update_failure = (err) => ({
  type: UPDATE_USER_FAILURE,
  payload: err,
});

export const userUpdate = (
  navigate,
  token,
  access,
  email,
  name,
  password,
  password_confirmation,
  status,
  type,
  id) => (dispatch) => {
    dispatch(user_update_req());
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/auth/updateUser`,
        {
          access,
          email,
          name,
          password,
          password_confirmation,
          status,
          type,
          id
        }, {
        headers: {
          Authorization: "Bearer" + " " + token,
        },
      })
      .then((response) => {
        dispatch(user_update_succ("User Updated Successfully"));
        toast.success("User Updated Successfully")
        return;
      })
      .catch((error) => {
        dispatch(user_update_failure(error?.response?.data));
        if (error?.message === "Network Error" || error?.response?.status === 500) {
          dispatch(errorCall(error))
          return;
        }
        if (error?.response?.data?.error == "Unauthenticated.") {
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          toast.error("User Update Fail")
        }
      });
  };