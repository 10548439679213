import React, { useEffect, useRef, useState } from "react";
import "../../../Pages/candidates/CreateCandidate.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  nextGeneralDetails,
  nextUpdateDetails,
  updateContactTime,
  updateCurrentLocation,
  updateGender,
  updateJobPreference,
  updatePrimaryEmail,
  updatePrimaryPhone,
  updateSecondaryEmail,
  updateSecondaryPhone,
  updateSocials,
  validData,
} from "../../../Redux/Candidate/createCandidate/action";
import Spiner1 from "../../Spiner1";
const socialLink=["Others","Facebook.com","Twitter.com","Instagram.com","LinkedIn.com","Snapchat.com","Pinterest.com","Reddit.com",
"TikTok.com","YouTube.com","WhatsApp.com","Telegram.com","Tumblr.com","Discord.com","WeChat.com","Signal.com","VK.com","Flickr.com","Medium.com","Quora.com","Twitch.com"]
const GeneralData = (props) => {
  const dispatch = useDispatch();
  const siteRef= [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null),
    useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  const candidateData = useSelector((state) => state?.createCandidate);
  const candidateResponse = useSelector((state)=> state.addResponseReducer.data);
  const addCandidate = useSelector((state) => state?.addResponseReducer);
  const upgaradeCandidate = useSelector((state) => state.updateCandidateResponse);
  const jobPreference = useSelector((state) => state?.Job_Reducer?.typeData);
  const[TimeArray,setTimeArray]=useState(["Anytime","12pm - 6pm","6pm-8pm","After 8pm"])
  const [errorsPrimeMail,errorsPrimaryMailError]=useState("")
  const [errorsSecondaryMail,setSecondaryMailError]=useState("")
  const [errorsPrimePhoneNum,setPrimePhoneError]=useState("")
  const [errorsSecondPhoneNum,setSecondPhoneError]=useState("")
  const {inputRef,errorGenerate,errorMessage} = props;
  const navigate = useNavigate();
 
  const handleGender = (e) => {
    delete errorMessage?.gender
    dispatch(updateGender(e.target.value));
    dispatch(validData())
  };

  const handlePrimaryEmail = (e) => {
    dispatch(updatePrimaryEmail(e.target.value.toLowerCase()));
    delete errorMessage?.email_primary;
    const emailRegex = /^[a-zA-Z0-9]+([._][a-zA-Z0-9]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
    
    if (!emailRegex?.test(e.target.value)) {
      errorsPrimaryMailError("email_primary");
      errorGenerate("email_primary", "Please Provide A Valid Email id");
      return;
    } 
    if(e.target.value.includes("..")){
      errorsPrimaryMailError("email_primary");
      errorGenerate("email_primary", "Please Provide A Valid Email id");
    }
  
    const [localPart, domain] = e.target.value.split('@');
    if (domain) {
      if (domain.includes('.')) {
        const parts = domain.split('.');
        if (parts.length > 4) {
          errorGenerate("email_primary", "Please Provide A Valid Domain id");
          return;
        }
        for (let i = 1; i < parts.length - 1; i++) {
          for (let j = i + 1; j < parts.length; j++) {
            if (parts[i] === parts[j]) {
              errorGenerate("email_primary", "Please Provide A Valid Domain id");
              return;
            }
          }
        }
      }
    }
    
    errorsPrimaryMailError("");
  };
  
  
  const handleSecondaryEmail = (e) => {
    setSecondaryMailError("")
    dispatch(validData())
    dispatch(updateSecondaryEmail(e.target.value.toLowerCase()));
   delete errorMessage?.email_secondary
    const emailRegex = /^(?!.*(?:\.com.*\.com|\.in.*\.in|\.co.*\.co))[a-zA-Z0-9+?._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if( !emailRegex?.test(e.target.value) && e.target.value!=""){
      setSecondaryMailError("email_secondary")
      errorGenerate("email_secondary","Please Provide A Valid Email id")
    }
    if(e.target.value.includes("..")){
      setSecondaryMailError("email_secondary")
      errorGenerate("email_secondary","Please Provide A Valid Email id")
    }
    const [localPart, domain] = e.target.value.split('@');
    if (domain) {
      if (domain.includes('.')) {
        const parts = domain.split('.');
        if (parts.length > 4) {
          errorGenerate("email_secondary", "Please Provide A Valid Domain id");
          return;
        }
        for (let i = 1; i < parts.length - 1; i++) {
          for (let j = i + 1; j < parts.length; j++) {
            if (parts[i] === parts[j]) {
              errorGenerate("email_secondary", "Please Provide A Valid Domain id");
              return;
            }
          }
        }
      }
    }
  };


  const handlePrimaryNumber = (value,country) => {
    delete errorMessage?.phone_primary
    dispatch(validData())
      dispatch(updatePrimaryPhone(value));
      if (value.replace(country?.dialCode,"").length<10  && country?.dialCode?.length==2 && country?.dialCode!=65 ||  !value.replace(country.dialCode,"")) {
        setSecondPhoneError("phone_primary")
        errorGenerate("phone_primary","Please Provide Valid Phone Number")
      }else if( value.replace(country?.dialCode,"").length<8  && country?.dialCode?.length==3 && country?.dialCode!=65){
        setSecondPhoneError("phone_primary")
        errorMessage("phone_primary","Please Provide Valid Phone Number")
      } else if (value.match(/1234/)) {
        setSecondPhoneError("")
        dispatch(validData())
      } else {
        setSecondPhoneError("")
        dispatch(validData())
      }
      
    };

  const handleSecondaryNumber = (value,country) => {
    delete errorMessage?.phone_secondary
      dispatch(validData())
      dispatch(updateSecondaryPhone(value));
     if(value){ 
      if (value.replace(country?.dialCode,"").length<10  && country?.dialCode?.length==2 && country?.dialCode!=65 ||  !value.replace(country.dialCode,"")) {
        setSecondPhoneError("phone_secondary")
        errorGenerate("phone_secondary","Please Provide Valid Phone Number")
      }else if( value.replace(country?.dialCode,"").length<8  && country?.dialCode?.length==3 && country?.dialCode!=65){
        setSecondPhoneError("phone_secondary")
        errorGenerate("phone_secondary","Please Provide Valid Phone Number")
      } else if (value.match(/1234/)) {
        setSecondPhoneError("")
        dispatch(validData())
      } else {
        setSecondPhoneError("")
        dispatch(validData())
      }}
      else{ 
        setSecondPhoneError("")
      }
  };

  const handleAllowTime = (e) => {
    delete errorMessage?.contact_time
    dispatch(validData());
    const { checked, value } = e.target;
    var allowedTime = candidateData?.contact?.contact_time;
    if (checked) {
      if(value=="Anytime"){
        allowedTime=["Anytime"]
        dispatch(updateContactTime(allowedTime));
      }else{
        const newAllowTime=allowedTime.filter((item)=>{return item!="Anytime"})
        newAllowTime.push(value);
        dispatch(updateContactTime(newAllowTime));
      }
    } else {
      if (allowedTime?.length > 0) {
        allowedTime = allowedTime.filter((element) => element != value?value:"");
        dispatch(updateContactTime(allowedTime));
      }
    }
    //Time array maunupulation as require in validation logic
    const initial = ["Anytime", "12pm - 6pm", "6pm-8pm", "After 8pm"];
    const others = ["12pm - 6pm", "6pm-8pm", "After 8pm"];
    const timeMap = {
      "Anytime": checked ? ["Anytime"] : initial,
      "12pm - 6pm": !checked && allowedTime.length == 0 ? initial : others,
      "6pm-8pm": !checked && allowedTime.length == 0 ? initial : others,
      "After 8pm": !checked && allowedTime.length == 0 ? initial : others,
    };
    setTimeArray(timeMap[value] || []);
  };

  const handlePreferance = (e) => {
    delete errorMessage?.job_preference
    dispatch(validData())
    const jobPreference = [];
    jobPreference[0] = (e);
    dispatch(updateJobPreference(jobPreference))
  }


  const handleLocation = (e) => {
    delete errorMessage?.city
    dispatch(validData())
    dispatch(updateCurrentLocation(e.target.value));
  }
  
  const handleAddSocials = () => {
    delete errorMessage?.socials
    dispatch(validData())
    const prevSocials = candidateData?.socials;
    const index=prevSocials?.length-1
    if(prevSocials?.length<10 && candidateData?.socials[index]?.link!="" )prevSocials.push({name: "", link: ""});
    dispatch(updateSocials(prevSocials));
  }


  const removeSocials = (e,indexp) => {
    delete errorMessage?.socials
    dispatch(validData())
    let prevSocials = [...candidateData?.socials];
      prevSocials.splice(indexp , 1 );
      dispatch(updateSocials(prevSocials));
  }
  const resetSocial = (e,indexp) => {
    let prevSocials = [...candidateData?.socials];
      prevSocials[indexp].name=""
      prevSocials[indexp].link=""
      prevSocials[indexp].message=""
      dispatch(updateSocials(prevSocials));
  }


  const handleSite = (e,index) => {
    dispatch(validData())
    const prevSocials = candidateData?.socials;
    let currentIndex = prevSocials[index];
    currentIndex.link = e.target.value.trim(" ");
    const validLink=/^[a-zA-Z0-9\-]+$/
    if(validLink.test(e.target.value) ||  e.target.value.charAt( e.target.value.length - 1) === '.'  || e.target.value.includes(',') ||  e.target.value.includes(' ') ||  !e.target.value.includes('.') ){
      if(index!="")siteRef[index].current.focus()
      currentIndex.message =`Invalid Link In:${index + 1}`;
    }else{
      // delete operator use to remove the value along with key ~~sougata
      delete currentIndex.message
    }
    dispatch(updateSocials(prevSocials));
  }


  const handleSiteName = (e,index) => {
    dispatch(validData())
    const prevSocials = candidateData?.socials;
    let currentIndex = prevSocials[index];
    currentIndex.name = e.target.value;
    dispatch(updateSocials(prevSocials));
  }

  const submitValidation=(message)=>{
    const validLink = /^[a-zA-Z0-9\-]+$/;
    for (let i = 0; i < message.length; i++) {
      const link = message[i].link.trim();
      const name = message[i].name;
      const isInvalidLink = validLink.test(link) || link.charAt(link.length - 1) === '.' || link.includes(',') || link.includes(' ');

    if (isInvalidLink ) {
      message[i].message = "Invalid Link";
      return i; 
    }
    if(!name && message.length > 1){
      message[i].message = "Please Provide Site name";
      return i;
    }
    if(name &&!link){
      message[i].message = "Please Provide Link";
      return i;
    }
    }
  
    return -1;
  }
 
  const handleGeneralDetailsNext =() => {
    if(!candidateData.resume){
      errorGenerate("resume","Please Provide The Resume")
    }
     if(!candidateData.first_name){
      errorGenerate("first_name","Please Provide The First name")
    }
     if(!candidateData.last_name){
      errorGenerate("last_name","Please Provide The Last name")
      
    }

     if(!candidateData.lead_source){
      errorGenerate("lead_source","Please Provide The source")
      // dispatch(validData("lead_source","Please Provide The source"))
      // inputRef[3].current.focus()
    }
     if(!candidateData.designation_experience){
      errorGenerate("designation_experience","Please Provide The Experience")
      // dispatch(validData("designation_experience","Please Provide The Experience"))
      //  inputRef[4].current.focus()
    }
     if(!candidateData.designation){
      errorGenerate("designation","Please Provide The Designation")
      //  inputRef[5].current.focus()
      // dispatch(validData("designation","Please Provide The Designation"))
    }
     if(!candidateData.gender){
      errorGenerate("gender","Please Provide The Gender")
      // dispatch(validData("gender","Please Provide The Gender"))
      //  inputRef[6].current.focus()
    }
     if(!candidateData.contact.email_primary  ){
      errorGenerate("email_primary","Please Provide The Primary Email")
      // dispatch(validData("email_primary","Please Provide The Primary Email"))
      // inputRef[7].current.focus()
     
   } if(errorsPrimeMail=="email_primary"){
    errorGenerate("email_primary","Please Provide A Valid Email Id")
    // dispatch(validData("email_primary","Please Provide A Valid Email Id"))
    // inputRef[7].current.focus()
   
   }
    if( errorsSecondaryMail==="email_secondary"){
    errorGenerate("email_secondary","Please Provide A Valid Email Id")
      // dispatch(validData("email_secondary","Please Provide A Valid Email Id"))
      // inputRef[8].current.focus()
    }
     if(errorsSecondaryMail &&errorsPrimeMail==errorsSecondaryMail){
      errorGenerate("email_secondary","Please Provide A Different Email")
      // dispatch(validData("email_secondary","Please Provide A Different Email"))
      // inputRef[8].current.focus()
     
     }
     if(!candidateData.contact.phone_primary){
      errorGenerate("phone_primary","Please Provide The Primary Phone Number")
      // dispatch(validData("phone_primary","Please Provide The Primary Phone Number"))
      //  inputRef[9].current.focus()
      
    }
     if(errorsPrimePhoneNum){
      errorGenerate("phone_primary","Please Provide A Valid Primary Phone Number")
      // dispatch(validData("phone_primary","Please Provide A Valid Primary Phone Number"))
      //  inputRef[9].current.focus()
      
    }
     if(errorsSecondPhoneNum){
      errorGenerate("phone_secondary","Please Provide A Valid Secondary Phone Number")
      // dispatch(validData("phone_secondary","Please Provide A Valid Secondary Phone Number"))
      //  inputRef[10].current.focus()
      
    }
     if(candidateData.contact.contact_time.length==0){
      errorGenerate("contact_time","Please Provide The Contact Time")
      // dispatch(validData("contact_time","Please Provide The Contact Time"))
      //  inputRef[11].current.focus()
      
    } 
     if(!candidateData.contact.city){
      errorGenerate("city","Please Provide The Location")
      // dispatch(validData("city","Please Provide The Location"))
      //  inputRef[12]?.current?.focus()

    }
    if(candidateData?.job_preference?.length==0 ){
      errorGenerate("job_preference","Please Provide The Prefered Job Type")
      // dispatch(validData("job_preference","Please Provide The Prefered Job Type"))
      // inputRef[14].current.focus()
    }
     if(submitValidation(candidateData?.socials)>=0){
      let prevSocials = [...candidateData?.socials];
      prevSocials[submitValidation(candidateData?.socials)].message="Invalid Data"
      siteRef[submitValidation(candidateData?.socials)]?.current?.focus()
      dispatch(updateSocials(prevSocials));
    }
    if( candidateData?.contact?.email_secondary && candidateData?.contact?.email_primary==candidateData?.contact?.email_secondary){
      errorGenerate("email_secondary","Primary And Secondary Email Can't Be Same")
    }


    if(!candidateData.resume){
      inputRef[0].current.focus()
    }
     else if(!candidateData.first_name){
      inputRef[1].current.focus()
    }
     else if(!candidateData.last_name){
      inputRef[2].current.focus()
    }
     else if(!candidateData.lead_source){
      inputRef[3].current.focus()
    }
     else if(!candidateData.designation_experience){
       inputRef[4].current.focus()
    }
     else if(!candidateData.designation){
       inputRef[5].current.focus()
    }
     else if(!candidateData.gender){
    }
     else if(!candidateData.contact.email_primary  ){
      inputRef[7].current.focus()
     
   } else if(errorsPrimeMail=="email_primary"){
    inputRef[7].current.focus()
   
   }
    else if( errorsSecondaryMail==="email_secondary"){
      inputRef[8].current.focus()
    }
     else if(errorsSecondaryMail &&errorsPrimeMail==errorsSecondaryMail){
      inputRef[8].current.focus()
     
     }
     else if(!candidateData.contact.phone_primary){
       inputRef[9].current.focus()
      
    }
     else if(errorsPrimePhoneNum){
       inputRef[9].current.focus()
      
    }
     else if(errorsSecondPhoneNum){
       inputRef[10].current.focus()
      
    }
     else if(candidateData.contact.contact_time.length==0){
       inputRef[11].current.focus()
      
    } 
     else if(!candidateData.contact.city){
       inputRef[12]?.current?.focus()
    }
    else if(candidateData?.job_preference?.length==0 ){
      inputRef[14].current.focus()
    }else if(candidateData?.contact?.email_primary==candidateData?.contact?.email_secondary){
      inputRef[8].current.focus()
    }
    else{
      if (candidateResponse?.candidate) {
        const nextTab = "Education";
        dispatch(
          nextUpdateDetails(
            navigate,
            nextTab,
            candidateResponse?.candidate,
            candidateData
          )
        );
      } else {
        dispatch(nextGeneralDetails(navigate, candidateData));
      }}
    
}
  return (
    <div className="tab-data mt-4" step={0}>
      <div className="row">
        <div className="col-sm-6">
          <div className="grid-box">
            <div className="form-group-gap">
              <div className="form-label-box mb-2">
                <label className="txt__para txt__sm fw-bold">Gender</label>
                <span className="text-danger ms-1">*</span>
              </div>
              <div className="form-control-box">
                <div className="form-check form-check-inline">
                  <input
                    ref={inputRef[6]}
                    value="Male"
                    type="radio"
                    id="genderType1"
                    name="gender"
                    checked={candidateData?.gender == "Male"}
                    className="form-check-input"
                    onChange={(e) => handleGender(e)}
                  />
                  <label htmlFor="genderType1" className="form-check-label">
                    Male
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    value="Female"
                    type="radio"
                    id="genderType2"
                    name="gender"
                    checked={candidateData?.gender == "Female"}
                    className="form-check-input"
                    onChange={(e) => handleGender(e)}
                  />
                  <label htmlFor="genderType2" className="form-check-label">
                    Female
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    value="Unknown"
                    type="radio"
                    id="genderType3"
                    name="gender"
                    className="form-check-input"
                    checked={candidateData?.gender == "Unknown"}
                    onChange={(e) => handleGender(e)}
                  />
                  <label htmlFor="genderType3" className="form-check-label">
                    Prefer not to say
                  </label>
                </div>
              </div>
              <p className="text-danger m-2">
                {errorMessage?.gender}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6"></div>

        <div  className="col-sm-6">
          <div className="grid-box">
            <div className="form-group-gap">
              <div className="form-label-box mb-2">
                <label className="txt__para txt__sm fw-bold">
                  Primary Email
                </label>
                <span className="text-danger ms-1">*</span>
              </div>
              <div className="form-control-box">
                <input
                  ref={inputRef[7]}
                  name={"email_primary"}
                  type="email"
                  placeholder="Enter email here"
                  className="form-control-no-border"
                  value={candidateData?.contact?.email_primary?candidateData?.contact?.email_primary:""}
                  onChange={(e) => handlePrimaryEmail(e)}
                />
              </div>
              <p className="text-danger m-2">
              {errorMessage?.email_primary}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="grid-box">
            <div className="form-group-gap">
              <div className="form-label-box mb-2">
                <label className="txt__para txt__sm fw-bold">
                  Secondary Email
                </label>
              </div>
              <div className="form-control-box">
                <input
                  ref={inputRef[8]}
                  type="email"
                  name={"email_secondary"}
                  placeholder="Enter email here"
                  className="form-control-no-border"
                  value={candidateData?.contact?.email_secondary?candidateData?.contact?.email_secondary:""}
                  onChange={(e) => handleSecondaryEmail(e)}
                />
              </div>
              <p className="text-danger m-2">
              {errorMessage?.email_secondary}
              </p>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div className="grid-box">
            <div className="form-group-gap">
              <div className="form-label-box mb-2">
                <label className="txt__para txt__sm fw-bold">
                  What is the best phone number to reach you at?
                </label>
                <span className="text-danger ms-1">*</span>
              </div>
              <div  className="form-control-box">
                <PhoneInput
                  inputProps={{
                    ref: inputRef[9],
                  }}
                  enableSearch={true}
                  name={"phone_primary"}
                  required={true}
                  country={"in"}
                  value={candidateData?.contact?.phone_primary?candidateData?.contact?.phone_primary:""}
                  placeholder="+91 xxxxx-xxxxx"
                  onChange={(e,country) => handlePrimaryNumber(e,country)}
                />
              </div>
              <p className="text-danger m-2">
                {errorMessage?.phone_primary}
              </p>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div className="grid-box">
            <div className="form-group-gap">
              <div className="form-label-box mb-2">
                <label className="txt__para txt__sm fw-bold">
                  Add a secondary contact number
                </label>
              </div>
              <div className="form-control-box">
                <PhoneInput
                 placeholder="+91 xxxxx-xxxxx"
                 inputProps={{
                  ref: inputRef[10],
                }}
                  value={candidateData?.contact?.phone_secondary}
                  name={"phone_secondary"}
                  enableSearch={true}
                  required={true}
                  country={"in"}
                  onChange={(e,country) => handleSecondaryNumber(e,country)}
                />
              </div>
              <p className="text-danger m-2">
              {errorMessage?.phone_secondary}
              </p>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div className="grid-box">
            <div className="form-group-gap">
              <div className="form-label-box mb-2">
                <label className="txt__para txt__sm fw-bold">
                  What is the best time to connect with you?
                </label>
                <span className="text-danger ms-1">*</span>
              </div>
              <div className="form-control-box">
                
                {TimeArray?.map((item, index) =>
                    (
                      <div className="form-check form-check-inline" key={index}>
                        {inputRef[11] && index==0 ?<input
                          ref={inputRef[11]}
                          value={item}
                          type="checkbox"
                          id={`callTiming${index + 1}`}
                          name={item}
                          className="form-check-input"
                          checked={candidateData?.contact?.contact_time?.includes(
                            item
                          )}
                          onChange={(e) => handleAllowTime(e)}
                        />:<input
                        value={item}
                        type="checkbox"
                        id={`callTiming${index + 1}`}
                        name={item}
                        className="form-check-input"
                        checked={candidateData?.contact?.contact_time?.includes(
                          item
                        )}
                        onChange={(e) => handleAllowTime(e)}
                      />}
                        <label
                          htmlFor={`callTiming${index + 1}`}
                          className="form-check-label"
                        >
                          {item}
                        </label>
                      </div>
                    ) 
                  )}
              </div>
              <p className="text-danger m-2">
              {errorMessage?.contact_time}
              </p>
            </div>
          </div>
        </div>
        <div  className="col-sm-6">
          <div className="grid-box">
            <div className="form-group-gap">
              <div className="form-label-box mb-2">
                <label className="txt__para txt__sm fw-bold">
                  Job Preference
                </label>
                <span className="text-danger ms-1">*</span>
              </div>
              <div className="form-control-box d-flex flex-wrap">
                
                {jobPreference?.map((e, index) => (
                  <div key={index} className="col-auto job_option">
                    <div className="form-check">
                     {inputRef[14] && index==0 ? <input
                        ref={inputRef[14]}
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id={index}
                        checked={candidateData?.job_preference?.length>0 ? candidateData?.job_preference?.includes(e?.id):false}
                        value={e.name}
                        onChange={() => {
                          handlePreferance(e.id);
                        }}
                      />:
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id={index}
                        checked={candidateData?.job_preference[0]==e?.name ||candidateData?.job_preference[0]==e?.id ?true:false}
                        value={e.id}
                        onChange={() => {
                          handlePreferance(e.id);
                        }}
                      />}
                      <label className="form-check-label" htmlFor={index}>
                        {e.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              <p className="text-danger m-2">
              {errorMessage?.job_preference}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="grid-box">
            <div className="form-group-gap">
              <div className="form-label-box mb-2">
                <label className="txt__para txt__sm fw-bold">
                  Current Location
                </label>
                <span className="text-danger ms-1">*</span>
              </div>
              <div className="form-control-box">
                <input
                  ref={inputRef[12]}
                  type="text"
                  placeholder="Enter current location"
                  className="form-control-no-border"
                  value={candidateData?.contact?.city}
                  onChange={(e) => handleLocation(e)}
                />
              </div>
              <p className="text-danger m-2">
              {errorMessage?.city}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="grid-box">
            <div className="form-group-gap">
              <div className="form-label-box mb-2">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <label className="txt__para txt__sm fw-bold">
                    Social media links
                  </label>

                  <button
                    type="button"
                    className="button-outline"
                    onClick={() => handleAddSocials()}
                  >
                    Add
                  </button>
                </div>
              </div>
              {candidateData?.socials?.map((social, index) => (
                <div key={index} className="mb-3">
                  <div className="d-flex">
                    <div
                      className="form-control-box flex-shrink-0"
                      style={{ width: 150 }}
                    >
                      <select
                        placeholder="Site name"
                        className="form-control-no-border form-control-no-border-select"
                        value={social?.name ? social?.name : ""}
                        onChange={(e) => {
                          handleSiteName(e, index);
                        }}
                      >
                        <option value="" >
                          Site Name
                        </option>
                        {socialLink?.map((source,ind) =><option key={ind}>{source}</option>)}
                      </select>
                    </div>
                    
                    <div className="form-control-box flex-grow-1 position-relative d-flex align-items-center" style={{ padding:'16px 36px 16px 10px' }}>
                      <input
                        ref={siteRef[index]}
                        disabled={!social?.name}
                        type="text"
                        placeholder="Enter link here (https://www.sitename.com/)"
                        className="form-control-no-border"
                        value={social?.link ? social?.link.trim(" ") : ""}
                        onChange={(e) => {
                          handleSite(e, index);
                        }}
                      />
                     <button
                    type="button"
                    className="button_remove new_position_romove_btn"
                    onClick={(e) => index>0 ?removeSocials(e, index):resetSocial(e, index)}
                  >
                   <i className="fa-solid fa-xmark"></i>
                  </button>
                    </div>
                    
                  </div>
                  <p className="text-danger  mx-2 p-2">
                        {social.message}
                    </p>
                 
                  
                  {/* )} */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        disabled={addCandidate?.isLoading || upgaradeCandidate?.isLoading}
        onClick={(e) => {
          handleGeneralDetailsNext(e);
        }}
        className="nav-link Next_qu mt-3 w-100"
      >
        {!addCandidate?.isLoading && !upgaradeCandidate?.isLoading? "Next" : <Spiner1 />}
      </button>
    </div>
  );
};

export default GeneralData;
