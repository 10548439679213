import {
  DESIGNATION_REQUEST,
  DESIGNATION_SUCCESS,
  DESIGNATION_FAILURE,
  LOCATION_REQUEST,
  LOCATION_SUCCESS,
  LOCATION_FAILURE,
  EXPERIENCE_REQUEST,
  EXPERIENCE_SUCCESS,
  EXPERIENCE_FAILURE,
  SKILLS_REQUEST,
  SKILLS_SUCCESS,
  SKILLS_FAILURE,
  CITY_REQUEST,
  CITY_SUCCESS,
  CITY_FAILURE,
  CANDIDATE_REQUEST,
  CANDIDATE_SUCCESS,
  CANDIDATE_FAILURE,
  CANDIDATE_SUCCESS_ALLRESPONSE,
  ADD_CANDIDATE_REQUEST,
  ADD_CANDIDATE_SUCCESS,
  ADD_CANDIDATE_FAILURE,
  SAVED_DATA,
  // SINGLE_CANDIDATE_REQUEST,
  // SINGLE_CANDIDATE_SUCCESS,
  // SINGLE_CANDIDATE_FAILURE,
  STATUS_UPDATE_REQUEST,
  STATUS_UPDATE_SUCCESS,
  STATUS_UPDATE_FAILURE,
  EMAIL_REQUEST,
  EMAIL_SUCCESS,
  EMAIL_FAILURE,
  // start resume extract
  RESUMEEXTRACT_REQUEST,
  RESUMEEXTRACT_SUCCESS_ADD,
  RESUMEEXTRACT_SUCCESS_EDIT,
  RESUMEEXTRACT_FAILURE
  // end resume extract
} from "./actionType";

const initState = {
  // start resume extract resume
  isLoadingResumeExtract: false,
  isErrorResumeExtract: false,
  errorDataResumeExtract: [],
  resumeExtractData: [],
  resumeExtractDataEdit: [],
  // end resume extract resume

  isLoading: false,
  isLoadingExp: false,
  isLoadingSkill: false,
  isLoadingCity: false,
  isCandidateLoading: false,
  isLoadingAllCandidate: false,
  // isLoadingSingle: false,
  isLoadingStatus: false,
  isAddLoading: false,
  isEmailLoading: false,
  loderPercent: 10,

  isError: false,
  isErrorExp: false,
  isErrorSkill: false,
  isErrorCity: false,
  isErrorCandidate: false,
  isErrorAllCandidate: false,
  // isErrorSingle: false,
  isErrorStatus: false,
  isErrorAdd: false,
  isErrorEmail: false,

  errorData: "",
  errorDataExp: "",
  errorDataSkill: "",
  errorDataCity: "",
  errorDataCandidate: "",
  errorDataAllCandidate: "",
  // errorDataSingle: "",
  errorDataStatus: "",
  errorDataAdd: "",
  errorDataEmail: "",

  designationData: [],
  locationData: [],
  experienceData: [],
  skillsData: [],
  cityData: [],
  candidateData: [],
  candidateDataAllResponse: {},
  candidateAddData: {},
  saveDAta: {},

  // singleCandidate: {},
  jobStatus: {},
  emailData: {}
};

export const Candidate_Reducer = (state = initState, action) => {
  switch (action.type) {
    // ___________________ start resume extract ______________________

    case RESUMEEXTRACT_REQUEST: {
      return {
        ...state,
        isLoadingResumeExtract: true,
        isErrorResumeExtract: false,
        loderPercent: 50
      };
    }
    case RESUMEEXTRACT_SUCCESS_ADD: {
      return {
        ...state,
        isLoadingResumeExtract: false,
        resumeExtractData: action.payload,
        isErrorResumeExtract: false,
        loderPercent: 100
      };
    }
    case RESUMEEXTRACT_SUCCESS_EDIT: {
      return {
        ...state,
        isLoadingResumeExtract: false,
        resumeExtractDataEdit: action.payload,
        isErrorResumeExtract: false,
        loderPercent: 100
      };
    }
    case RESUMEEXTRACT_FAILURE: {
      return {
        ...state,
        isLoadingResumeExtract: false,
        isErrorResumeExtract: true,
        errorDataResumeExtract: action.payload,
      };
    }

    // _______________________ end resume extract _______________
    case DESIGNATION_REQUEST: {
      return {
        ...state,
        isLoading: true,
        // designationData: [],
        isError: false,
      };
    }
    case DESIGNATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        designationData: action.payload,
        isError: false,
      };
    }
    case DESIGNATION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorData: action.payload,
      };
    }

    case LOCATION_REQUEST: {
      return {
        ...state,
        isLoading: true,
        // locationData: [],
        isError: false,
      };
    }
    case LOCATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        locationData: action.payload,
        isError: false,
      };
    }
    case LOCATION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorData: action.payload,
      };
    }

    case EXPERIENCE_REQUEST: {
      return {
        ...state,
        isLoadingExp: true,
        experienceData: [],
        isErrorExp: false,
      };
    }
    case EXPERIENCE_SUCCESS: {
      return {
        ...state,
        isLoadingExp: false,
        experienceData: action.payload,
        isErrorExp: false,
      };
    }
    case EXPERIENCE_FAILURE: {
      return {
        ...state,
        isLoadingExp: false,
        experienceData: [],
        isErrorExp: true,
        errorDataExp: action.payload,
      };
    }


    case SKILLS_REQUEST: {
      return {
        ...state,
        isLoadingSkill: true,
        skillsData: [],
        isErrorSkill: false,
      };
    }
    case SKILLS_SUCCESS: {
      return {
        ...state,
        isLoadingSkill: false,
        skillsData: action.payload,
        isErrorSkill: false,
      };
    }
    case SKILLS_FAILURE: {
      return {
        ...state,
        isLoadingSkill: false,
        skillsData: [],
        isErrorSkill: true,
        errorDataSkill: action.payload,
      };
    }


    case CITY_REQUEST: {
      return {
        ...state,
        isLoadingCity: true,
        cityData: [],
        isErrorCity: false,
      };
    }
    case CITY_SUCCESS: {
      return {
        ...state,
        isLoadingCity: false,
        cityData: action.payload,
        isErrorCity: false,
      };
    }
    case CITY_FAILURE: {
      return {
        ...state,
        isLoadingCity: false,
        isErrorCity: true,
        errorDataCity: action.payload,
      };
    }


    case CANDIDATE_REQUEST: {
      return {
        ...state,
        isCandidateLoading: true,
        candidateData: [],
        isErrorCandidate: false,
      };
    }
    case CANDIDATE_SUCCESS: {
      return {
        ...state,
        isCandidateLoading: false,
        candidateData: action.payload,
        isErrorCandidate: false,
      };
    }
    case CANDIDATE_FAILURE: {
      return {
        ...state,
        isCandidateLoading: false,
        candidateData: [],
        isErrorCandidate: true,
        errorDataCandidate: action.payload,
      };
    }


    case CANDIDATE_SUCCESS_ALLRESPONSE: {
      return {
        ...state,
        isLoadingAllCandidate: false,
        candidateDataAllResponse: action.payload,
        isErrorAllCandidate: false,
      };
    }


    // case SINGLE_CANDIDATE_REQUEST: {
    //   return {
    //     ...state,
    //     isLoadingSingle: true,
    //     singleCandidate: {},
    //     isErrorSingle: false,
    //     errorDataSingle: null,
    //   };
    // }
    // case SINGLE_CANDIDATE_SUCCESS: {
    //   return {
    //     ...state,
    //     isLoadingSingle: false,
    //     singleCandidate: action.payload,
    //     isErrorSingle: false,
    //     errorDataSingle: null,
    //   };
    // }
    // case SINGLE_CANDIDATE_FAILURE: {
    //   return {
    //     ...state,
    //     isLoadingSingle: false,
    //     singleCandidate: null,
    //     isErrorSingle: true,
    //     errorDataSingle: action.payload,
    //   };
    // }




    case STATUS_UPDATE_REQUEST: {
      return {
        ...state,
        isLoadingStatus: true,
        jobStatus: {},
        isErrorStatus: false,
        errorDataStatus: null,
      };
    }
    case STATUS_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoadingStatus: false,
        jobStatus: action.payload,
        isErrorStatus: false,
        errorDataStatus: null,
      };
    }
    case STATUS_UPDATE_FAILURE: {
      return {
        ...state,
        isLoadingStatus: false,
        jobStatus: null,
        isErrorStatus: true,
        errorDataStatus: action.payload,
      };
    }




    case ADD_CANDIDATE_REQUEST: {
      return {
        ...state,
        isCandidateCreateLoading: true,
        candidateAddData: [],
        isErrorAdd: false,
        errorDataAdd: null
      };
    }
    case ADD_CANDIDATE_SUCCESS: {
      return {
        ...state,
        isCandidateCreateLoading: false,
        candidateAddData: action.payload,
        isErrorAdd: false,
        errorDataAdd: null
      };
    }
    case ADD_CANDIDATE_FAILURE: {
      return {
        ...state,
        isCandidateCreateLoading: false,
        candidateAddData: null,
        isErrorAdd: true,
        errorDataAdd: action.payload,
      };
    }


    case EMAIL_REQUEST: {
      return {
        ...state,
        isEmailLoading: true,
        emailData: {},
        isErrorEmail: false,
        errorDataEmail: null,
      };
    }
    case EMAIL_SUCCESS: {
      return {
        ...state,
        isEmailLoading: false,
        emailData: action.payload,
        isErrorEmail: false,
        errorDataEmail: null,
      };
    }
    case EMAIL_FAILURE: {
      return {
        ...state,
        isEmailLoading: false,
        emailData: null,
        isErrorEmail: true,
        errorDataEmail: action.payload,
      };
    }

    case SAVED_DATA: {
      return {
        ...state,
        saveDAta: ({ ...state.saveDAta, ...action.payload })
      };
    }





    // case RESET_DATA: {
    //   return {
    //     ...state,
    //     saveDAta: {
    //         certifications: [
    //           { course_name: "", institute_name: "", completion_year: "" },
    //         ],
    //         contact: {
    //           phone_primary: "+91",
    //           phone_secondary:"+91",
    //           email_secondary:"",
    //           email_primary: "",
    //           contact_time:  [],
    //           city:"",
    //         },
    //         designation_id: "",
    //         experience_level_id: "",
    //         first_name: "",
    //         gender: "",
    //         last_name: "",
    //         lead_source: "",
    //         profile_image: "",
    //         project_details: [{ name: "", link: "" }],
    //         qualifications: [
    //           {
    //             degree: "",
    //             institute: "",
    //             percentage: "",
    //             session_end: "",
    //             session_start: "",
    //             status: [],
    //           },
    //         ],
    //         resume: "",
    //         skills: [
    //           {
    //             currently_working: false,
    //             exp_month: "0",
    //             exp_year: "0",
    //             skill_id: null,
    //           },
    //         ],
    //         socials: [],
    //         system_hardware: [
    //           {
    //             computer: "",
    //             internet_download: "",
    //             internet_type: "",
    //             internet_upload: "",
    //             processor: "",
    //             ram: "",
    //             upgrade_internet: false,
    //             upgrade_ram: false,
    //           },
    //         ],
    //         total_experience: {
    //           month: "",
    //           year: "",
    //           total_in_months:""
    //         },
    //         work_details: {
    //           available: "",
    //           current_company: "",
    //           current_ctc: "",
    //           current_position: "",
    //           currently_working: "",
    //           expected_ctc: "",
    //           fresher_join: "",
    //           job_preference: ["Remote part time"],
    //           joining_date_month: "",
    //           joining_date_year: "",
    //           last_company: "",
    //           last_ctc: "",
    //           last_position: "",
    //           notice_period: "",
    //           notice_period_negotiable: "",
    //           termination_date_month: "",
    //           termination_date_year: "",
    //           why_switching: "",
    //           why_unemployed: "",
    //           work_note: "",
    //         },
    //       }
    //   };
    // }

    default: {
      return state;
    }
  }
};