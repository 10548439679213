import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { skills } from "../../Redux/CandidateReducer/action";
import { jobList, typeList, jobCount } from "../../Redux/JobReducer/action";
import { useNavigate } from "react-router-dom";
import { globalDebouncing } from "../../Debouncing/globalDebouncing";
import Paginate from "../../TestPortal/components/Pagination/Pagination";
import { toast } from "react-toastify";
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? "1px solid #dee2e6" : "1px solid #dee2e6",
    //   backgroundColor: "#fff",
    backgroundColor: state.isDisabled ? "#ccc" : "#fff",
    borderRadius: "0.375rem",
    minHeight: "45px",
    boxShadow: state.isFocused && "0 0 0 0.25rem rgba(13,110,253,.25)",
    color: state.isSelected ? "#86b7fe" : "#000",
    fontSize: "14px",
    "&:hover": {
      borderColor: state.isFocused ? "#dee2e6" : "#dee2e6",
      boxShadow: "0 0 0 0.25rem rgba(13,110,253,.25)",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#333",
  }),
  input: (provided) => ({
    ...provided,
    color: "#333",
    fontSize: "14px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused || state.isSelected ? "#86b7fe" : "#fff",
    color: state.isSelected ? "white" : "#333",
    fontSize: "14px",
    "&:hover": {
      color: state.isFocused ? "#fff" : "#fff",
      boxShadow: "0 0 0 0.25rem rgba(13,110,253,.25)",
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#eee",
    borderRadius: "0.375rem",
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: "#fff",
    color: "#eee",
    borderRadius: "0.375rem",
  }),
};

const minExp = [
  { value: 1, name: "1 Years", label: "1 Year" },
  { value: 2, name: "2 Years", label: "2 Year" },
  { value: 3, name: "3 Years", label: "3 Year" },
  { value: 4, name: "4 Years", label: "4 Year" },
  { value: 5, name: "5 Years", label: "5 Year" },
  { value: 6, name: "6 Years", label: "6 Year" },
  { value: 7, name: "7 Years", label: "7 Year" },
  { value: 8, name: "8 Years", label: "8 Year" },
  { value: 9, name: "9 Years", label: "9 Year" },
  { value: 10, name: "10 Years", label: "10 Year" },]


const maxExp = [
  { value: 1, name: "1 Years", label: "1 Year" },
  { value: 2, name: "2 Years", label: "2 Year" },
  { value: 3, name: "3 Years", label: "3 Year" },
  { value: 4, name: "4 Years", label: "4 Year" },
  { value: 5, name: "5 Years", label: "5 Year" },
  { value: 6, name: "6 Years", label: "6 Year" },
  { value: 7, name: "7 Years", label: "7 Year" },
  { value: 8, name: "8 Years", label: "8 Year" },
  { value: 9, name: "9 Years", label: "9 Year" },
  { value: 10, name: "10 Years", label: "10 Year" },

];

const salaryOptionsOne = [
  { name: "5000", value: 5000, label: "5000" },
  { name: "10000", value: 10000, label: "10000" },
  { name: "15000", value: 15000, label: "15000" },
  { name: "20000", value: 20000, label: "20000" },
  { name: "25000", value: 25000, label: "25000" },
  { name: "30000", value: 30000, label: "30000" },
  { name: "35000", value: 35000, label: "35000" },
  { name: "40000", value: 40000, label: "40000" },
  { name: "45000", value: 45000, label: "45000" },
  { name: "50000", value: 50000, label: "50000" },
  { name: "55000", value: 55000, label: "55000" },
  { name: "60000", value: 60000, label: "60000" },
  { name: "65000", value: 65000, label: "65000" },
  { name: "70000", value: 70000, label: "70000" },
];
const salaryOptionsTwo = [
  { name: "5000", value: 5000, label: "5000" },
  { name: "10000", value: 10000, label: "10000" },
  { name: "15000", value: 15000, label: "15000" },
  { name: "20000", value: 20000, label: "20000" },
  { name: "25000", value: 25000, label: "25000" },
  { name: "30000", value: 30000, label: "30000" },
  { name: "35000", value: 35000, label: "35000" },
  { name: "40000", value: 40000, label: "40000" },
  { name: "45000", value: 45000, label: "45000" },
  { name: "50000", value: 50000, label: "50000" },
  { name: "55000", value: 55000, label: "55000" },
  { name: "60000", value: 60000, label: "60000" },
  { name: "65000", value: 65000, label: "65000" },
  { name: "70000", value: 70000, label: "70000" },
];
const JobsList = (props) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [skill, setskill] = useState([]);
  const [experienceMax, setExperienceMax] = useState(null);
  const [experienceMin, setExperienceMin] = useState(null);
  const [salaryMax, setSalaryMax] = useState(null);
  const [salaryMin, setSalaryMin] = useState(null);
  const [jobGroup, setJobGroup] = useState('All');
  const [search, setSearch] = useState("");
  //   const [skillArray, setSkill] = useState([]);
  const [type, setType] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const skillList = useSelector((state) => state.Candidate_Reducer.skillsData);
  const jobData = useSelector((state) => state.Job_Reducer.jobData);
  const isLoadingJobList = useSelector((state) => state.Job_Reducer.isLoadingJobList);
  const typeLists = useSelector((state) => state.Job_Reducer.typeData);
  const countJob = useSelector((state) => state.Job_Reducer.jobCount);
  const isJobCount = useSelector((state) => state.Job_Reducer.isJobCount);
  const isJobCountErr = useSelector((state) => state.Job_Reducer.isJobCountErr);
  const typeRef = useRef([]);
  const skillRef = useRef([]);
  const expminRef = useRef([]);
  const expmaxRef = useRef([]);
  const salaryminRef = useRef([]);
  const salarymaxRef = useRef([]);

  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const usersPermission = localStorage?.getItem("userPermission")
  const permissionList = usersPermission?.split(',');

  const handleChange = (selectedOption) => {
    // Finding the complete item from the data using the selected option value
    setSelectedValue(selectedOption);

    const option = selectedOption.map((e) => {
      return skillList.find((element) => {
        if (element.id == e.value) {
          return element;
        }
      });
    });
    setskill(option)

  };
  const handleType = (typeOptions) => {
    // Finding the complete item from the data using the selected option value
    setSelectedType(typeOptions);

    const option = typeOptions.map((e) => {
      return typeLists.find((element) => {
        if (element.id == e.value) {
          return element;
        }
      });
    });
    setType(option)
  };

  const programmingLanguages = () => {
    if (skillList) {
      return skillList?.map((e) => {
        return { value: e.id, label: e.name };
      });
    }
  };
  const typeOptions = () => {
    if (typeLists) {
      return typeLists?.map((e) => {
        return { value: e.id, label: e.name };
      });
    }
  }

  const copyLink = (id) => {
    const path = `/view-job-data/${id}`;
    const fullUrl = window.location.origin + path;

    navigator.clipboard.writeText(fullUrl).then(() => {
      toast.success("Link Copied!!");
    }).catch(err => {
      toast.error('Failed To Copy');
    });
  }

  const handleSearch = (e) => {
    setSearch(e.target.value)
    // dispatch(jobList(navigate, token, experienceMax, experienceMin, salaryMax, salaryMin, search, skill, type))
    globalDebouncing(dispatch, jobList, navigate, token, experienceMax, experienceMin, salaryMax, salaryMin, e.target.value, skill, type, page, jobGroup);
    setPage(1);
  }
  const handelMinExp = (e) => {
    // dispatch(jobList(navigate, token, experienceMax, experienceMin, salaryMax, salaryMin, search, skill, type))
    setExperienceMin(e?.value ? e?.value : null);
  }
  const handelMaxExp = (e) => {
    // dispatch(jobList(navigate, token, experienceMax, experienceMin, salaryMax, salaryMin, search, skill, type))
    setExperienceMax(e?.value ? e?.value : null)
  }
  const handelMinSalary = (e) => {

    setSalaryMin(e?.value ? e?.value : null)
  }
  const handelMaxSalary = (e) => {
    setSalaryMax(e?.value ? e?.value : null)
    // dispatch(jobList(navigate, token, experienceMax, experienceMin, salaryMax, salaryMin, search, skill, type))
  }

  useEffect(() => {
    dispatch(skills(navigate));
    dispatch(typeList(navigate, token))
    // dispatch(jobList(navigate, token, experienceMax, experienceMin, salaryMax, salaryMin, search, skill, type))
    // dispatch(jobList(navigate, token, null, null, null, null, null, [], null, null));
  }, []);


  useEffect(() => {
    // dispatch(jobCount(navigate, token));
    // dispatch(jobList(navigate, token, experienceMax, experienceMin, salaryMax, salaryMin, search, skill, type))
    dispatch(jobList(navigate, token, experienceMax, experienceMin, salaryMax, salaryMin, search, skill, type, page, jobGroup))
  }, [experienceMax, experienceMin, salaryMax, salaryMin, skill, type, page, jobGroup]);
  useEffect(() => {
    //dispatch single time on page load
    dispatch(jobCount(navigate, token)); //get counts of job
  }, []);
  const menuClick = () => {
    props.onSidedataChange(!props.Sidedata);
    navigate("/jobs/create-job-list")
  };
  const handleClear = () => {
    setSearch("");
    setJobGroup("All");
    typeRef.current.clearValue();
    skillRef.current.clearValue();
    expmaxRef.current.clearValue();
    expminRef.current.clearValue();
    salaryminRef.current.clearValue();
    salarymaxRef.current.clearValue();
  };

  const pageChange = (e) => {
    setPage(e);
    // dispatch(jobList(navigate, token, experienceMax, experienceMin, salaryMax, salaryMin, search, skill, type, e));
  };
  // jobs
  const handleClick = (id) => {
    navigate(`/jobs/joblist-applicants/${id}`);
  };
  // jobs

  useEffect(() => {
    props.onSidedataChange(false);
  }, [])
  return (
    <div className="jobListsPage">
      <div className="container-fluid">
        <div className="paheHeader">
          <div className="row g-3 align-items-cneter justify-content-between">
            <div className="col-auto">
              <h5 className="pageTitle"><button className="fa_bars  d-lg-none me-3" onClick={menuClick}>
                <i className="fa-solid fa-bars"></i>
              </button>Job List</h5>
            </div>
            {permissionList?.includes("61ae06f5edb406550227b942") &&
              <div className="col-auto">
                <button className="btn btn-job" onClick={() => navigate("/jobs/create-job")}>Post Jobs</button>
              </div>
            }
          </div>
        </div>
        <div className="pageBody py-4">
          <div className="row g-3 mb-2">
            <div className="col-lg-4 col-md-6">
              <button className="widgetJob rounded p-4 btn" onClick={() => setJobGroup('Active')} disabled={isLoadingJobList}>
                <div className="row g-2 align-items-center justify-content-between">
                  <div className="col-auto">
                    <h2 className="text-white fw-bold fs-1">{!isJobCountErr && !isJobCount ? countJob.active_jobs : 0}</h2>
                  </div>
                  <div className="col-auto">
                    <h5>Active Jobs</h5>
                  </div>
                  <div className="col-auto">
                    <i className="fa-solid fa-briefcase fs-1"></i>
                  </div>
                </div>
              </button>
            </div>
            <div className="col-lg-4 col-md-6">
              <button className="widgetJob second rounded p-4 btn" onClick={() => setJobGroup('All')} disabled={isLoadingJobList}>
                <div className="row g-2 align-items-center justify-content-between">
                  <div className="col-auto">
                    <h2 className="text-white fw-bold fs-1">{!isJobCountErr && !isJobCount ? countJob.total_jobs : 0}</h2>
                  </div>
                  <div className="col-auto">
                    <h5>Total Jobs</h5>
                  </div>
                  <div className="col-auto">
                    <i className="fa-solid fa-briefcase fs-1"></i>
                  </div>
                </div>
              </button>
            </div>
            <div className="col-lg-4 col-md-6">
              <button className="widgetJob third rounded p-4 btn" onClick={() => setJobGroup('Applied')} disabled={isLoadingJobList}>
                <div className="row g-2 align-items-center justify-content-between">
                  <div className="col-auto">
                    <h2 className="text-white fw-bold fs-1">{!isJobCountErr && !isJobCount ? countJob.appied_on_active_jobs : 0}</h2>
                  </div>
                  <div className="col-auto">
                    <h5>Applied on Active Jobs</h5>
                  </div>
                  <div className="col-auto">
                    <i className="fa-solid fa-circle-user fs-1"></i>
                  </div>
                </div>
              </button>
            </div>
          </div>
          <div className="sortArea formArea bg-white p-4 rounded mb-4 position-relative" style={{ zIndex: 2 }}>
            <div className="row g-3">
              <div className="col-sm-12 col-md-auto">
                <div
                  className="sortBySearch position-relative"
                  style={{ minWidth: "300px" }}
                >
                  <input
                    type="search"
                    className="form-control pe-5 border"
                    placeholder="Search by job title"
                    value={search}
                    onChange={(e) => handleSearch(e)}
                  />
                  <button className="btn-search border-0 position-absolute end-0 top-0 h-100 px-2 bg-transparent">
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </button>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-auto"
                style={{ minWidth: "170px" }}
              >
                <Select
                  name=""
                  id=""
                  className=""
                  isMulti
                  ref={typeRef}
                  options={typeOptions()}
                  styles={customStyles}
                  placeholder="Types"
                  value={selectedType}
                  onChange={handleType}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
              <div
                className="col-sm-6 col-md-auto"
                style={{ minWidth: "300px" }}
              >
                <Select
                  name=""
                  id=""
                  className=""
                  ref={skillRef}
                  options={programmingLanguages()}
                  isMulti
                  styles={customStyles}
                  placeholder="Skills"
                  value={selectedValue}
                  onChange={handleChange}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
              <div
                className="col-sm-6 col-md-auto"
                style={{ minWidth: "150px" }}
              >
                <Select
                  name=""
                  id=""
                  className=""
                  ref={expminRef}
                  options={minExp}
                  styles={customStyles}
                  // value={experienceMin}
                  placeholder="Experience Min"
                  onChange={handelMinExp}
                  isClearable={true}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
              <div
                className="col-sm-6 col-md-auto"
                style={{ minWidth: "150px" }}
              >
                <Select
                  name=""
                  id=""
                  className=""
                  ref={expmaxRef}
                  options={maxExp}
                  styles={customStyles}
                  onChange={handelMaxExp}
                  // value={experienceMax}
                  placeholder="Experience Max"
                  isClearable={true}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
              <div
                className="col-sm-6 col-md-auto"
                style={{ minWidth: "170px" }}
              >
                <Select
                  name=""
                  id=""
                  className=""
                  ref={salaryminRef}
                  options={salaryOptionsOne}
                  styles={customStyles}
                  placeholder="Salary Min"
                  onChange={handelMinSalary}
                  isClearable={true}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
              <div
                className="col-sm-6 col-md-auto"
                style={{ minWidth: "170px" }}
              >
                <Select
                  name=""
                  id=""
                  className=""
                  ref={salarymaxRef}
                  options={salaryOptionsTwo}
                  styles={customStyles}
                  placeholder="Salary Max"
                  onChange={handelMaxSalary}
                  isClearable={true}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
              {experienceMax == null &&
                experienceMin == null &&
                salaryMax == null &&
                salaryMin == null &&
                search == '' &&
                type.length == 0 &&
                skill.length == 0
                ? null : <div
                  className="col-sm-6 col-md-auto"
                  style={{ minWidth: "170px" }}
                >
                  <button className="button_remove" onClick={() => handleClear()}>Clear Filter</button>
                </div>}
            </div>
          </div>

          <div className="jobTableArea table_box p-3 rounded">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="txt__md fw-bold">Job title</th>
                    <th className="txt__md fw-bold text-center text-md-start">Status</th>
                    <th className="txt__md fw-bold text-center text-md-start">Date</th>
                    <th className="txt__md fw-bold text-center text-md-start">Candidate</th>
                    <th className="txt__md fw-bold text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoadingJobList == true ?
                    (<tr>
                      <td colSpan={5}>
                        <div className='text-center py-3'>
                          <span className="loader"></span>
                        </div>
                      </td>
                    </tr>)
                    : (jobData?.data?.length > 0
                      ? jobData?.data?.map((e) => (
                        <tr key={e.id}>
                          <td onClick={() => handleClick(e.job_reference)} style={{ cursor: "pointer" }}>{e.title}</td>
                          <td className="text-center text-md-start">
                            {e.application_status == 1 ? (
                              <span className="statusBadge bg-success"></span>
                            ) : (
                              <span className="statusBadge bg-danger"></span>
                            )}
                            {e.application_status == 1 ? (
                              <span className="text-success ">Active</span>
                            ) : (
                              <span className="text-danger">Inactive</span>
                            )}
                          </td>
                          <td className="text-center text-md-start">{e?.created_at}</td>
                          <td className="text-center text-md-start">{e?.candidates_appiled}{" "}{e?.candidates_appiled?.length > 1 ? 'Candidates' : 'Candidate'}</td>
                          <td>
                            <div className="actionBtns text-center d-flex align-items-center justify-content-center">
                              <button onClick={() => { copyLink(e?.job_reference) }} className="me-2">
                                <i className="fa-solid fa-link"></i>
                              </button>
                              <button onClick={(element) => { navigate(`/jobs/view-job/${e?.job_reference}`) }} className="me-2">
                                <i className="fa-solid fa-eye">
                                </i>
                              </button>
                              {permissionList?.includes("65bb96ff53e762c89b9af17a") &&
                                <button onClick={() => { navigate(`/jobs/edit-job/${e.id}`) }} className="">
                                  <i className="fa-solid fa-pen-to-square"></i>
                                </button>
                              }
                            </div>
                          </td>
                        </tr>
                      ))
                      :
                      <tr>
                        <td colSpan={5}>
                          <div className='text-center py-3'>
                            No Data
                          </div>
                        </td>
                      </tr>)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {jobData?.data?.length > 0 &&
          <>
            <div className="parentPage">
              <Paginate
                currentPage={jobData.current_page}
                totalPages={jobData.total_page}
                onPageChange={(e) => pageChange(e)}
              />
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default JobsList;