import React, { useEffect, useState } from "react";
import "./job-list-new.css";
import Card from "react-bootstrap/Card";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Highcharts, { color } from "highcharts";
import HighchartsReact from "highcharts-react-official"
import { MdSearch } from "react-icons/md";
import Table from "react-bootstrap/Table";
import { FaEye } from "react-icons/fa";
import { BsCopy } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { postViewApplicant } from "../../Redux/ViewJobApplicants/action";
import { postJobDetails } from "../../Redux/JobDetails.jsx/action";
import { postActiveStatus } from "../../Redux/ActiveInactiveStatus/action";
import "react-datepicker/dist/react-datepicker.css";
import { useDebounce } from "use-debounce";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from 'react-bootstrap/Dropdown';
import { toast } from "react-toastify";



const JobListNew = ({Sidedata, onSidedataChange}) => {

  const [stats, setStats] = useState({});
  const [render, setRender] = useState(false)

  let experienceProcessed = [
    {
      name: "0 - 1 years",
      y: 0,
      count: 0,
      color: "#A8DADB",
    },
    {
      name: "1 - 2 years",
      y: 0,
      count: 0,
      color: "#03191a",
    },
    {
      name: "2 - 3 years",
      y: 0,
      count: 0,
      color: "#172da5",
    },
    {
      name: "3 - 4 years",
      y: 0,
      count: 0,
      color: "#c93915",
    },

    {
      name: "4 - 5+years",
      y: 0,
      count: 0,
      color: "#b34848",
    },
    {
      name: "5+ years",
      y: 0,
      count: 0,
      color: "#d1d40d",
    },
  ];

  // chart

  const [charData, setChartData] = useState([
    {
      name: "(1) years",
      y: 17,
      color: "#A8DADB",
    },
    {
      name: "(2) years",
      y: 17,
      color: "#D9E8D7",
    },
    {
      name: "(3) years",
      y: 16,
      color: "#E53945",
    },
    {
      name: "(4) years",
      y: 18,
      color: "#1D3557",
    },
    {
      name: "(5) years",
      y: 32,
      color: "#457B9D",
    },
    {
      name: "(5+) years",
      y: 32,
      color: "#457B9D",
    },
  ]);



  const options = {
    chart: {
      type: "pie",
      style: {
        fontFamily: "Poppins",
      },
    },
    title: {
      text: "",
    },
    plotOptions: {
      series: {
        borderRadius: 0,
        dataLabels: [
          {
            enabled: false,
            distance: 15,
            format: "{point.name}",
          },
          {
            enabled: true,
            distance: "-30%",
            filter: {
              property: "percentage",
              operator: ">",
              value: 5,
            },
            format: "{point.y}%",
            style: {
              fontSize: "1rem",
              textOutline: "none",
            },
          },
        ],
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Percentage",
        colorByPoint: true,
        data: experienceProcessed,
      },
    ],
  };



  // chart
  // for pagination
  const [page, setPage] = useState(1);
  // for pagination
  // for search

  const [search, setSearch] = useState("");
  const [text, setText] = useState("");
  const [value] = useDebounce(text, 1000);

  // for search
  const [status, setStatus] = useState("");
  const [activeStatus, setActiveStatus] = useState(false);
  const [experience, setExperience] = useState("");
  const [sort, setSort] = useState();
  const [readMore, setReadMore] = useState(false);
  const [selectedItem, setSlelectedItem] = useState(null);

  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.viewApplicants.applicants);
  const { isLoading } = useSelector((state) => state.viewApplicants);
  const jobDetails = useSelector((state) => state.viewJobDetails.jobDetails);
  const { dataActive } = useSelector((state) => state.activeStatus.applicantStatus);
  const { isLoadingActive } = useSelector((state) => state.activeStatus);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [datesArray, setDatesArray] = useState([]);
  const dates = []
  const finalDates = []

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const generateDatesArray = () => {
    if (!startDate || !endDate) {
      toast.error("Select valid date range")
      return
    }
    if (startDate > endDate) {
      toast.error("Start date cannot be greater than the end date")
      return
    }

    const currentDate = new Date().toISOString().split('T')[0];
    if (endDate > currentDate) {
      toast.error("End date cannot be greater than the current date");
      return;
    }
    setRender((prev) => !prev)
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dates = [];


    for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
      dates.push(new Date(date));
    }

    setDatesArray(dates);
  };

  const currentDate = new Date().toISOString().split('T')[0];

  datesArray.forEach((itm) => {
    dates.push(itm.toDateString())
  })
  dates.forEach((itm) => {
    finalDates.push(itm.slice(4, 10))
  })
  const options2 = {
    chart: {
      type: "line",
      style: {
        fontFamily: "Poppins",
      },
    },
    title: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: Object.keys(stats),
      accessibility: {
        description: "days of the Month",
      },
      lineWidth: 2,
      lineColor: "#ccc",
    },
    yAxis: {
      lineWidth: 2,
      lineColor: "#ccc",
      title: {
        text: "",
      },
    },
    plotOptions: {
      line: {

        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: false,
        lineWidth: 2,
        dashStyle: "Dash",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Job Roles",
        data: Object.values(stats),
      },
    ],
  };


  const isLoadingJobdetail = useSelector(
    (state) => state.viewJobDetails.isLoading
  );

  const exp = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
  ];

  const handleSort = (e) => {
    setSort(e.target.value);
    // dispatch(postViewApplicant(jobDetails?.data?._id, e.target.value));
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  const handleActiveStatus = (e) => {
    setActiveStatus(e.target.value);
    // console.log(e.target.value);
    dispatch(postActiveStatus(jobDetails?.data?._id, e.target.value));
  };

  const handleEmailCopyClick = () => {
    const emailToCopy = selectedItem?.contact?.email_primary;
    if (emailToCopy) {
      navigator.clipboard.writeText(emailToCopy)
        .then(() => {
          toast.success('Email copied to clipboard!');
        })
        .catch(err => {
          toast.error('Failed to copy email: ', err);
        });
    } else {
      alert('No email to copy');
    }
  };

  const handlePhoneCopyClick = () => {
    const phoneToCopy = selectedItem?.contact?.phone_primary;
    if (phoneToCopy) {
      navigator.clipboard.writeText(phoneToCopy)
        .then(() => {
          toast.success('Phone number copied to clipboard!');
        })
        .catch(err => {
          toast.error('Failed to copy phone number: ', err);
        });
    } else {
      alert('No phone number to copy');
    }
  };

  const handleExp = (e) => {
    setExperience(e.target.value);
  };

  const handleClick = () => {
    navigate(`/jobs/edit-job/${jobDetails?.data?._id}`);
  };
  const handleProfile = (id) => {
    navigate(`/candidates/all-candidates/profile/${id}`);
  };

  const handleNext = () => {
    setPage(page + 1);
  };
  const handlePrev = () => {
    setPage(page - 1);
  };


  useEffect(() => {
    dispatch(postJobDetails(id));
  }, [id]);

  useEffect(() => {
    dispatch(postViewApplicant(id, sort, status, experience, value, page));
  }, [id, sort, status, experience, value, page]);

  useEffect(() => {
    setActiveStatus(jobDetails?.data?.application_status);
  }, [jobDetails?.data?.application_status]);

  const dataCount = data?.data?.length;

  data?.data?.forEach((element) => {
    if (element.total_experience.total_in_months <= 12) {
      experienceProcessed[0].count += 1;
      experienceProcessed[0].y = Math.floor(
        (experienceProcessed[0].count / dataCount) * 100
      );
    } else if (
      element.total_experience.total_in_months <= 24 &&
      element.total_experience.total_in_months > 12
    ) {
      experienceProcessed[1].count += 1;
      experienceProcessed[1].y = Math.floor(
        (experienceProcessed[1].count / dataCount) * 100
      );
    } else if (
      element.total_experience.total_in_months <= 36 &&
      element.total_experience.total_in_months > 24
    ) {
      experienceProcessed[2].count += 1;
      experienceProcessed[2].y = Math.floor(
        (experienceProcessed[2].count / dataCount) * 100
      );
    } else if (
      element.total_experience.total_in_months <= 48 &&
      element.total_experience.total_in_months > 36
    ) {
      experienceProcessed[3].count += 1;
      experienceProcessed[3].y = Math.floor(
        (experienceProcessed[3].count / dataCount) * 100
      );
    } else if (
      element.total_experience.total_in_months <= 60 &&
      element.total_experience.total_in_months > 48
    ) {
      experienceProcessed[4].count += 1;
      experienceProcessed[4].y = Math.floor(
        (experienceProcessed[4].count / dataCount) * 100
      );
    } else if (element.total_experience.total_in_months > 60) {
      experienceProcessed[5].count += 1;
      experienceProcessed[5].y = Math.floor(
        (experienceProcessed[5].count / dataCount) * 100
      );
    }
  });
  useEffect(() => {
    if (data?.data?.length > 0) {
      setSlelectedItem(data?.data?.find((e, index) => index === 0));
    } else {
      setSlelectedItem(null);
    }
  }, [data?.data]);

  function formatDate(dateString) {
    const options = { month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  }

  let formatDates = [];
  data?.data?.forEach((itm) => {
    formatDates.push(formatDate(itm?.created_at));
  });


  useEffect(() => {
    const calculateStats = () => {
      const statsObject = {};
      finalDates?.forEach((date) => {
        const jobsOnDate = formatDates?.filter((job) => job === date);
        const count = jobsOnDate.length;
        statsObject[date] = count;
      });

      setStats(statsObject);
    };

    calculateStats();
  }, [render]);


    const menuClick = () => {
        onSidedataChange(!Sidedata);
    };



  return (
    <div className="jobListNew">
      <Card className="jobLsitTop">
        <Card.Body className=" py-0 py-md-4">
          <Row className="g-2">
            <Col lg="6" xl="4">
              <div className="jltLeft h-100 px-2">
                 <div className="mb-3">
                  <button className="fa_bars d-lg-none fs-4" onClick={menuClick}>
                    <i className="fa-solid fa-bars "></i>
                  </button>
                </div>
                <div className="jltLeftHeader mb-3">
                  <Row className="g-3 align-items-center">
                    <Col xs="auto" className="me-auto">
                      <div className="jltLeftHeaderLeft">
                        <div className="jlthlBox">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={42}
                            height={42}
                            viewBox="0 0 42 42"
                            fill="none"
                          >
                            <mask
                              id="mask0_3580_779"
                              style={{ maskType: "alpha" }}
                              maskUnits="userSpaceOnUse"
                              x={0}
                              y={0}
                              width={42}
                              height={42}
                            >
                              <rect width={42} height={42} fill="#2E2E2E" />
                            </mask>
                            <g mask="url(#mask0_3580_779)">
                              <path
                                d="M7 36.75C6.0375 36.75 5.21354 36.4073 4.52813 35.7219C3.84271 35.0365 3.5 34.2125 3.5 33.25V14C3.5 13.0375 3.84271 12.2135 4.52813 11.5281C5.21354 10.8427 6.0375 10.5 7 10.5H14V7C14 6.0375 14.3427 5.21354 15.0281 4.52813C15.7135 3.84271 16.5375 3.5 17.5 3.5H24.5C25.4625 3.5 26.2865 3.84271 26.9719 4.52813C27.6573 5.21354 28 6.0375 28 7V10.5H35C35.9625 10.5 36.7865 10.8427 37.4719 11.5281C38.1573 12.2135 38.5 13.0375 38.5 14V33.25C38.5 34.2125 38.1573 35.0365 37.4719 35.7219C36.7865 36.4073 35.9625 36.75 35 36.75H7ZM17.5 10.5H24.5V7H17.5V10.5Z"
                                fill="#2E2E2E"
                              />
                            </g>
                          </svg>
                        </div>
                        <div className="jlthlContent">
                          <h6 className="jlthlContentTitle">
                            {jobDetails?.data?.title}
                          </h6>
                          <p>
                            Created on:
                            <span>
                              {jobDetails?.data?.created_at
                                .slice(0, 10)
                                .replaceAll("-", "/")}
                            </span>
                          </p>
                        </div>
                      </div>
                    </Col>

                  </Row>
                  <Row className="g-3 mt-2 d-flex align-items-center justify-content-around">
                    <Col xs="auto">
                      <Button
                        variant="primary"
                        className="border-0"
                        onClick={handleClick}
                      >
                        Edit Post
                      </Button>
                    </Col>
                    <Col xs="auto">
                      <Form.Select
                        value={activeStatus}
                        aria-label="Default select example"
                        onChange={handleActiveStatus}
                      >
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                        {/* <option value="1">Active</option> */}
                      </Form.Select>
                    </Col>
                  </Row>
                </div>
                <div className="jltLeftBody">
                  <h5 className="mb-2">Description</h5>
                  {isLoadingJobdetail ? (
                    <div className="text-center py-3">
                      <span className="loader"></span>
                    </div>
                  ) : (
                    <div
                      className="grid_scroll_profile_card"
                      style={{ maxHeight: "250px" }}
                    >
                      {readMore
                        ? jobDetails?.data?.description
                        : `${jobDetails?.data?.description.substring(
                          0,
                          300
                        )}...`}

                      {jobDetails?.data?.description && (
                        <button
                          onClick={() => setReadMore(!readMore)}
                          className="more-btn"
                        >
                          {readMore ? "show less" : "  read more"}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col lg="6" xl="4">
              <div className="jltMiddle h-100 px-3">
                <div className="jltLeftHeader mb-3">
                  <Row className="g-3 align-items-center">
                    <Col xs="auto" className="me-auto">
                      <div className="jltLeftHeaderLeft">
                        <h6 className="jlthlContentTitle">Ratings</h6>
                      </div>
                    </Col>
                    <Col xs="auto">
                      <Form.Select aria-label="Default select example">
                        <option>Experience</option>
                        {charData.map((val) => {
                          return <option value="1">{val.name}</option>;
                        })}
                      </Form.Select>
                    </Col>
                  </Row>
                </div>
                <div className="jltLeftBody">
                  <HighchartsReact highcharts={Highcharts} options={options} />
                </div>
              </div>
            </Col>
            <Col lg="12" xl="4">
              <div className="jltEnd h-100 px-3">
                <div className="jltLeftHeader mb-3">
                  <Row className="g-3 align-items-center">
                    <Col xs="auto" className="me-auto">
                      <div className="jltLeftHeaderLeft">
                        <h6 className="jlthlContentTitle">Applied</h6>
                      </div>
                    </Col>
                    <Col xs="auto">
                      <div className="date_picker">

                        <label htmlFor="start-date">From:</label>
                        <input type="date" id="start-date" className="inp" value={startDate} onChange={handleStartDateChange} />



                        <label htmlFor="end-date">To:</label>
                        <input type="date" id="end-date" className="inp" value={endDate} onChange={handleEndDateChange} max={currentDate} />


                        <button onClick={generateDatesArray}>Submit</button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="jltLeftBody">
                  <HighchartsReact highcharts={Highcharts} options={options2} />
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="jobLsitBottom mt-3">
        <Card.Body className="mt-3 mt-lg-4">
          <Row className="g-3 g-lg-4">
            <Col xl="7" lg="6">
              <div className="ps-lg-4 pe-lg-3">
                <h6 className="jlthlContentTitle mb-3">Applicants</h6>
                <div className="jltBottomHeader mb-3">
                  <Row className="g-3 align-items-center">
                    <Col xs="auto" className="me-auto">
                      <div className="tableSearch">
                        <span className="icoSearch">
                          <MdSearch />
                        </span>
                        <Form.Control
                          type="search"
                          placeholder="Search"
                          value={text}
                          onChange={(e) => setText(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col xs="auto">
                      <Form.Select
                        aria-label="Default select example"
                        onChange={handleSort}
                      >
                        <option>Sort</option>
                        <option value="asc">a-z</option>
                        <option value="desc">z-a</option>
                      </Form.Select>
                    </Col>
                    <Col xs="auto">
                      <Form.Select
                        aria-label="Default select example"
                        onChange={handleStatus}
                      >
                        <option value={""}>Status</option>
                        <option value="0">New</option>
                        <option value="1">Reviewed</option>
                        <option value="2">Good Fit</option>
                        <option value="3">Interview Scheduled</option>
                        <option value="4">Made Offer</option>
                        <option value="5">Hired</option>
                        <option value="6">Rejected</option>
                        <option value="7">On Hold</option>
                      </Form.Select>
                    </Col>
                    <Col xs="auto">
                      <Form.Select
                        aria-label="Default select example"
                        onChange={handleExp}
                      >
                        <option value={""}>Years of Experience</option>
                        {exp.map((months, idx) => (
                          <option key={idx} value={months}>
                            {months}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </div>
                <div className="jltBottomBody">
                  <Table responsive borderless>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Date applied</th>
                        <th>Experience</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={"4"} style={{ paddingTop: "0" }}></td>
                      </tr>
                      {isLoading ? (
                        <tr>
                          <div className="text-center py-3">
                            <span className="loader"></span>
                          </div>
                        </tr>
                      ) : data?.data ? (
                        data?.data?.map((item) => (
                          <tr key={item._id}>
                            <td>
                              <button
                                onClick={() => setSlelectedItem(item)}
                                className="applicant-btn"
                              >
                                {item.first_name}
                              </button>
                            </td>
                            <td>
                              {item.created_at
                                .slice(0, 10)
                                .replaceAll("-", "/")}
                            </td>
                            <td>{item.total_experience.year}yrs</td>
                            <td className="text-center">
                              <Button
                                variant="transaprent"
                                className="border-0 "
                              >
                                <FaEye
                                  onClick={() => handleProfile(item?._id)}
                                />
                              </Button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <div className="text-center py-3">
                            <p>No data found</p>
                          </div>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {data?.data && (
                    <div className="pagination col-md-12">
                      <Button
                        variant="primary"
                        onClick={handlePrev}
                        disabled={page === 1}
                      >
                        <FaChevronLeft />
                      </Button>

                      <Button
                        variant="primary"
                        onClick={handleNext}
                        disabled={page === data?.last_page}
                      >
                        <FaChevronRight />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col xl="5" lg="6" className="pe-lg-4 ps-0">
              <div className="jltProfileview h-100 ps-4">
                <div className="jltLeftHeader mb-3">
                  <Row className="g-3 align-items-center">
                    <Col xs="auto" className="me-auto">
                      <div className="jltLeftHeaderLeft">
                        <div className="jlthlBox">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={42}
                            height={42}
                            viewBox="0 0 42 42"
                            fill="none"
                          >
                            <mask
                              id="mask0_3580_779"
                              style={{ maskType: "alpha" }}
                              maskUnits="userSpaceOnUse"
                              x={0}
                              y={0}
                              width={42}
                              height={42}
                            >
                              <rect width={42} height={42} fill="#2E2E2E" />
                            </mask>
                            <g mask="url(#mask0_3580_779)">
                              <path
                                d="M7 36.75C6.0375 36.75 5.21354 36.4073 4.52813 35.7219C3.84271 35.0365 3.5 34.2125 3.5 33.25V14C3.5 13.0375 3.84271 12.2135 4.52813 11.5281C5.21354 10.8427 6.0375 10.5 7 10.5H14V7C14 6.0375 14.3427 5.21354 15.0281 4.52813C15.7135 3.84271 16.5375 3.5 17.5 3.5H24.5C25.4625 3.5 26.2865 3.84271 26.9719 4.52813C27.6573 5.21354 28 6.0375 28 7V10.5H35C35.9625 10.5 36.7865 10.8427 37.4719 11.5281C38.1573 12.2135 38.5 13.0375 38.5 14V33.25C38.5 34.2125 38.1573 35.0365 37.4719 35.7219C36.7865 36.4073 35.9625 36.75 35 36.75H7ZM17.5 10.5H24.5V7H17.5V10.5Z"
                                fill="#2E2E2E"
                              />
                            </g>
                          </svg>
                        </div>
                        <div className="jlthlContent">
                          <h6 className="jlthlContentTitle d-xl-inline">
                            {selectedItem && selectedItem.first_name}
                          </h6>
                          <p className="d-xl-inline">
                            - {jobDetails?.data?.title}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col xs="auto">
                      <Button
                        variant="primary"
                        className="border-0"
                        onClick={() => handleProfile(selectedItem?._id)}
                      >
                        View Profile
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="jltProfileViewBody py-3">
                  <Row className="my-3">
                    <Col md="4">Email:</Col>
                    <Col md="8">
                      {selectedItem && selectedItem?.contact?.email_primary}

                      <Button variant="transparent" className="border-0 p-0" onClick={handleEmailCopyClick}>
                        <BsCopy />
                      </Button>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col md="4">Phone:</Col>
                    <Col md="8">
                      {selectedItem && selectedItem?.contact?.phone_primary}

                      <Button variant="transparent" className="border-0 p-0" onClick={handlePhoneCopyClick}>
                        <BsCopy />
                      </Button>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col md="4">Location:</Col>
                    <Col md="8">
                      {selectedItem && selectedItem?.contact?.city}
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col md="4">Total Experience:</Col>
                    <Col md="8">
                      {selectedItem?.total_experience?.year} years {selectedItem?.total_experience?.month} months
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col md="4">Relevant Experience:</Col>
                    <Col md="8">
                      {selectedItem?.relevant_experience?.year} years {selectedItem?.relevant_experience?.month} months
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col md="4">Currently Working:</Col>
                    {selectedItem?.job_histories?.map((company) => {
                      return <Col md="8">{company?.company}</Col>;
                    })}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default JobListNew;
