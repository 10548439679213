import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingsNavbar from "../Components/SettingsNavbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import Avatar from "../Components/settings/Avatar";
import { findSourcef } from "../Redux/settings/findSource/action";
import { updateSourcef } from "../Redux/settings/updateSource/action";
import { toast } from "react-toastify";
import Spiner1 from "../Components/Spiner1";

const UpdateSource = (props) => {
  let { id } = useParams();
  const [SourceName, setSourceName] = useState("");
  const [status1, setStatus] = useState("0");
  const sourceList = useSelector((state) => state.findSource);
  const updateSource = useSelector((state) => state.updateSource);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const changeSkilName = (e) => {
    setSourceName(e.target.value);
  };
  const addSkillA = (e) => {
    e.preventDefault();
    if (SourceName?.trim() == "") {
      toast.warn("Please enter source name");
      return
    }
    dispatch(updateSourcef(id, SourceName, status1, navigate));
    // navigate("/settings/source");
  };
  useEffect(() => {
    setSourceName(sourceList?.data?.data?.name);
    setStatus(sourceList?.data?.data?.status);
  }, [sourceList]);
  useEffect(() => {
    dispatch(findSourcef(id, navigate));
    setSourceName(sourceList?.data?.data?.name);
  }, []);
  return (
    <div>
      <SettingsNavbar
        navdata={{
          header: "Update Lead Source",
          buttonText: "designation Button",
          buttonPath: "/settings/skillsuggetion",
        }}
        newSidedata={props.Sidedata} newonSidedataChange={props.onSidedataChange}
      />
      <div className="user-action-area placeholder-glow">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Link to={"/settings/source"}>
                {" "}
                <BsFillArrowLeftCircleFill /> Source List
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="create-company-form">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="type">
                <span className="font-weight-bold ms-4">Type</span>
              </div>

              <div className="form">
                <div className="row g-2">
                  <div className="col-md-4">
                    <div className="position-relative list_search_fild">
                      <input
                        type="text"
                        name="query"
                        placeholder="Source Name"
                        aria-label=""
                        value={SourceName?SourceName:""}
                        className={`form-control coustom_P h-100 ${sourceList?.isLoading || updateSource?.isLoading ?"placeholder":""}`}
                        onChange={(e) => changeSkilName(e)}
                        // disabled
                      />
                      <Avatar
                        placeholder={sourceList?.isLoading || updateSource?.isLoading ?"placeholder":""}
                        name={SourceName}
                        size={"34px"}
                        position="absolute"
                        top="3px"
                        left="5px"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <select
                      value={status1}
                      className={`col-sm-4 mb-2 form-control form-control-custom ${sourceList?.isLoading || updateSource?.isLoading ?"placeholder":""} `}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option
                        value="1"
                      >
                        Active
                      </option>
                      <option
                        value="0"
                      >
                        Inactive
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <button
                className={`btn btn-success button-outline choosed mt-3 ${sourceList?.isLoading || updateSource?.isLoading ?"placeholder":""}`}
                disabled={sourceList?.isLoading || updateSource?.isLoading}
                onClick={(e) => addSkillA(e)}
              >
                {sourceList?.isLoading || updateSource?.isLoading ? <Spiner1/> : "Update"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateSource;
