import React, { useEffect} from "react";
import "./jobs.css";
import { Link, useNavigate, useParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jobsData, specificeJob } from "../../Redux/JobReducer/action";
import { LuClipboardList } from "react-icons/lu";
import { Button } from "react-bootstrap";
const JobsViwer = (props) => {
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const {id}=useParams()
  const {candidateJobData,isErrorCandijob,isLoadingCandiJob}=useSelector((state)=>state.Job_Reducer)


  
  useEffect(() => {
    props.onSidedataChange(!props.Sidedata);
  }, [])

const menuClick = () => {
    props.onSidedataChange(!props.Sidedata);
  }

  const handleClick = () => {
    navigate(`/apply-job/${id}`);
  };
  useEffect(()=>{
    dispatch(jobsData(id))
  },[])
  return (
      <>
          <div className="container">
          <div className="job_post_body" style={{height:"90vh"}}>
                 {isLoadingCandiJob && (props.title=="candidate"?
                 (<div  className="loading_box d-flex align-items-center ">
                   <LuClipboardList className={" text-primary"} style={{ fontSize: '72px', color: 'white', fontWeight: "100" }} />
                     <div>
                     <div className="heading loading_animation  text-primary">
                        <p>I</p><p>n</p><p>t</p><p>e</p><p>r</p><p>v</p><p>i</p><p>e</p><p>w</p></div>
                        <p className="large_text  text-primary">Screening</p>
                       </div>
                       </div>):
                      (<div className='text-center py-3 d-flex justify-content-center align-items-center w-100 ' style={{height:"90vh"}}>
                       <span className="loader"></span>
                      </div>))} 
                     <div className={`row ${isLoadingCandiJob && "active d-none"}`}>
                      <div className="col-12">
                          {props.title=="candidate"?<div className="job_post_header pt-2  pb-4">
                              <img src="/interview_logo.png" alt="" />
                          </div>:
                          <div className="col-12">
                            {/* <img className="hire_logo" src="/logos/hireingLogo.png" alt="" /> */}
                             <button className="fa_bars  d-lg-none" onClick={menuClick}>
                                 <i className="fa-solid fa-bars"></i>
                            </button>
                             <b>Job Details</b>
                          </div>
                          }
                      </div>
                      {candidateJobData?.image ?<div className="col-12 py-3">
                          <img src={candidateJobData?.image} alt="" className=' img-fluid' style={{minHeight:'95px',width:" 100%",height: "200px",maxWidth: "1200px"}}/>
                      </div>:
                      <div className="col-12 py-3">
                          <img src="/jobpost_banner.png" alt="" className=' img-fluid' style={{minHeight:'95px'}}/>
                      </div>}
                      <div className="col-12">
                          <div className="job_post_content ">
                          <div className='py-2 d-flex flex-wrap justify-content-between' style={{ borderBottom: '1.5px solid #D3D3D3' }}>
                                  <h3 className='ps-2 px-xs-3 px-md-5 mb-0 text-center text-sm-start'>{candidateJobData?.title}</h3>
                                  <p className="mx-2 d-flex align-items-center text-center px-4  mb-0">JOB REF NO:-{" "}<b>{candidateJobData?.job_reference}</b></p>
                              </div>
                              <div className='px-3 px-md-5 py-3'>
                                  <ul className='w-100 list-unstyled mb-5 d-flex flex-wrap align-items-center justify-content-between' style={{gap:'18px'}}>
                                      <li>
                                          <p>DESIGNATION</p>
                                          <h6>{candidateJobData?.designation}</h6>
                                      </li>
                                      <li>
                                          <p>JOB PREFERENCE</p>
                                          <h6>{candidateJobData?.preference}</h6>
                                      </li>
                                      <li>
                                          <p>JOB LOCATION</p>
                                          <h6>{candidateJobData?.location}</h6>
                                      </li>
                                  </ul>
                                  <div className='body_content'>
                                      <div className='mb-5'>
                                          <h6 className='mb-1'>Job Description</h6>
                                          <p style={{overflowWrap: "break-word"}}>{candidateJobData?.description}</p>
                                      </div>
                                      <div className=' mb-5'>
                                          <h6 className='mb-2'>Required Skills</h6>
                                          <div className='job_post_skil_list d-flex align-items-center flex-wrap' style={{ gap: '10px' }}>
                                             {candidateJobData?.skills?.map((item,index)=> <span key={index}>{item}</span>)}
                                          </div>
                                      </div>
                                  </div>
                                  <ul className='w-100 list-unstyled mb-5 d-flex flex-wrap align-items-center justify-content-between' style={{gap:'18px'}}>
                                      <li>
                                          <p>Expected Salary Per Month</p>
                                          <h6>
                                          {candidateJobData?.currency_type}{candidateJobData?.salary_min}/-{" "}to{" "}{candidateJobData?.currency_type}{candidateJobData?.salary_max}/-
                                          </h6>
                                      </li>
                                      <li>
                                          <p>Experience in years</p>
                                          <h6>{candidateJobData?.experience_min} to {candidateJobData?.experience_max}</h6>
                                      </li>
                                      <li>
                                          <p>Total Number Of Vacancy</p>
                                          <h6>{candidateJobData?.total_vacancy}</h6>
                                      </li>
                                  </ul>
                                  <div className='body_content'>
                                      <div className='mb-5'>
                                          <h6 className='mb-1'>OTHERS</h6>
                                         <p>{candidateJobData?.note}</p> </div>
                                  </div>
                                  <div className='d-flex justify-content-end'>
                                      <div className='mb-5'>
                                      <h6 className="px-2 me-2">Published Date:<b className="px-2">{candidateJobData?.created_at?.replace(/-/g, " ")}</b></h6>
                                      </div>
                                  </div>
                                 {props.title=="candidate" &&<Button to="" className='mb-4' onClick={handleClick}>Apply Now</Button>}

                              </div>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </>
  )
};

export default JobsViwer;