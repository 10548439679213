import React, { useEffect } from "react";
// import SideNav from "./SideNav";
import "./css/AddTest.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postTest } from "../../Redux/TestReducer/action";
import { useState } from "react";
import { HiChevronLeft } from "react-icons/hi";
// import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Spiner1 from "../../Components/Spiner1";
import { BiSolidDownArrow } from "react-icons/bi";

const AddTest = ({ newSidedata, onSidedataChange }) => {
  const sessionTokenValue = localStorage.getItem("token");

  const webToken = sessionTokenValue
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState({
    title: "",
  });
  const [testId, setTestId] = useState();
  const [toaster, setToaster] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);
  const [testType, setTesttype] = useState("custom test");

  const testData = useSelector((state) => state.test?.testId);
  const loading = useSelector((state) => state.test?.isLoadingPost)

  const handleInput = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  useEffect(() => {
      setOnSubmit(false);
  }, [inputValue]);

  const handleSubmit = (e) => {
    e.preventDefault(); 
    if (!inputValue.title) {
      setToaster(true);
      toast.warn("Please Enter Test Title");
      setTimeout(() => {
        setToaster(false);
      }, 1000);
    } else if (!/^[^\d\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?][^\d]*.*/.test(inputValue.title)) {
      setToaster(true);
      toast.warn("Please Provide valid Test Title");
      setTimeout(() => {
        setToaster(false);
      }, 1000);
    } else {
      setToaster(false);
      if (onSubmit === false) {
        setOnSubmit(true);
        dispatch(postTest(webToken,navigate,window.location.href, inputValue,testType)).then(() => {
          setTestId(testData);
        });
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (toaster === false) {
        handleSubmit(e);
      }
    }
  };

  const backToPreviousPage = () => {
    window.history.back();
  };
  const menuClick = () => {
    onSidedataChange(!newSidedata);
  };

  useEffect(() => {
    if (!sessionTokenValue) {
      navigate("/login")
    }
  }, [sessionTokenValue])

  return (
    <div className="body_content">
      <div className="container-fluid">
        <button className="fa_bars  d-lg-none" onClick={menuClick}>
          <i className="fa-solid fa-bars fs-4"></i>
        </button>
        <div className="row">
          <div className="col-12 hjklop_right">
            <div className="container-fluid px-0 px-lg-4 my-4 my-lg-5">
              <div className="top_header border-bottom pb-2">
                <div className="d-flex align-items-center">
                  <img
                    id="top_icon"
                    className="gray_round "
                    src="/logos/update_test.png"
                    alt="img"
                  />
                  <Link
                    onClick={backToPreviousPage}
                    className="back_to_text fw-bold text-dark d-flex align-items-center mt-0"
                  >
                    <HiChevronLeft className="fs-3" />
                    Back To Test List
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-lg-8">
                  <div className="add_test_block my-4">
                    <div className="">
                      <div className="inner_div">
                        <b className="add_new_text">Add New Test</b>
                        <div className="d-flex flex-column flex-md-row">
                        <div className="my-2 my-sm-4 col-lg-6">
                          <input
                            type="text"
                            className="single_input form-control"
                            placeholder="Please Enter Title Here"
                            name="title"
                            value={inputValue?.title?.trimStart()}
                            onChange={handleInput}
                            onKeyDown={handleKeyPress}
                          />
                        </div>
                        <div className="my-2 my-sm-4 ms-sm-2 col-lg-6">
                          <div className="single_input form-control d-flex ">
                          <select style={{border:"transparent",width:"100%",outline:"none"}}
                            // placeholder="Choose Test Type"
                            name="title"
                            value={testType?testType:""}
                            onChange={(e)=>{
                              setTesttype(e.target.value)
                            }}>
                          <option value="" hidden>Choose Test Type</option>
                          <option  value="custom test">MCQ</option>
                          <option value="logical test">Logical</option>
                          </select>
                          {/* <BiSolidDownArrow className=""/> */}
                          </div>
                        </div>
                        </div>
                        <div className="">
                          
                            <button 
                            id="save_btn" 
                            disabled={loading || toaster }
                            onClick={handleSubmit} >
                              {loading ? <Spiner1 /> : "Save"}
                            </button>
                           <button
                            id="cancel_btn"
                            onClick={backToPreviousPage}
                           >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTest;
