import React, { useEffect, useState } from "react";
import { } from "./UserCreate.css";
import UserHead from "./UserHead";
import { accessList, specUserData, userAdd, userUpdate } from "../../Redux/User/action";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BlurLoader } from "../../Components/BlurLoader/BlurLoader";
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import Spiner1 from "../../Components/Spiner1";
import { getPermission } from "../../Redux/settings/userPermission/action";
import Section from "../../Components/Permission/Section";
import Item from "../../Components/Permission/Item";

const UserCreate = (props) => {
  const { id } = useParams()
  const accessData = useSelector((state) => state?.User_Reducer?.accessData);
  const specficData = useSelector((state) => state?.User_Reducer?.specUser);
  const loading = useSelector((state) => state?.User_Reducer?.isAddLoading);
  const upLoading = useSelector((state) => state?.User_Reducer?.isUpLoading);
  const permission = useSelector((state) => state.getPermissionSet);
  const permissionList = permission?.data?.data
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [allPermissions, setAllpermissions] = useState([]);
  const [choosedType, setChoosedType] = useState("");
  const [invalidEmail, setInvalidEmail] = useState("");
  const [isActive, setIsActive] = useState(0);
  const [selectData,setSelectData]=useState(false)
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPass: false,
  });


  const handleChange = (element) => {
    const choosedData = accessData?.find((e) => {
      if (e.id === element.target.value) {
        return e
      }
      return null
    })
    setSelectData(false)
    setChoosedType(choosedData)
    setAllpermissions(choosedData?.permissions ? choosedData?.permissions:[{}])
  };
  const toggle = (e) => {
    if (isActive === 1) {
      setIsActive(0)
    }else{
      setIsActive(1)
    }
  };

  const groupedPermissions = permissionList?.reduce((pre, curr) => {
    if (!pre[curr.section]) {
        pre[curr.section] = [];
    }
    pre[curr.section].push(curr);
    return pre;
}, {});

const formatPermissionName = (name) => {
  return name.replace(/([a-z])([A-Z])/g, '$1 $2')
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
};


  useEffect(() => {
    setIsActive(specficData?.status)
  }, [specficData?.status])

  
  const handleEmail = (e) => {
    setInvalidEmail("")
   setEmail(e.target.value.toLowerCase())
    const emailRegex = /^(?!.*(?:\.com.*\.com|\.in.*\.in|\.co.*\.co))[a-zA-Z0-9+?._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if( !emailRegex.test(e.target.value)){
      setInvalidEmail("Please Provide A Valid Email id")
    }
    if(e.target.value.includes("..")){
      setInvalidEmail("Please Provide A Valid Email id")
    }
    const [localPart, domain] = e.target.value.split('@');
    if (domain) {
      if (domain.includes('.')) {
        const parts = domain.split('.');
        if (parts.length > 4) {
          setInvalidEmail("Please Provide A Valid Domain id") 
          return;
        }
        for (let i = 1; i < parts.length - 1; i++) {
          for (let j = i + 1; j < parts.length; j++) {
            if (parts[i] === parts[j]) {
              setInvalidEmail("Please Provide A Valid Domain id")
              return;
            }
          }
        }
      }
    }
  };


const validData=()=>{
  if (!name) {
    return toast.warn("Please Provide Name");
  } else if (!email) {
    return toast.warn("Please Provide Email");
  } else if (props.title!=="edit" && !password) {
    return toast.warn("Please Provide Password");
  } else if (props.title!=="edit"&& !confirmPass) {
    return toast.warn("Please Provide  Confirm Password");
  } else if (password !== confirmPass) {
    return toast.warn("Password and Confirm Password Not Matched ");
  } else if (allPermissions < 1 && isActive === 1) {
    return toast.warn("Please Select Atleast one allPermissions");
  } else if (!choosedType.id) {
    return toast.warn("Please Provide Type");
  }else if (invalidEmail) {
    return toast.warn("Please Provide A Valid Email id");
  }else{
    return false
  }
}


  const handleSubmit = (event) => {
    if(validData()){
      return
    } 
    else{  
    dispatch(
      userAdd(
        navigate,
        token,
        allPermissions,
        email,
        name,
        password,
        confirmPass,
        isActive,
        choosedType
      )
    );}
  };



  const handleUpdate = () => {
    if(validData()){
      return
    } 
    else{  
    dispatch(
      userUpdate(
        navigate,
        token,
        allPermissions,
        email,
        name,
        password,
        confirmPass,
        isActive,
        choosedType,
        specficData?.id
      )
    )}
  }

const emptyPermission=()=>{
  setAllpermissions([])
  return 
}


const handleSelect=(e)=>{
  setSelectData(!selectData)
  const data=[]
  emptyPermission()
   e.target.checked&& permissionList?.map((e) => {e.section&&data.push(e.id)})
  !e.target.checked && setChoosedType({ id: "" })
   setAllpermissions(data) 
}
// useEffect(() => {
// setSelectData(false)
// },[allPermissions])

  useEffect(() => {
    if (props.title === "edit") {
      dispatch(
        specUserData(
          navigate,
          token,
          id))
    }
  }, [props.title]);
  
  useEffect(() => {
    if (props.title === "edit") {
      setTimeout(() => {
        setAllpermissions(specficData?.access)
        setName(specficData?.name)
        setEmail(specficData?.email ? specficData?.email : "")
      }, 100)
    }
  }, [specficData])

  useEffect(() => {
    const foundData = accessData?.find((e) => e.id === specficData?.type);
    if (foundData) {
      setChoosedType(foundData);
    }
  }, [accessData, specficData?.type,]);

  useEffect(() => {
    dispatch(accessList(navigate, token));
    dispatch(getPermission(navigate));
    !specficData?.status && setIsActive(0)
  }, [])
  return (
    <div className="container">
      <div className="row">
        <UserHead newSidedata={props.Sidedata} newonSidedataChange={props.onSidedataChange} navdata={{ header: props.title === "edit" ? "Update User" : "Create Users" }} />
      </div>
      <div>
      <div className="row ">
        <div className="user-action-area pb-2 d-flex justify-content-between">
          <div className="add-users">
            <Link to="/user/user-list" className="txt__sm txt__para">
              <BsFillArrowLeftCircleFill /> User List
            </Link>
          </div>
          {props.title === "edit" &&( upLoading) ? <BlurLoader /> : null}
          {props.title !== "edit" ?
            (loading === false ?
              <button
                className=" p-1 button-outline choosed rounded"
                onClick={() => handleSubmit()}
              >
                Register
              </button>
              :
              <button
                className="p-1 button-outline choosed rounded"
              >
                <Spiner1 />
              </button>
            )
            : (upLoading === false ?
              <button
                className="p-1 button-outline choosed rounded"
                onClick={() => handleUpdate()}
              >
                Update
              </button> :
              <button
                className="p-1 button-outline choosed rounded"
              >
                <Spiner1 />
              </button>)}
        </div>
      </div>
      <div className="row mb-4">
        <div className=" col-md-3 col-sm-6 mb-2 filter_search ">
          <label className="text-dark">User name</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="User name"
            className="form-control"
            aria-required="true"
            aria-invalid="false"
            value={name ? name : ""}
            onChange={(e) => {(/^[a-zA-Z]+$/.test(e.target.value) || e.target.value=="")&&setName(e.target.value)}}
          />
          <div className="user-initial"></div>
        </div>

        <div className="col-md-3 col-sm-6 mb-2 ">
          <label className="text-dark">Email</label>
          <input
            type="email"
            autoComplete="one-time-code"
            placeholder="User email"
            className="form-control"
            value={email ?email :""}
            onChange={(e) => {
              handleEmail(e);
            }}
          />
          <p className="text-danger">{invalidEmail}</p>
        </div>

        <div className="col-md-3 col-sm-6 mb-2 ">
          <label className="text-dark">New Password</label>
          <div className=" position-relative">
            <button className='profile_setting_eye' onClick={() => setShowPassword((prev) => ({ ...prev, password: !prev?.password }))}><i className={showPassword?.password ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'}></i></button>
            <input
              type={!showPassword?.password ? "password" : "text"}
              placeholder="Password"
              className="form-control"
              autoComplete="one-time-code"
              value={password ?password : ""}
              onChange={(e) => {
                setPassword(e.target.value.replace(" ", ""));
              }}
            />
          </div>
        </div>
        <div className="col-md-3 col-sm-6 mb-2 ">
          <label className="text-dark">Confirm Password</label>
          <div className=" position-relative">
            <button className='profile_setting_eye' onClick={() => setShowPassword((prev) => ({ ...prev, confirmPass: !prev?.confirmPass }))}><i className={showPassword?.confirmPass ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'}></i></button>
            <input
              type={!showPassword?.confirmPass ? "password" : "text"}
              placeholder="Confirm password"
              className="form-control"
              autoComplete="one-time-code"
              value={confirmPass?confirmPass:""}
              onChange={(e) => {
                setConfirmPass(e.target.value.replace(" ", ""));
              }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p>User type</p>
          <div className="d-sm-flex align-items-center justify-content-between w-100 my-2">
        
            <button
              className={` toggle_button ${isActive===0 ? "" : "active"}`}
              onClick={(e)=>toggle(e)}
            ></button>
         
          
              <div className="w-100 me-4">
                <select
                  className="col-sm-4 form-control form-control-custom mx-0 mx-sm-2 mt-2 mt-sm-0 "
                  value={choosedType?.id}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">Select User Type</option>
                  {accessData?.length>0?accessData?.map((item, index) => (
                      <option key={index} name={item.name} value={item.id}>
                        {item.name}
                      </option>
                    )):"no data"
                  }
                </select>
              </div>
           
            {isActive===1 &&<div className="mx-0 mx-sm-2 mt-2 mt-sm-0 d-flex border border-secondary rounded px-1 h-10 bg-light align-items-center justify-content-center" style={{whiteSpace:'nowrap', maxWidth:'100px'}}>
            
               <input id="225588" type="checkbox" checked={selectData} onChange={(e)=>{handleSelect(e)}}/>
               <label className="form-check-label mx-1" htmlFor="225588">Select All</label>
          
            </div>}
          </div>
        </div>
      </div>
      {isActive===1 ? (
        <div className="row">
             {groupedPermissions && Object.keys(groupedPermissions).map((section, index) => section !== "" && (
              <Section key={index} title={section}>
                {groupedPermissions[section].map((permission, i) => (
                  <Item key={i + index} id={permission?.id} allPermissions={allPermissions} setAllpermissions={setAllpermissions} setSelectData={setSelectData} name={<span>{formatPermissionName(permission?.name)} </span>}  />
                ))}
              </Section>
            ))}
        </div>
      ) : null}
     </div>
    </div>
  );
};

export default UserCreate;