import {
  POST_TEST_SUCCESS,
  POST_TEST_FAILURE,
  POST_TEST_REQUEST,
  GET_TEST_REQUEST,
  GET_TEST_SUCCESS,
  GET_TEST_FAILURE,
  CREATE_TEST_REQUEST,
  CREATE_TEST_SUCCESS,
  CREATE_TEST_FAILURE,
  // TEST_FILTER_PROGRAM_REQUEST,
  // TEST_FILTER_PROGRAM_SUCCESS,
  // TEST_FILTER_PROGRAM_FAILURE,
  TEST_FILTER_CUSTOM_REQUEST,
  TEST_FILTER_CUSTOM_SUCCESS,
  TEST_FILTER_CUSTOM_FAILURE,
  TEST_BY_ID_REQUEST,
  TEST_BY_ID_SUCCESS,
  TEST_BY_ID_FAILURE,
  TEST_BY_APPLICANT_ID_REQUEST,
  TEST_BY_APPLICANT_ID_SUCCESS,
  TEST_BY_APPLICANT_ID_FAILURE,
  // STATE_CHANGE_REQUEST,
  // STATE_CHANGE_SUCCESS,
  // STATE_CHANGE_FAILURE,
  // ARCHIVE_CHANGE_REQUEST,
  // ARCHIVE_CHANGE_SUCCESS,
  // ARCHIVE_CHANGE_FAILURE,
  CREATE_TOKEN_REQUEST,
  CREATE_TOKEN_SUCCESS,
  CREATE_TOKEN_FAILURE,
  SEND_MAIL_REQUEST,
  SEND_MAIL_SUCCESS,
  SEND_MAIL_FAILURE,
  DELETED_TEST_REQUEST,
  DELETED_TEST_SUCCESS,
  DELETED_TEST_FAILURE,
  DELETE_TEST_PERMANENTLY_REQUEST,
  DELETE_TEST_PERMANENTLY_SUCCESS,
  DELETE_TEST_PERMANENTLY_FAILURE,
  TEST_SEARCH_REQUEST,
  TEST_SEARCH_SUCCESS,
  TEST_SEARCH_FAILURE,
  TEST_DUPLICATE_REQUEST,
  TEST_DUPLICATE_SUCCESS,
  TEST_DUPLICATE_FAILURE,
  // CATEGORY_BY_TEST_REQUEST,
  // CATEGORY_BY_TEST_SUCCESS,
  // CATEGORY_BY_TEST_FAILURE,
} from "./actionType";

const initialState = {
  count: 0,
  title: "",
  maxTime: "",
  testVersion: "",
  qesCategoriesCount: "",
  questionsCount: "",
  // data: [],
  result: [],
  //   status: STATUSES.IDLE,
  localStorageDAta: [],
  validationError: "",
  testId: "",
  isDraft: "",
  shuffleQuestions: "",
  singleTest: [],
  testError: "",
  message: "",
  url: "",
  // catData: [],
  error: "",
  createTestData: "",
  testIdData: "",
  testAppData: "",
  DeletedTest: [],
  TestDelete: {},
  testSearch: [],
  testduplicate:{},
  

  isLoadingPost: false,
  isLoadingGet: false,
  isLoadingCreate: false,
  // isLoadingProgram: false,
  isLoadingCustom: false,
  isLoadingById: false,
  isLoadingApplicantId: false,
  isLoadingApplicantId: false,
  // isLoadingState: false,
  // isLoadingArchive: false,
  isLoadingToken: false,
  isLoadingMail: false,
  // isLoadingCatTest: false,
  isLoadingDeletedTest: false,
  isLoadingTestDelete: false,
  isLoadingSearch: false,
  isLoadingduplicate: true,


  isErrorPost: false,
  isErrorGet: false,
  isErrorCreate: false,
  // isErrorProgram: false,
  isErrorCustom: false,
  isErrorById: false,
  isErrorApplicantId: false,
  isErrorApplicantId: false,
  // isErrorState: false,
  // isErrorArchive: false,
  isErrorToken: false,
  isErrorMail: false,
  // isErrorCatTest: false,
  isErrorDeletedTest: false,
  isErrorTestDelete: false,
  isErrorTestSearch: false,
  isErrorTestduplicate: false,

  errorPostData: "",
  errorGetData: "",
  errorCreateData: "",
  // errorProgramData: "",
  errorIdData: "",
  errorApplicantIdData: "",
  // errorStateData: "",
  // errorArchiveData: "",
  errorTokenData: "",
  errorMailData: "",
  errorCatTestData: "",
  errorDeletedTest: "",
  errorTestDelete: "",
  errorTestSearch: "",
  errorTestduplicate: "",


};

export const Test_Reducer = (state = initialState, action) => {
  switch (action.type) {



    case POST_TEST_REQUEST: {
      return {
        ...state,
        isLoadingPost: true,
        testId: "",
        errorPostData: "",
        isErrorPost: false,
      };
    }
    case POST_TEST_SUCCESS: {
      return {
        ...state,
        isLoadingPost: false,
        testId: action.payload,
        errorPostData: "",
        isErrorPost: false,
      };
    }
    case POST_TEST_FAILURE: {
      return {
        ...state,
        isLoadingPost: false,
        testId: "",
        errorPostData: action.payload,
        isErrorPost: true,
      };
    }


    case GET_TEST_REQUEST: {
      return {
        ...state,
        isLoadingGet: true,
        singleTest: [],
        errorGetData: "",
        isErrorGet: false,
      };
    }
    case GET_TEST_SUCCESS: {
      return {
        ...state,
        isLoadingGet: false,
        singleTest: action.payload,
        errorGetData: "",
        isErrorGet: false,
      };
    }
    case GET_TEST_FAILURE: {
      return {
        ...state,
        isLoadingGet: false,
        singleTest: [],
        errorGetData: action.payload,
        isErrorGet: true,
      };
    }



    case CREATE_TEST_REQUEST: {
      return {
        ...state,
        isLoadingCreate: true,
        createTestData: "",
        errorCreateData: "",
        isErrorCreate: false,
      };
    }
    case CREATE_TEST_SUCCESS: {
      return {
        ...state,
        isLoadingCreate: false,
        createTestData: action.payload,
        errorCreateData: "",
        isErrorCreate: false,
      };
    }
    case CREATE_TEST_FAILURE: {
      return {
        ...state,
        isLoadingCreate: false,
        createTestData: "",
        errorCreateData: action.payload,
        isErrorCreate: true,
      };
    }



    // case TEST_FILTER_PROGRAM_REQUEST: {
    //   return {
    //     ...state,
    //     isLoadingProgram: false,
    //     data: "",
    //     errorProgramData: "",
    //      isErrorProgram: false,
    //   };
    // }
    // case TEST_FILTER_PROGRAM_SUCCESS: {
    //   return {
    //     ...state,
    //     isLoadingProgram: true,
    //     data: action.payload,
    //     errorProgramData: "",
    //      isErrorProgram: false,
    //   };
    // }
    // case TEST_FILTER_PROGRAM_FAILURE: {
    //   return {
    //     ...state,
    //     isLoadingProgram: false,
    //     data: "",
    //     errorProgramData: action.payload,
    //      isErrorProgram: true,
    //   };
    // }


    case TEST_FILTER_CUSTOM_REQUEST: {
      return {
        ...state,
        isLoadingCustom: true,
        result: [],
        errorCustomData: "",
        isErrorCustom: false,
      };
    }
    case TEST_FILTER_CUSTOM_SUCCESS: {
      return {
        ...state,
        isLoadingCustom: false,
        result: action.payload,
        errorCustomData: "",
        isErrorCustom: false,
      };
    }
    case TEST_FILTER_CUSTOM_FAILURE: {
      return {
        ...state,
        isLoadingCustom: false,
        result: "",
        errorCustomData: action.payload,
        isErrorCustom: true,
      };
    }


    case TEST_BY_ID_REQUEST: {
      return {
        ...state,
        isLoadingById: false,
        testIdData: "",
        errorIdData: "",
        isErrorById: false,
      };
    }
    case TEST_BY_ID_SUCCESS: {
      return {
        ...state,
        isLoadingById: true,
        testIdData: action.payload,
        errorIdData: "",
        isErrorById: false,
      };
    }
    case TEST_BY_ID_FAILURE: {
      return {
        ...state,
        isLoadingById: false,
        testIdData: "",
        errorIdData: action.payload,
        isErrorById: true,
      };
    }

    case TEST_BY_APPLICANT_ID_REQUEST: {
      return {
        ...state,
        isLoadingApplicantId: false,
        testAppData: "",
        errorApplicantIdData: "",
        isErrorApplicantId: false,
      };
    }
    case TEST_BY_APPLICANT_ID_SUCCESS: {
      return {
        ...state,
        isLoadingApplicantId: true,
        testAppData: action.payload,
        errorApplicantIdData: "",
        isErrorApplicantId: false,
      };
    }
    case TEST_BY_APPLICANT_ID_FAILURE: {
      return {
        ...state,
        isLoadingApplicantId: false,
        testAppData: "",
        errorApplicantIdData: action.payload,
        isErrorApplicantId: true,
      };
    }




    // case STATE_CHANGE_REQUEST: {
    //   return {
    //     ...state,
    //     isLoadingState: false,
    //     errorStateData: "",
    //     isErrorState: false,
    //   };
    // }
    // case STATE_CHANGE_SUCCESS: {
    //   return {
    //     ...state,
    //     isLoadingState: true,
    //     errorStateData: "",
    //     isErrorState: false,
    //   };
    // }
    // case STATE_CHANGE_FAILURE: {
    //   return {
    //     ...state,
    //     isLoadingState: false,
    //     errorStateData: action.payload,
    //     isErrorState: true,
    //   };
    // }


    // case ARCHIVE_CHANGE_REQUEST: {
    //   return {
    //     ...state,
    //     isLoadingArchive: false,
    //     errorArchiveData: "",
    //     isErrorArchive: false,
    //   };
    // }
    // case ARCHIVE_CHANGE_SUCCESS: {
    //   return {
    //     ...state,
    //     isLoadingArchive: true,
    //     errorArchiveData: "",
    //     isErrorArchive: false,
    //   };
    // }
    // case ARCHIVE_CHANGE_FAILURE: {
    //   return {
    //     ...state,
    //     isLoadingArchive: false,
    //     errorArchiveData: action.payload,
    //     isErrorArchive: true,
    //   };
    // }



    case CREATE_TOKEN_REQUEST: {
      return {
        ...state,
        isLoadingToken: false,
        url: "",
        errorTokenData: "",
        isErrorToken: false,
      };
    }
    case CREATE_TOKEN_SUCCESS: {
      return {
        ...state,
        isLoadingToken: true,
        url: action.payload,
        errorTokenData: "",
        isErrorToken: false,
      };
    }
    case CREATE_TOKEN_FAILURE: {
      return {
        ...state,
        isLoadingToken: false,
        url: "",
        errorTokenData: action.payload,
        isErrorToken: true,
      };
    }


    case SEND_MAIL_REQUEST: {
      return {
        ...state,
        isLoadingMail: false,
        message: "",
        errorMailData: "",
        isErrorMail: false,
      };
    }
    case SEND_MAIL_SUCCESS: {
      return {
        ...state,
        isLoadingMail: true,
        message: action.payload,
        errorMailData: "",
        isErrorMail: false,
      };
    }
    case SEND_MAIL_FAILURE: {
      return {
        ...state,
        isLoadingMail: false,
        message: "",
        errorMailData: action.payload,
        isErrorMail: true,
      };
    }


    // case CATEGORY_BY_TEST_REQUEST: {
    //   return {
    //     ...state,
    //     isLoadingCatTest: false,
    //     catData: "",
    //     isErrorCatTest: false,
    //   };
    // }
    // case CATEGORY_BY_TEST_SUCCESS: {
    //   return {
    //     ...state,
    //     isLoadingCatTest: true,
    //     catData: action.payload,
    //     errorCatTestData: "",
    //     isErrorCatTest: false,
    //   };
    // }
    // case CATEGORY_BY_TEST_FAILURE: {
    //   return {
    //     ...state,
    //     isLoadingCatTest: false,
    //     catData: "",
    //     errorCatTestData: action.payload,
    //     isErrorCatTest: true,
    //   };
    // }


    case DELETED_TEST_REQUEST: {
      return {
        ...state,
        isLoadingDeletedTest: false,
        errorDeletedTest: "",
        isErrorDeletedTest: false,
      };
    }
    case DELETED_TEST_SUCCESS: {
      return {
        ...state,
        isLoadingDeletedTest: true,
        DeletedTest: action.payload,
        errorDeletedTest: "",
        isErrorDeletedTest: false,
      };
    }
    case DELETED_TEST_FAILURE: {
      return {
        ...state,
        isLoadingDeletedTest: false,
        errorDeletedTest: action.payload,
        isErrorDeletedTest: true,
      };
    }



    case DELETE_TEST_PERMANENTLY_REQUEST: {
      return {
        ...state,
        isLoadingTestDelete: false,
        errorTestDelete: "",
        isErrorTestDelete: false,
      };
    }
    case DELETE_TEST_PERMANENTLY_SUCCESS: {
      return {
        ...state,
        isLoadingTestDelete: true,
        errorTestDelete: "",
        isErrorTestDelete: false,
      };
    }
    case DELETE_TEST_PERMANENTLY_FAILURE: {
      return {
        ...state,
        isLoadingTestDelete: false,
        errorTestDelete: action.payload,
        isErrorTestDelete: true,
      };
    }



    case TEST_SEARCH_REQUEST: {
      return {
        ...state,
        isLoadingSearch: true,
        testSearch: [],
        errorTestSearch: "",
        isErrorTestSearch: false,
      };
    }
    case TEST_SEARCH_SUCCESS: {
      return {
        ...state,
        isLoadingSearch: false,
        testSearch: action.payload,
        errorTestSearch: "",
        isErrorTestSearch: false,
      };
    }
    case TEST_SEARCH_FAILURE: {
      return {
        ...state,
        isLoadingSearch: false,
        testSearch: [],
        errorTestSearch: action.payload,
        isErrorTestSearch: true,
      };
    }



    case TEST_DUPLICATE_REQUEST: {
      return {
        ...state,
        isLoadingduplicate: true,
        testduplicate: [],
        errorTestduplicate: "",
        isErrorTestduplicate: false,
      };
    }
    case TEST_DUPLICATE_SUCCESS: {
      return {
        ...state,
        isLoadingduplicate: false,
        testduplicate: action.payload,
        errorTestduplicate: "",
        isErrorTestduplicate: false,
      };
    }
    case TEST_DUPLICATE_FAILURE: {
      return {
        ...state,
        isLoadingduplicate: false,
        testduplicate: [],
        errorTestduplicate: action.payload,
        isErrorTestduplicate: true,
      };
    }

    default: {
      return state;
    }
  }
};
