import React, { useEffect, useRef, useState } from "react";
import "../Candiate.scss";
import CandidateHeader from "../component/CandidateHeader";
import MinuteTimer from "../component/MinuteTimer";
// import { Link } from 'react-router-dom'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BsCheckCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getQuestion, getQuestionApplicant } from "../../../Redux/QuestionReducer/action";
import parse from "html-react-parser";
import { testById, testByIdApplicant } from "../../../Redux/TestReducer/action";
import { Upadate, UpadateMarks, singleResult, singleResultApplicant, updateResult, updateResultStatus } from "../../../Redux/ResultReducer/action";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Spiner1 from "../../../Components/Spiner1";
import { validToken } from "../../../Redux/ApplicantReducer/action";

const ExamPage = (props) => {
  const location = useLocation()
  const questionList = useSelector(state => state.question.testQuesAppData.result);
  const loading = useSelector(state => state.resultData.isLoadingUpadte);
  const trueList = useSelector(
    (state) => state.question.testQuesAppData.CountArray);
  const resultData = useSelector(state => state?.resultData.singleResult);
  const testData = useSelector((state) => state.test.testAppData);
  const editorConfig = { toolbar: [] };
  const navigate = useNavigate();
  const { testId, resultId } = useParams();
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const [arrayQuestion, setArrayQuestion] = useState([]);
  const [ansArray, setAnsArray] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [appliCantAns, setApplicantAns] = useState([]);
  const [appliCantAnsId, setApplicantAnsId] = useState("");
  const [singleQuestion, setSingleQuestion] = useState([]);
  const [time, setTime] = useState(0);
  const [optionCount, setOptionCount] = useState()
  const [shouldPreventNavigation, setShouldPreventNavigation] = useState(false);
  const [closeWindow, setCloseWindow] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false);
  const [nextBtnLoading, setNextBtnLoading] = useState(false);
  const [restrictOtherFunctions, setRestrictOtherFunctions] = useState(false);
  const nextSlide = (e) => {
    if (appliCantAns.length === 0 && e.target.title === "next") {
      return toast.warn("Please Choose Your Answer");
    }

    setNextBtnLoading(true);
    dispatch(updateResult(resultId, singleQuestion._id, appliCantAns, appliCantAnsId));
    // setIndex(resultData?.answers?.length + 1);
    setIndex(index + 1);
    setSingleQuestion([]);
    setApplicantAns([]);
    setApplicantAnsId("")
  };

  const submit = () => {
    setRestrictOtherFunctions(true)
    dispatch(updateResult(resultId, singleQuestion._id, appliCantAns, appliCantAnsId));
    dispatch(UpadateMarks(testId, resultId, navigate));
    // setIndex(resultData?.answers?.length);
    setIndex(index);
    setShouldPreventNavigation(false);
    setShowModel(true);
    setIsSubmit(true)
  };

  const selectedAnswer = (e, i) => {
    const checked = e.target.checked;
    const example = {
      ansId: e.target.name,
    };
    const example2 = e.target.name + " "

    // Filter selected answers from appliCantAns array
    const chekedVal = appliCantAns.filter((el) => el.isChecked);

    if (optionCount[index]?.Correct_Answer > 1) {
      if (
        optionCount[index].Correct_Answer === appliCantAns.length ||
        optionCount[index].Correct_Answer < appliCantAns.length ||
        optionCount[index].Correct_Answer < chekedVal.length
      ) {
        // Update ansArray based on the selected answer
        let tempData = ansArray.map((element) =>
          element._id === e.target.name
            ? { ...element, isChecked: false }
            : element
        );
        setAnsArray(tempData);

        // Remove the selected answer from appliCantAns if it exists
        const check = appliCantAns.filter((el) => el.ansId === e.target.name);
        if (check.length > 0) {
          const removed = appliCantAns.filter(
            (element) => element.ansId !== e.target.name
          );
          setApplicantAnsId(appliCantAnsId?.replace(`${e.target.name}`, ''))
          setApplicantAns(removed);
        }

      } else {
        setApplicantAns((prevArray) => [...prevArray, example]);
        setApplicantAnsId(appliCantAnsId + " " + example2)

        let tempData = ansArray.map((element) =>
          element._id === e.target.name
            ? { ...element, isChecked: checked }
            : element
        );
        setAnsArray(tempData);

        const check = appliCantAns.filter((el) => el.ansId === e.target.name);
        if (check.length > 0) {
          const removed = appliCantAns.filter(
            (element) => element.ansId !== e.target.name
          );
          setApplicantAns(removed);
          setApplicantAnsId(appliCantAnsId.replace(`${e.target.name}`, ''));
        };
      };
    } else {
      const checked = e.target.checked;

      // Extract necessary values from your state
      // const { optionCount, ansArray, appliCantAns, appliCantAnsId } = yourState;

      // Identify the index of the checkbox that was clicked based on its name
      // const index = optionCount.findIndex((option) => option._id === e.target.name);

      if (checked) {
        // Deselect other checkboxes and update appliCantAnsId and appliCantAns
        const updatedAppliCantAnsId = e.target.name;
        const updatedAppliCantAns = [{ ansId: e.target.name }];

        const updatedAnsArray = ansArray.map((element) =>
          element._id === e.target.name ? { ...element, isChecked: checked } : { ...element, isChecked: false }
        );

        setAnsArray(updatedAnsArray);
        setApplicantAns(updatedAppliCantAns);
        setApplicantAnsId(updatedAppliCantAnsId);
      } else {
        // Deselect the checkbox and update appliCantAnsId and appliCantAns
        const updatedAnsArray = ansArray.map((element) =>
          element._id === e.target.name ? { ...element, isChecked: checked } : element
        );

        setAnsArray(updatedAnsArray);
        setApplicantAns([]);
        setApplicantAnsId('');
      };
    }
  };

  const handleBeforeUnload = (event) => {
    if (shouldPreventNavigation) {
      event.preventDefault();
      event.returnValue = 'Are you sure you want to leave?';
    }
  };

  useEffect(() => {
    let visibilityChangeExecuted = false;
    let beforeUnloadExecuted = false;

    const handleVisibilityChange = () => {
      if (!beforeUnloadExecuted && !visibilityChangeExecuted && showModel === false && !restrictOtherFunctions) {
        if (document.visibilityState === "hidden") {
          alert("Your Exam Is Cancelled.");
          setTimeout(() => {
            navigate('/');
            window.close();
          }, 3000);
          setTimeout(() => {
            dispatch(updateResult(resultId, singleQuestion?._id, appliCantAns, appliCantAnsId, 'ABANDONED'));
          }, 100);
          visibilityChangeExecuted = true;
        }
      }
    };

    // const beforeUnloadHandler = (e) => {
    //   if (!beforeUnloadExecuted && !visibilityChangeExecuted && showModel === false && !restrictOtherFunctions) {
    //     e.preventDefault();
    //     // setTimeout(() => {
    //     //   dispatch(updateResult(resultId, singleQuestion?._id, appliCantAns, appliCantAnsId, 'ABANDONED'));
    //     // }, 100);
    //     beforeUnloadExecuted = true;
    //   }
    // };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    // window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      // window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [shouldPreventNavigation]);


  // useEffect(() => {
  //   let visibilityChangeExecuted = false;
  //   let beforeUnloadExecuted = false;

  //   const handleVisibilityChange = () => {
  //     if (!beforeUnloadExecuted && !visibilityChangeExecuted && showModel === false && !restrictOtherFunctions) {
  //       if (document.visibilityState === "hidden") {
  //         alert("Your Exam Is Cancelled.");
  //         setTimeout(() => {
  //           navigate('/');
  //           // window.close(); // Remove or comment out this line to prevent closing the window
  //         }, 3000);
  //         setTimeout(() => {
  //           dispatch(updateResult(resultId, singleQuestion?._id, appliCantAns, appliCantAnsId, 'ABANDONED'));
  //         }, 100);
  //         visibilityChangeExecuted = true;
  //       }
  //     }
  //   };

  //   const beforeUnloadHandler = (e) => {
  //     if (!beforeUnloadExecuted && !visibilityChangeExecuted && showModel === false && !restrictOtherFunctions) {
  //       e.preventDefault();
  //       // Remove or comment out the following line to prevent updating result on unload
  //       // setTimeout(() => {
  //       //   dispatch(updateResult(resultId, singleQuestion?._id, appliCantAns, appliCantAnsId, 'ABANDONED'));
  //       // }, 100);
  //       beforeUnloadExecuted = true;
  //     }
  //   };

  //   document.addEventListener("visibilitychange", handleVisibilityChange);
  //   window.addEventListener('beforeunload', beforeUnloadHandler);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //     window.removeEventListener('beforeunload', beforeUnloadHandler);
  //   };
  // }, [shouldPreventNavigation]);


  var arrCopy;
  function shuffle(arr) {
    if (!Array.isArray(arr)) {
      console.error("Input is not an array.");
      return arr;
    }

    arrCopy = [...arr];
    let ctr = arrCopy.length;
    let temp, index;

    while (ctr > 0) {
      index = Math.floor(Math.random() * ctr);
      ctr--;
      temp = arrCopy[ctr];
      arrCopy[ctr] = arrCopy[index];
      arrCopy[index] = temp;
    }
    return arrCopy;
  };
  const sortedArray =
    ansArray ?
      ansArray.reduce(
        (initialValue, currentItem) => {
          if (currentItem?.Stick_to_bottom == true) {
            initialValue.bottom.push(currentItem);
          } else {
            initialValue.top.push(currentItem);
          }
          return initialValue;
        },
        { top: [], bottom: [] }
      )
      : { top: [], bottom: [] };

  if (singleQuestion?.shuffleAnswers == true || false) {
    sortedArray.top = sortedArray.top
    sortedArray.bottom = sortedArray.bottom
  }

  useEffect(() => {
    setShouldPreventNavigation(true);
    dispatch(testByIdApplicant(testId, navigate));
    dispatch(getQuestionApplicant(testId, navigate));
  }, [testId]);


  useEffect(() => {
    if (questionList) {
      setTime(resultData?.timer);
      var myArray = questionList;
      // const newData = shuffle(myArray)
      if (testData?.shuffleQuestions == true) {
        setArrayQuestion(resultData?.candidateQuestion);
      } else {
        setArrayQuestion(questionList);
      }
    };
    setOptionCount(trueList);
  }, [questionList, resultData?.timer, trueList, optionCount]);


  useEffect(() => {
    if (arrayQuestion) {
      setSingleQuestion(arrayQuestion[index]);
    };
    setIndex(index || resultData?.answers?.length);
    setOptionCount(trueList);
  }, [arrayQuestion, index, singleQuestion, trueList]);

  useEffect(() => {
    var myArray = singleQuestion?.answers;
    const newData = shuffle(myArray)
    if (singleQuestion) {
      if (singleQuestion?.shuffleAnswers == true) {
        setAnsArray(newData);
      } else {
        setAnsArray(singleQuestion?.answers);
      }
    };
    setOptionCount(trueList)
  }, [singleQuestion, trueList, index, optionCount, index]);

  useEffect(() => {
    // const beforeUnloadHandler = (e) => {
    //   e.preventDefault();
    //   setCloseWindow(true)
    //   // e.returnValue = ''; // This line is required for some browsers
    // };

    // window.addEventListener('beforeunload', beforeUnloadHandler);

    // return () => {
    //   window.removeEventListener('beforeunload', beforeUnloadHandler);
    // };
  }, []);


  useEffect(() => {

    setIndex(resultData?.answers?.length || 0);
    if (resultData?.testStatus === "IN PROGRESS" && isSubmit == false) {
      dispatch(updateResult(resultId, singleQuestion?._id, appliCantAns, appliCantAnsId, 'ABANDONED'));

    }
  }, [resultId, closeWindow]);
  useEffect(() => {
    dispatch(singleResultApplicant(resultId, navigate));
  }, [resultId])



  useEffect(() => {
    // Disable right-click context menu
    document.addEventListener('contextmenu', function (e) {
      e.preventDefault();
    }, false);

    // Disable F12 key
    document.addEventListener('keydown', function (e) {
      if (e.key === 'F12') {
        e.preventDefault();
      }
    }, false);

    // Disable Ctrl+Shift+I (or Cmd+Opt+I on Mac)
    document.addEventListener('keydown', function (e) {
      if ((e.ctrlKey || e.metaKey) && e.shiftKey && (e.key === 'I' || e.key === 'i')) {
        e.preventDefault();
      }
    }, false);

    // Disable Ctrl+Shift+J (or Cmd+Opt+J on Mac)
    document.addEventListener('keydown', function (e) {
      if ((e.ctrlKey || e.metaKey) && e.shiftKey && (e.key === 'J' || e.key === 'j')) {
        e.preventDefault();
      }
    }, false);
  }, [])

  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function (event) {
      window.history.pushState(null, null, window.location.href);
    };
  }, [])


  return (
    <section className="log_in instruction_area exam_page candidate">
      <div className="container">
        <CandidateHeader />
        <div className="login_area d-block">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="log_in_box p-4 border-0 bg-transparent box-shadow-sm text-start mb-4">
                <img
                  src="site_logo.png"
                  alt=""
                  className="img-fluid mobile_logo mx-auto mb-3"
                />
                <div className="row justify-content-between align-items-center g-3 mb-4">
                  <p className="text-danger">*Changing tabs will cancel the exam.</p>
                  <div className="col-auto">
                    <h3 className="mb-0">{testData?.title} </h3>
                  </div>
                  <div className="col-auto">
                    {time > 0 ? <MinuteTimer time={time} /> : null}
                  </div>
                </div>
                {resultData?.testStatus !== "COMPLETED" ? (!singleQuestion ? (
                  "Loading......"
                ) : (
                  <div className="question-slide">
                    <div className="question_area">
                      <h6 className="">Q.{index + 1}</h6>
                      <div className="question_body">
                        {
                          !singleQuestion?.question ? (
                            "Loading..."
                          ) : (
                            <ReactQuill
                              theme="snow"
                              modules={editorConfig}
                              readOnly
                              value={singleQuestion?.question}
                            />
                          )
                        }
                      </div>
                    </div>
                    <div className="answer_area">
                      <p className="mb-2">
                        You can choose only {optionCount ? optionCount[index]?.Correct_Answer : "Loading..."} option.
                      </p>
                      {sortedArray.top.map((items, i) => (
                        <div key={items._id} className="answer_option">
                          <span>{i + 1}</span>
                          <input
                            type="checkbox"
                            id={items?._id}
                            className="form-check"
                            checked={items?.isChecked}
                            value={i}
                            name={items?._id}
                            onChange={selectedAnswer}
                          />
                          <label htmlFor={items?._id} className="form-label">
                            {parse(items?.answers)}
                          </label>
                        </div>
                      ))}

                      {sortedArray.bottom.map((items, i) => (
                        <div key={items._id} className="answer_option">
                          <span>{i + 1 + sortedArray.top.length}</span>
                          <input
                            type="checkbox"
                            id={items?._id}
                            className="form-check"
                            checked={items?.isChecked}
                            value={i}
                            name={items?._id}
                            onChange={selectedAnswer}
                          />
                          <label htmlFor={items?._id} className="form-label">
                            {parse(items?.answers)}
                          </label>
                        </div>
                      ))}
                    </div>
                    {arrayQuestion?.length - 1 === index ? (
                      <div className="exam_btns">
                        <button
                          type="button"
                          disabled={loading}
                          onClick={submit}
                          className="btn btn-primary me-2 mb-3 px-lg-5"
                        >
                          {loading ? <Spiner1 /> : "Submit"}
                        </button>
                      </div>
                    ) : (
                      <div className="exam_btns">
                        <button
                          type="button"
                          disabled={loading && nextBtnLoading}
                          onClick={nextSlide}
                          className="btn btn-success me-2 mb-3 px-lg-5"
                          title={"next"}
                        >
                          {loading && nextBtnLoading ? <Spiner1 /> : "Next"}
                        </button>
                        <button
                          type="button"
                          onClick={nextSlide}
                          className="btn btn-danger px-lg-5 mb-3"
                        >
                          I Don’t Know
                        </button>
                      </div>
                    )}
                    {
                      <Modal
                        show={showModel}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header className="border-0"></Modal.Header>
                        <Modal.Body className="text-center pb-5 px-4 px-lg-5">
                          <span className="display-3 text-success">
                            <BsCheckCircle />
                          </span>
                          <h3 className="text-primary fw-bold fw-montserrat fs-3 mt-3">
                            Thank You!
                          </h3>
                          <p className="fs-6">
                            Your answers have been successfully submitted. Our
                            team will carefully review your test and get back to
                            you as soon as possible.
                          </p>
                          <Button
                            onClick={() => {
                              window.close()
                            }}
                            variant="primary"
                          >
                            You can close the tab
                          </Button>
                        </Modal.Body>
                      </Modal>
                    }
                  </div>
                )) : "You Have Already Submitted"
                }
              </div>
            </div>
          </div>
        </div>
        <div className="exam_instruction">
          <div className="exam_isntruction_block">
            Please select all correct answers (One or Multiple)
          </div>
          <div className="exam_isntruction_block">
            Any image failed to load? Please refresh the page.
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExamPage;