import React, { useState } from 'react'
import SettingsNavbar from '../Components/SettingsNavbar'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import Avatar from '../Components/settings/Avatar'
import Spiner1 from '../Components/Spiner1'

import { toast } from 'react-toastify'
import axios from 'axios'
import { errorCall } from '../Redux/ErrorHandling/action'
import { useDispatch } from 'react-redux'

const AddUserType = ({ onSidedataChange, Sidedata }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [typeName, setTypeName] = useState("");
    const [Status, setStatus] = useState("1");
    const [isLoading, setIsLoading] = useState(false)
    const changeDesignationName = (e) => {
        e.preventDefault();
        setTypeName(e.target.value);
    }
    const getLocaldata = () => {
        const token = localStorage.getItem("token");
        return token
    }
    const addDesignationS = async (e) => {
        setTypeName(typeName.trim());
        if (typeName.trim() == "") {
            toast.warn("Please provide User Type");
            return;
        }

        setIsLoading(true);

        const mt = getLocaldata();
        const config = {
            headers: { Authorization: `Bearer ${mt}` },
        };

        const formData = {
            form: {
                "name": typeName,
                "status": Status,
            },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/addUser`, formData, config);
            toast.success(response.data.message);
            navigate("/settings/user-management")
        } catch (error) {
            if (error.message === "Network Error" || error.response.status === 500) {
                dispatch(errorCall(error))
                return;
            }
            toast.error(error?.response?.data?.message);
            if (error?.response?.data?.message === "Unauthenticated.") {
                localStorage.removeItem("token");
                navigate("/login");
            }
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <div>
            <SettingsNavbar navdata={{ header: "Add User Type", buttonText: "designation Button", buttonPath: "/settings/skillsuggetion" }} newSidedata={Sidedata} newonSidedataChange={onSidedataChange} />
            <div className="user-action-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Link to={"/settings/user-management"}> <BsFillArrowLeftCircleFill /> User Type List</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="create-company-form">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form">
                                <div className="row g-2">
                                    <div className="col-md-4">
                                        <div className="position-relative list_search_fild">
                                            <input type="text" name="query" placeholder="User Type"
                                                aria-label="" value={typeName?typeName:""} className="form-control coustom_P h-100" onChange={(e) => changeDesignationName(e)} />
                                            <Avatar name={typeName} size={"34px"} position="absolute" top="3px" left="5px" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <select className="col-sm-4 mb-2 form-control form-control-custom" value={Status} onChange={(e) => setStatus(e.target.value)} >
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-success button-outline choosed mt-3" disabled={isLoading ? true : false} onClick={(e) => addDesignationS(e)}>
                                {isLoading ? <Spiner1 /> : "Submit"}
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddUserType
